import axios from 'axios';

import { RESET_PASSWORD_CREATED, RESET_PASSWORD_UPDATED } from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const createResetPasswordRequest = (email) => {
  const url = `${REST_API_URL}v1/reset-password`;
  const request = axios.post(
    url,
    { email },
  );

  return {
    type: RESET_PASSWORD_CREATED,
    payload: request,
  };
};

const updateResetPasswordRequest = (id, password) => {
  const url = `${REST_API_URL}v1/reset-password`;
  const request = axios.put(
    url,
    { id, password },
  );

  return {
    type: RESET_PASSWORD_UPDATED,
    payload: request,
  };
};

export { createResetPasswordRequest, updateResetPasswordRequest };
