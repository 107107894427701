import { FEEDBACK_TIMEFRAME_SELECTED, FEEDBACK_USER_SELECTED } from './types';

const initialState = {
  timeframe: '',
  user: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK_TIMEFRAME_SELECTED: {
      return {
        ...state, ...{ timeframe: action.payload },
      };
    }
    case FEEDBACK_USER_SELECTED: {
      return {
        ...state, ...{ user: action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
