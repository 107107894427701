/* global localStorage */
import _ from 'lodash';

import * as types from './types';

const TOKEN_KEY = 'api_token';
const TOKEN_EXP_KEY = 'api_token_exp';
const REFRESH_TOKEN_KEY = 'api_refresh_token';

const initialTokenState = localStorage.getItem(TOKEN_EXP_KEY);

export default (state = initialTokenState, action) => {
  switch (action.type) {
    case types.TOKEN_REFRESHED:
    case types.USER_LOGGED_IN: {
      const newToken = _.get(action.payload.data, 'exp', state);
      if (newToken) {
        localStorage.setItem(TOKEN_EXP_KEY, newToken);
        return newToken.toString();
      }
      return state;
    }
    case types.USER_LOGGED_OUT: {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(TOKEN_EXP_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      return null;
    }
    default: {
      return state;
    }
  }
};
