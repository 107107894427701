import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import UserSurveyFilters from '../../containers/UserSurveyFilters';
import SurveyTeamList from '../../containers/SurveyTeamList';
import SurveyReportTeamList from '../../containers/SurveyReportTeamList';

import './surveys-team-page.css';

import teamIcon from '../../assets/images/team-icon.svg';

const SurveysTeamPage = ({ t, teamSurveys, teamSurveyReports }) => {
  const loading = (teamSurveys === null || teamSurveyReports === null);
  const listCount = loading
    ? 0
    : _.values(teamSurveys).length + _.values(teamSurveyReports).length;

  const renderList = (
    <div className={`render-list ${loading || listCount === 0 ? 'd-none' : ''}`}>
      <UserSurveyFilters team />
      <div className="content-box accordion">
        <div className="box-list">
          <SurveyTeamList />
          <SurveyReportTeamList />
        </div>
      </div>
    </div>
  );
  const renderLoading = (
    <div
      style={{ padding: '15px' }}
      className={`content-box accordion ${!loading && listCount === 0 ? '' : 'd-none'}`}
    >
      <div className="box-list">
        <p align="center">
          <strong>
            {t('survey.label.no_team_feedback')}
          </strong>
        </p>
        <p align="center">
          {t('survey.label.no_team_feedback_desc')}
        </p>
        <div className="form-group text-center">
          <Link
            className="btn standard"
            to="/provide-feedback"
          >
            {t('feedback.action.no_feedback')}
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <main className="surveys-team-page list">
      <div className="container">
        <div className="content-title team">
          <div className="row">
            <div className="col-2">
              <Link to="/#collapseThree">
                <img
                  src={teamIcon}
                  alt="team"
                />
              </Link>
            </div>
            <div className="col my-auto">
              <Link
                className="without-underline"
                to="/#collapseThree"
              >
                <span className="box-title">
                  {t('menu.label.team_feedback')}
                </span>
              </Link>
            </div>
            <div className="col my-auto">
              <span className="box-subtitle">
                {t('menu.label.team_members_feedback')}
              </span>
            </div>
          </div>
        </div>
        {renderList}
        {renderLoading}
      </div>
    </main>
  );
};

const mapStateToProps = ({ teamSurveys, teamSurveyReports }) => ({
  teamSurveys,
  teamSurveyReports,
});

export default connect(mapStateToProps)(withNamespaces()(SurveysTeamPage));

SurveysTeamPage.propTypes = {
  t: PropTypes.func.isRequired,
  teamSurveys: PropTypes.shape({}),
  teamSurveyReports: PropTypes.shape({}),
};

SurveysTeamPage.defaultProps = {
  teamSurveys: null,
  teamSurveyReports: null,
};
