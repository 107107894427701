import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import FeedbackProvideFormComponent from '../../components/FeedbackProvideForm';
import { fetchFeedback, updateFeedback } from '../../redux-modules/feedback/actions';

class FeedbackProvideForm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      token,
      id,
      onRedirect,
      fetchFeedback: fetchFeedbackAction,
    } = this.props;

    fetchFeedbackAction(token, id).then(
      (data) => {
        const feedback = _.get(data.payload, 'data', null);
        if (feedback && !feedback.isAnswerable) {
          onRedirect();
        }
      },
      () => {},
    );
  }

  handleSubmit(answers) {
    const {
      id,
      token,
      updateFeedback: updateFeedbackAction,
    } = this.props;

    const newAnswers = answers.map((value) => {
      const newValue = { ...value };
      if (newValue.rating === null) {
        newValue.rating = undefined;
      }

      return newValue;
    });

    return updateFeedbackAction(token, id, newAnswers);
  }

  render() {
    const { feedback, userProfile } = this.props;

    return feedback && feedback.isAnswerable
      ? (
        <FeedbackProvideFormComponent
          showBack
          questions={feedback.questions}
          year={feedback.year}
          quarter={feedback.quarter}
          type={feedback.surveyType}
          name={feedback.fullName}
          initiator={feedback.initiator}
          dueDate={moment(feedback.endDate)}
          title={feedback.title}
          onSubmit={this.handleSubmit}
          userName={userProfile ? userProfile.name : null}
        />
      )
      : <div />;
  }
}

const mapStateToProps = ({ token, feedback, userProfile }) => ({ token, feedback, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators(
  { updateFeedback, fetchFeedback },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackProvideForm);

FeedbackProvideForm.propTypes = {
  id: PropTypes.string.isRequired,
  onRedirect: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  feedback: PropTypes.shape({}),
  updateFeedback: PropTypes.func.isRequired,
  fetchFeedback: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({}),
};

FeedbackProvideForm.defaultProps = {
  feedback: null,
  userProfile: null,
};
