import React from 'react';
import PropTypes from 'prop-types';

import CreateSelfAssessmentForm from '../../containers/CreateSelfAssessmentForm';

const CreateSelfAssessmentPage = ({ history, match: { params: { id } } }) => (
  <main className="create-self-assessment-page survey">
    <div className="container">
      <CreateSelfAssessmentForm
        id={id}
        onRedirect={() => history.push('/#collapseFour')}
      />
    </div>
  </main>
);

export default CreateSelfAssessmentPage;

CreateSelfAssessmentPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
