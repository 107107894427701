import _ from 'lodash';

import CALL_TO_ACTION_FETCHED from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case CALL_TO_ACTION_FETCHED: {
      return _.get(action.payload, 'data.notifications', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
