import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import './table.css';

const Table = ({
  data,
  columns,
  onClick,
  defaultSorted,
}) => (
  <BootstrapTable
    bootstrap4
    classes="surveys-table"
    keyField="id"
    data={data}
    columns={columns}
    defaultSorted={defaultSorted}
    bordered={false}
    onTableChange={() => {}}
    rowEvents={onClick ? { onClick: (e, row) => { onClick(row.id); } } : null}
  />
);

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onClick: PropTypes.func,
  defaultSorted: PropTypes.arrayOf(PropTypes.shape({
    dataField: PropTypes.string,
    order: PropTypes.string,
  })),
};

Table.defaultProps = {
  onClick: null,
  defaultSorted: null,
};
