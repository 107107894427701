import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import SurveysList from '../../components/SurveyList';
import { fetchSurveys } from '../../redux-modules/survey/actions';

class SurveyList extends Component {
  componentDidMount() {
    const { token, fetchSurveys: fetchSurveysAction } = this.props;
    fetchSurveysAction(token, 'active');
  }

  render() {
    const { surveys, filterUserSurvey } = this.props;

    const activeSurveys = _.keyBy(
      _.filter(surveys, survey => survey.status === 'active'),
      'id',
    );

    return (
      <div className="survey-list">
        <SurveysList
          data={activeSurveys}
          filter={filterUserSurvey}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  surveys,
  filterUserSurvey,
}) => ({ token, surveys, filterUserSurvey });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchSurveys }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);

SurveyList.propTypes = {
  token: PropTypes.string.isRequired,
  surveys: PropTypes.shape({}),
  filterUserSurvey: PropTypes.shape({}),
  fetchSurveys: PropTypes.func.isRequired,
};

SurveyList.defaultProps = {
  surveys: null,
  filterUserSurvey: null,
};
