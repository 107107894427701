import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import AdminChooseUserForm from '../../containers/AdminChooseUserForm';

const AdminUsersPage = ({ t }) => (
  <main className="profile-page settings">
    <div className="container">
      <div className="content-title my-auto">
        <span>
          {t('menu.label.users')}
        </span>
      </div>
      <div className="content-box accordion">
        <AdminChooseUserForm />
      </div>
    </div>
  </main>
);

export default withNamespaces()(AdminUsersPage);

AdminUsersPage.propTypes = {
  t: PropTypes.func.isRequired,
};
