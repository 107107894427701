import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import FeedbackProvideFormComponent from '../../components/FeedbackProvideForm';
import {
  fetchFeedbackExternal,
  updateFeedbackExternal,
} from '../../redux-modules/feedback-external/actions';
import fetchCustomerExternal from '../../redux-modules/customer-external/actions';
import i18next from '../../utils/i18next';

class FeedbackProvideExternalForm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      id,
      onRedirect,
      fetchFeedbackExternal: fetchFeedbackExternalAction,
      fetchCustomerExternal: fetchCustomerExternalAction,
    } = this.props;

    fetchCustomerExternalAction(id).then(
      (data) => {
        const customer = _.get(data.payload, 'data', null);
        if (customer) {
          i18next.changeLanguage(customer.language).catch();
        }
      },
      () => {},
    );
    fetchFeedbackExternalAction(id).then(
      (data) => {
        const feedback = _.get(data.payload, 'data', null);
        if (feedback && !feedback.isAnswerable) {
          onRedirect();
        }
      },
      () => {},
    );
  }

  handleSubmit(answers) {
    const {
      id,
      updateFeedbackExternal: updateFeedbackExternalAction,
    } = this.props;

    return updateFeedbackExternalAction(id, answers);
  }

  render() {
    const { customerExternal, feedbackExternal } = this.props;

    return customerExternal && feedbackExternal && feedbackExternal.isAnswerable
      ? (
        <FeedbackProvideFormComponent
          questions={feedbackExternal.questions}
          year={feedbackExternal.year}
          quarter={feedbackExternal.quarter}
          type={feedbackExternal.surveyType}
          name={feedbackExternal.fullName}
          dueDate={moment(feedbackExternal.endDate)}
          userName={customerExternal ? customerExternal.name : null}
          onSubmit={this.handleSubmit}
        />
      )
      : <div />;
  }
}

const mapStateToProps = ({ customerExternal, feedbackExternal }) => ({
  customerExternal,
  feedbackExternal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFeedbackExternal,
  fetchFeedbackExternal,
  fetchCustomerExternal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackProvideExternalForm);

FeedbackProvideExternalForm.propTypes = {
  id: PropTypes.string.isRequired,
  onRedirect: PropTypes.func.isRequired,
  feedbackExternal: PropTypes.shape({}),
  customerExternal: PropTypes.shape({}),
  updateFeedbackExternal: PropTypes.func.isRequired,
  fetchFeedbackExternal: PropTypes.func.isRequired,
  fetchCustomerExternal: PropTypes.func.isRequired,
};

FeedbackProvideExternalForm.defaultProps = {
  feedbackExternal: null,
  customerExternal: null,
};
