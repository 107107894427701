import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import InputField from '../../components/InputField';
import SubmitField from '../../components/SubmitField';
import { updateResetPasswordRequest } from '../../redux-modules/reset-password/actions';
import validators from '../../utils/validators';

class ResetPasswordConfirmForm extends Component {
  handleSubmit(values) {
    const {
      updateResetPasswordRequest: updateResetPasswordRequestAction,
      onSent,
      id,
    } = this.props;

    return updateResetPasswordRequestAction(id, values.password).then(
      onSent || (() => {}),
      onSent || (() => {}),
    );
  }

  render() {
    const { t, handleSubmit } = this.props;

    return (
      <div className="reset-password-request-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group">
            <Field
              name="password"
              type="password"
              showLabel
              label={t('user.label.password')}
              placeholder={t('user.placeholder.password')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="passwordConfirm"
              type="password"
              showLabel
              label={t('user.label.password_confirm')}
              placeholder={t('user.placeholder.password_confirm')}
              component={InputField}
            />
          </div>
          <div className="form-group text-center">
            <Field
              name="submit"
              label={t('user.action.reset')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.minLength(values.password, 8)) {
    errors.password = t('user.error.password_min_length');
  }

  if (!validators.equals(values.password, values.passwordConfirm)) {
    errors.passwordConfirm = t('user.error.password_do_not_match');
  }

  return errors;
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateResetPasswordRequest,
}, dispatch);

export default (withNamespaces()(reduxForm({
  validate,
  form: 'resetPasswordConfirmForm',
})(connect(null, mapDispatchToProps)(ResetPasswordConfirmForm))));

ResetPasswordConfirmForm.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  updateResetPasswordRequest: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSent: PropTypes.func,
};

ResetPasswordConfirmForm.defaultProps = {
  onSent: null,
};
