import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import SignInForm from '../../containers/SignInForm';
import Footer from '../../components/Footer';

import logo from '../../assets/images/logo.svg';
import cchLogo from '../../assets/images/cch-logo.svg';

class SignInForcePage extends Component {
  handleSignIn() {
    const { history: { push } } = this.props;
    push('/');
  }

  render() {
    const { t } = this.props;

    return (
      <div className="sign-in-page">
        <nav className="navbar navbar-expand-lg navbar-gray static-top">
          <div className="mobile-header">
            <div className="mobile-header-logo">
              <img
                src={cchLogo}
                alt="logo"
              />
            </div>
          </div>
          <div className="container">
            <div className="logo">
              <img
                src={logo}
                alt="logo"
              />
            </div>
          </div>
        </nav>
        <main className="login">
          <div className="container">
            <div className="content-box">
              <h2 align="center">
                {t('user.sign_in')}
              </h2>
              <SignInForm onSignIn={() => this.handleSignIn()} />
              <div className="row">
                <div className="col text-center">
                  <Link to="/reset-password-request">
                    {t('user.link.password_forgot')}
                  </Link>
                </div>
                <div className="col text-center">
                  <Link to="/sign-up">
                    {t('user.link.sign_up')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withNamespaces()(SignInForcePage);

SignInForcePage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
