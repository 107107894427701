const subareas = {
  Z210: 'Commercial',
  Z220: 'Commercial',
  Z110: 'Supply Chain',
  Z120: 'Supply Chain',
  Z310: 'Supply Chain',
  Z320: 'Supply Chain',
  Z410: 'Finance',
  Z420: 'BSS',
  Z430: 'HR',
  Z440: ['Legal', 'PA&C'],
  Z450: 'BSS',
};

export default {
  getValue: id => (Array.isArray(subareas[id]) ? subareas[id].join(' / ') : subareas[id]),
  getIds: (value) => {
    const keys = [];

    Object.keys(subareas).forEach((key) => {
      if (subareas[key] === value || subareas[key].includes(value)) {
        keys.push(key);
      }
    });

    return keys;
  },
  getAllKeys: () => Object.keys(subareas),
};
