import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import NotificationItem from '../../components/NotificationItem';
import fetchCallToAction from '../../redux-modules/call-to-action/actions';

class FeedbackList extends Component {
  componentDidMount() {
    const { token, callToActions, fetchCallToAction: fetchCallToActionAction } = this.props;
    if (!callToActions || callToActions.length === 0) {
      fetchCallToActionAction(token);
    }
  }

  render() {
    const { callToActions, filterUserFeedback } = this.props;
    const { timeframe, user } = filterUserFeedback;

    const currentYear = moment().year();
    const currentQuarter = moment().quarter();

    const data = (callToActions || [])
      .filter(notification => notification.type === 'provide_feedback')
      .filter((feedback) => {
        if (timeframe && timeframe === 'this_quarter' && (feedback.quarter !== currentQuarter || feedback.year !== currentYear)) {
          return false;
        }
        if (timeframe && timeframe === 'this_year' && feedback.year !== currentYear) {
          return false;
        }
        if (user && user !== feedback.fullName) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        const endDateA = moment(a.endDate);
        const endDateB = moment(b.endDate);

        if (endDateA.isBefore(endDateB)) {
          return -1;
        }
        if (endDateA.isAfter(endDateB)) {
          return 1;
        }
        return 0;
      })
      .map(notification => (
        <div
          className="sub-accordion-item"
          key={notification.url}
        >
          <NotificationItem
            url={notification.url}
            notificationType={notification.type}
            quarter={notification.quarter}
            year={notification.year}
            title={notification.surveyTitle}
            type={notification.surveyType}
            name={notification.fullName}
            endDate={moment(notification.endDate)}
          />
        </div>
      ));

    return (
      <div className="feedback-list">
        {data}
      </div>
    );
  }
}

const mapStateToProps = ({ token, filterUserFeedback, callToActions }) => ({
  token,
  filterUserFeedback,
  callToActions,
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCallToAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackList);

FeedbackList.propTypes = {
  filterUserFeedback: PropTypes.shape({
    timeframe: PropTypes.string,
    user: PropTypes.string,
  }),
  token: PropTypes.string.isRequired,
  callToActions: PropTypes.arrayOf(PropTypes.shape({})),
  fetchCallToAction: PropTypes.func.isRequired,
};

FeedbackList.defaultProps = {
  callToActions: null,
  filterUserFeedback: null,
};
