/* global koreBotChat */
import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

class KoreAI extends Component {
  constructor(props) {
    super(props);

    this.jwtUrl = `${REST_API_URL}v1/chat-bot`;
    this.assertion = this.assertion.bind(this);
  }

  assertion(options, callback) {
    const JWTUrl = this.jwtUrl;

    axios.post(
      JWTUrl,
      {},
    ).then((response) => {
      // eslint-disable-next-line no-param-reassign
      options.assertion = response.data.jwt;
      callback(null, options);
    });
  }

  render() {
    const {
      jwtToken,
      userEmail,
      firstName,
      lastName,
      user3rdPartyId,
    } = this.props;

    if (!jwtToken || !userEmail || !user3rdPartyId) {
      return (<div />);
    }

    return (
      <ScriptTag
        type="text/javascript"
        src="/kore-ai/kore-ai-sdk.min.js"
        onLoad={() => {
          const botOptions = {};

          botOptions.koreAPIUrl = process.env.REACT_APP_CHATBOT_BOT_API_URL;
          botOptions.assertionFn = this.assertion;
          botOptions.botInfo = {
            name: process.env.REACT_APP_CHATBOT_BOT_NAME,
            _id: process.env.REACT_APP_CHATBOT_BOT_ID,
          };
          botOptions.JWTUrl = this.jwtUrl;
          botOptions.userIdentity = userEmail;
          botOptions.botInfo.customData = {
            username: user3rdPartyId,
            email: userEmail,
            firstname: firstName,
            lastname: lastName,
          };

          const chatConfig = {
            botOptions,
            allowIframe: false,
            isSendButton: false,
            isTTSEnabled: false,
            isSpeechEnabled: false,
            allowGoogleSpeech: false,
            allowLocation: false,
            loadHistory: true,
            messageHistoryLimit: 10,
            autoEnableSpeechAndTTS: false,
            graphLib: 'd3',
            googleMapsAPIKey: '',
            minimizeMode: true,
          };

          const chatInstance = koreBotChat();
          chatInstance.show(chatConfig);
        }}
      />
    );
  }
}

const mapStateToProps = ({ token, userProfile }) => ({
  jwtToken: token,
  userEmail: (userProfile ? userProfile.email : null),
  firstName: (userProfile ? userProfile.firstName : null),
  lastName: (userProfile ? userProfile.lastName : null),
  user3rdPartyId: (userProfile ? userProfile.user3rdPartyId : null),
});

export default connect(mapStateToProps, null)(KoreAI);

KoreAI.propTypes = {
  jwtToken: PropTypes.string,
  userEmail: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  user3rdPartyId: PropTypes.string,
};

KoreAI.defaultProps = {
  jwtToken: null,
  userEmail: null,
  firstName: null,
  lastName: null,
  user3rdPartyId: null,
};
