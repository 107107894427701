import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { logout } from '../../redux-modules/auth/actions';

class SignOutForm extends Component {
  componentDidMount() {
    const { logout: logoutAction, token } = this.props;
    logoutAction(token);
  }

  render() {
    return (
      <div className="sign-out-form" />
    );
  }
}

const mapStateToProps = ({ token }) => ({ token });

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignOutForm);

SignOutForm.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};
