import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const DatePickerField = ({
  t,
  input: { name, value, onChange },
  meta: { touched, error, submitting },
  label,
  disabled,
  showLabel,
  showErrors,
}) => {
  const options = [
    {
      value: '1',
      label: t('date.label.months.1'),
    },
    {
      value: '2',
      label: t('date.label.months.2'),
    },
    {
      value: '3',
      label: t('date.label.months.3'),
    },
    {
      value: '4',
      label: t('date.label.months.4'),
    },
    {
      value: '5',
      label: t('date.label.months.5'),
    },
    {
      value: '6',
      label: t('date.label.months.6'),
    },
    {
      value: '7',
      label: t('date.label.months.7'),
    },
    {
      value: '8',
      label: t('date.label.months.8'),
    },
    {
      value: '9',
      label: t('date.label.months.9'),
    },
    {
      value: '10',
      label: t('date.label.months.10'),
    },
    {
      value: '11',
      label: t('date.label.months.11'),
    },
    {
      value: '12',
      label: t('date.label.months.12'),
    },
  ];

  const optionsView = options.map(option => (
    <option
      key={option.value}
      value={option.value}
    >
      {option.label}
    </option>
  ));

  return (
    <div className="date-picker-field">
      <label
        className={`${showLabel ? 'visible' : ''}`}
        htmlFor={name}
      >
        {label}
      </label>
      <div className="row">
        <div className="col">
          <select
            className="form-control"
            name={`${name}.month`}
            disabled={submitting || disabled}
            value={(value && value.month) || ''}
            onChange={event => onChange({
              day: value ? value.day : '',
              month: event.target.value,
              year: value ? value.year : '',
            })}
          >
            <option value="">
              {t('date.label.choose_month')}
            </option>
            {optionsView}
          </select>
        </div>
        <div className="col">
          <input
            style={{ height: 'calc(1.5em + 0.75rem + 2px)' }}
            className="form-control"
            name={`${name}.day`}
            type="number"
            placeholder={t('date.placeholder.enter_day')}
            disabled={submitting || disabled}
            value={(value && value.day) || ''}
            onChange={event => onChange({
              day: parseInt(event.target.value, 10),
              month: value ? value.month : '',
              year: value ? value.year : '',
            })}
          />
        </div>
        <div className="col">
          <input
            style={{ height: 'calc(1.5em + 0.75rem + 2px)' }}
            className="form-control"
            name={`${name}.year`}
            type="number"
            placeholder={t('date.placeholder.enter_year')}
            disabled={submitting || disabled}
            value={(value && value.year) || ''}
            onChange={event => onChange({
              day: value ? value.day : '',
              month: value ? value.month : '',
              year: parseInt(event.target.value, 10),
            })}
          />
        </div>
      </div>
      <small className="date-picker-field__error">
        {touched && error && showErrors ? error : '' }
      </small>
    </div>
  );
};

export default withNamespaces()(DatePickerField);

DatePickerField.propTypes = {
  t: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    submitting: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({ name: PropTypes.string }).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
};

DatePickerField.defaultProps = {
  label: '',
  disabled: false,
  showLabel: false,
  showErrors: true,
};
