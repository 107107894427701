import React from 'react';
import PropTypes from 'prop-types';

const SubmitField = ({
  meta: { submitting, error },
  input,
  label,
  inline,
}) => (
  <div className={`submit-field ${inline ? 'd-inline-block' : ''}`}>
    <input
      className="btn standard"
      {...input}
      disabled={submitting || error}
      type="submit"
      value={label}
    />
  </div>
);

export default SubmitField;

SubmitField.propTypes = {
  meta: PropTypes.shape({
    submitting: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  inline: PropTypes.bool,
};

SubmitField.defaultProps = {
  label: '',
  inline: false,
};
