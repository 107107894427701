import axios from 'axios';

import * as types from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;
const SAML_URL = process.env.REACT_APP_SAML_URL;

const login = (email, password) => {
  const url = `${REST_API_URL}v1/login-check`;
  const request = axios.post(url, {
    _username: email,
    _password: password,
  });

  return {
    type: types.USER_LOGGED_IN,
    payload: request,
  };
};

const loginWithSaml = (token) => {
  const url = `${SAML_URL}_check/${token}`;
  const request = axios.get(url);

  return {
    type: types.USER_LOGGED_IN,
    payload: request,
  };
};

const logout = () => {
  const url = `${REST_API_URL}v1/logout`;
  const request = axios.post(
    url,
    {},
  );

  return ({
    type: types.USER_LOGGED_OUT,
    payload: request,
  });
};

const logoutWebapp = () => ({
  type: types.USER_LOGGED_OUT,
  payload: {},
});

const refreshToken = () => {
  const url = `${REST_API_URL}v1/token/refresh`;
  const request = axios.post(
    url,
    {},
  );

  return {
    type: types.TOKEN_REFRESHED,
    payload: request,
  };
};

const loginAsAdmin = (token, email) => {
  const url = `${REST_API_URL}v1/login_check-admin`;
  const request = axios.post(
    url,
    {
      email,
    },
  );

  return {
    type: types.ADMIN_LOGGED_IN,
    payload: request,
  };
};

export {
  login,
  loginWithSaml,
  logout,
  logoutWebapp,
  refreshToken,
  loginAsAdmin,
};
