import React from 'react';
import PropTypes from 'prop-types';

import KoreAI from '../../containers/KoreAI';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';

const HomePage = ({ children }) => (
  <div className="home-page">
    <KoreAI />
    <TopBar />
    {children}
    <Footer />
  </div>
);

export default HomePage;

HomePage.propTypes = {
  children: PropTypes.element.isRequired,
};
