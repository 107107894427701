import React from 'react';
import PropTypes from 'prop-types';

import SurveyReportPreview from '../../containers/SurveyReportPreview';

const ReportSurveyPreviewPage = ({ match: { params: { id } } }) => (
  <div className="report-survey-preview-page">
    <SurveyReportPreview id={id} />
  </div>
);

export default ReportSurveyPreviewPage;

ReportSurveyPreviewPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
