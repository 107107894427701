import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Footer from '../../components/Footer/Footer';
import SignUpForm from '../../containers/SignUpForm';

import cchLogo from '../../assets/images/cch-logo.svg';
import logo from '../../assets/images/logo.svg';

class SignUpPage extends Component {
  handleSignUp() {
    const { history: { push } } = this.props;
    push('/sign-up-confirmation');
  }

  render() {
    const { t } = this.props;
    return (
      <div className="sign-up-page">
        <nav className="navbar navbar-expand-lg navbar-gray static-top">
          <div className="mobile-header">
            <div className="mobile-header-logo">
              <img
                src={cchLogo}
                alt="logo"
              />
            </div>
          </div>
          <div className="container">
            <div className="logo">
              <img
                src={logo}
                alt="logo"
              />
            </div>
          </div>
        </nav>
        <main className="login">
          <div className="container">
            <div className="content-box">
              <h2 align="center">
                {t('user.sign_up')}
              </h2>
              <SignUpForm onSignUp={() => this.handleSignUp()} />
              <div className="row">
                <div className="col text-center">
                  <Link to="/sign-in">
                    {t('user.link.sign_in')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withNamespaces()(SignUpPage);

SignUpPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
