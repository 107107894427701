import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import InputField from '../../components/InputField';
import SubmitField from '../../components/SubmitField';
import { createResetPasswordRequest } from '../../redux-modules/reset-password/actions';
import validators from '../../utils/validators';

class ResetPasswordRequestForm extends Component {
  handleSubmit(values) {
    const { createResetPasswordRequest: createResetPasswordRequestAction, onSent } = this.props;

    return createResetPasswordRequestAction(values.email).then(
      onSent || (() => {}),
      onSent || (() => {}),
    );
  }

  render() {
    const { t, handleSubmit } = this.props;
    return (
      <div className="reset-password-request-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group">
            <Field
              name="email"
              type="email"
              showLabel
              label={t('user.label.email')}
              placeholder={t('user.placeholder.email')}
              component={InputField}
            />
          </div>
          <div className="form-group text-center">
            <Field
              name="submit"
              label={t('user.action.send')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.email) || !validators.email(values.email)) {
    errors.email = t('user.error.email_required');
  }

  return errors;
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createResetPasswordRequest,
}, dispatch);

export default withNamespaces()(reduxForm({
  validate,
  form: 'resetPasswordRequestForm',
})(connect(null, mapDispatchToProps)((ResetPasswordRequestForm))));

ResetPasswordRequestForm.propTypes = {
  t: PropTypes.func.isRequired,
  createResetPasswordRequest: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSent: PropTypes.func,
};

ResetPasswordRequestForm.defaultProps = {
  onSent: null,
};
