import React from 'react';
import PropTypes from 'prop-types';

import FeedbackCreateForm from '../../containers/FeedbackCreateForm';

const FeedbackCreatePage = ({ history }) => (
  <main className="feedback-create-page survey">
    <div className="container">
      <FeedbackCreateForm onSubmit={() => history.push('/')} />
    </div>
  </main>
);

export default FeedbackCreatePage;

FeedbackCreatePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
