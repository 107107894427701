/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import SelectField from '../../components/SelectField';
import SubmitField from '../../components/SubmitField';
import { updateUser } from '../../redux-modules/me/actions';
import i18next from '../../utils/i18next';

const AVAILABLE_LANGUAGES = process.env.REACT_APP_AVAILABLE_LANGUAGES;

class ChooseLanguageForm extends Component {
  static handleLanguageChange(value) {
    document.documentElement.setAttribute('lang', value);
    document.documentElement.setAttribute('dir', (value === 'ar-EG' ? 'rtl' : 'ltr'));
    i18next.changeLanguage(value).catch();
  }

  componentDidMount() {
    const { userProfile, onConfirm } = this.props;
    if (userProfile && userProfile.language && onConfirm) {
      onConfirm();
    }
  }

  handleSubmit(values) {
    const {
      step,
      token,
      updateUser: updateUserAction,
      onLanguageChange,
      onConfirm,
    } = this.props;

    if (step < 2) {
      onLanguageChange();
      return;
    }

    updateUserAction(token, values.language).then(
      onConfirm || (() => {}),
      () => {},
    );
  }

  render() {
    const { t, handleSubmit, step } = this.props;

    const languageOptions = AVAILABLE_LANGUAGES
      .split(',')
      .map(language => (
        {
          value: language,
          label: t(`language.${language}`),
        }
      ));

    return (
      <div className="choose-language-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className={`${step < 2 ? '' : 'd-none'}`}>
            <Field
              name="language"
              label={t('user.label.language')}
              options={languageOptions}
              onValueChange={value => ChooseLanguageForm.handleLanguageChange(value)}
              component={SelectField}
            />
          </div>
          <div className="form-group text-center">
            <Field
              name="submit"
              label={step < 2 ? t('survey.action.next') : t('welcome.action.i_agree')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ token, userProfile }) => ({ token, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators({ updateUser }, dispatch);

export default withNamespaces()(reduxForm({
  form: 'chooseLanguageForm',
  initialValues: { language: 'en' },
})(connect(mapStateToProps, mapDispatchToProps)(ChooseLanguageForm)));

ChooseLanguageForm.propTypes = {
  step: PropTypes.number.isRequired,
  token: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    language: PropTypes.string,
  }),
};

ChooseLanguageForm.defaultProps = {
  token: null,
  userProfile: null,
};
