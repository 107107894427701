import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Footer from '../../components/Footer';
import ResetPasswordConfirmForm from '../../containers/ResetPasswordConfirmForm';

import cchLogo from '../../assets/images/cch-logo.svg';
import logo from '../../assets/images/logo.svg';

class ResetPasswordConfirmPage extends Component {
  handleSent() {
    const { history: { push } } = this.props;
    push('/sign-in-force');
  }

  render() {
    const { t, match: { params: { id } } } = this.props;

    return (
      <div className="reset-password-confirm-page">
        <nav className="navbar navbar-expand-lg navbar-gray static-top">
          <div className="mobile-header">
            <div className="mobile-header-logo">
              <img
                src={cchLogo}
                alt="logo"
              />
            </div>
          </div>
          <div className="container">
            <div className="logo">
              <img
                src={logo}
                alt="logo"
              />
            </div>
          </div>
        </nav>
        <main className="login">
          <div className="container">
            <div className="content-box">
              <h2 align="center">
                {t('user.password_new')}
              </h2>
              <ResetPasswordConfirmForm
                id={id}
                onSent={() => this.handleSent()}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withNamespaces()(ResetPasswordConfirmPage);

ResetPasswordConfirmPage.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
