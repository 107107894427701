import axios from 'axios';

import WORKFORCE_SEGMENT_FETCHED from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchWorkforceSegment = () => {
  const url = `${REST_API_URL}v1/workforce-segment`;
  const request = axios.get(
    url,
  );

  return {
    type: WORKFORCE_SEGMENT_FETCHED,
    payload: request,
  };
};

export default fetchWorkforceSegment;
