import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import Table from '../../../components/Table';
import {
  fetchSurveyExcludeRules,
  removeSurveyExcludeRule,
} from '../../../redux-modules/survey-exclude-rule/actions';

class AdminSurveyExcludeRuleTable extends Component {
  constructor(props) {
    super(props);

    this.state = { deleteOpen: false, selectedSurveyExcludeRule: 0 };

    this.handleDeleteYes = this.handleDeleteYes.bind(this);
    this.handleDeleteNo = this.handleDeleteNo.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  componentDidMount() {
    const { type, fetchSurveyExcludeRules: fetchSurveyExcludeRulesAction } = this.props;

    fetchSurveyExcludeRulesAction(type);
  }

  componentDidUpdate({ type: prevType }) {
    const { type, fetchSurveyExcludeRules: fetchSurveyExcludeRulesAction } = this.props;

    if (type !== prevType) {
      fetchSurveyExcludeRulesAction(type);
    }
  }

  handleDeleteYes() {
    const {
      type,
      fetchSurveyExcludeRules: fetchSurveyExcludeRulesAction,
      removeSurveyExcludeRule: removeSurveyExcludeRuleAction,
    } = this.props;
    const { selectedSurveyExcludeRule } = this.state;

    removeSurveyExcludeRuleAction(selectedSurveyExcludeRule).then(
      () => {
        fetchSurveyExcludeRulesAction(type);
        this.setState({ deleteOpen: false, selectedSurveyExcludeRule: 0 });
      },
      () => {},
    );
  }

  handleDeleteNo() {
    this.setState({ deleteOpen: false });
  }

  handleDeleteClose() {
    this.setState({ deleteOpen: false });
  }

  handleEditClick(id) {
    const { onEditClick } = this.props;

    onEditClick(id);
  }

  render() {
    const { surveyExcludeRules, t } = this.props;
    const { deleteOpen } = this.state;

    const data = (_.values(surveyExcludeRules) || [])
      .map(surveyExcludeRule => ({
        ...surveyExcludeRule,
        ...{
          type: surveyExcludeRule.type.includes('customer')
            ? t('survey.type.customer_feedback')
            : t(`survey.type.${surveyExcludeRule.type}`),
          businessUnit: surveyExcludeRule.businessUnit ? surveyExcludeRule.businessUnit : '-',
          workforceSegment: surveyExcludeRule.workforceSegment ? surveyExcludeRule.workforceSegment : '-',
          personnelSubarea: surveyExcludeRule.personnelSubarea ? surveyExcludeRule.personnelSubarea : '-',
          countryCode: surveyExcludeRule.countryCode ? surveyExcludeRule.countryCode : '-',
          companyCode: surveyExcludeRule.companyCode ? surveyExcludeRule.companyCode : '-',
          email: surveyExcludeRule.email ? surveyExcludeRule.email : '-',
          locationId: surveyExcludeRule.locationId ? surveyExcludeRule.locationId : '-',
          positionName: surveyExcludeRule.positionName ? surveyExcludeRule.positionName : '-',
          standardGroupJobNumber: surveyExcludeRule.standardGroupJobNumber ? surveyExcludeRule.standardGroupJobNumber : '-',
          originalHireDate: surveyExcludeRule.originalHireDate ? surveyExcludeRule.originalHireDate : '-',
        },
      }));

    const columns = [
      {
        dataField: 'type',
        text: t('survey.label.type'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'email',
        text: t('survey_exclude_rule.label.email'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'countryCode',
        text: t('survey_exclude_rule.label.country_code'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'personnelSubarea',
        text: t('survey_exclude_rule.label.personnel_subarea'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'companyCode',
        text: t('survey_exclude_rule.label.company_code'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'workforceSegment',
        text: t('survey_exclude_rule.label.workforce_segment'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'businessUnit',
        text: t('user.label.business_unit'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'locationId',
        text: t('survey_exclude_rule.label.location_id'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'positionName',
        text: t('survey_exclude_rule.label.position_name'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'standardGroupJobNumber',
        text: t('survey_exclude_rule.label.standard_group_job_number'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'originalHireDate',
        text: t('survey_exclude_rule.label.original_hire_date'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'delete',
        isDummyField: true,
        text: t('survey_exclude_rule.label.actions'),
        formatter: (cellContent, row) => (
          <div>
            <button
              className="btn standard gray"
              type="button"
              onClick={() => this.handleEditClick(row.id)}
            >
              {t('menu.link.edit')}
            </button>
            <button
              className="btn standard gray"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.setState({ deleteOpen: true, selectedSurveyExcludeRule: row.id });
              }}
            >
              {t('survey_exclude_rule.delete_dialog.button')}
            </button>
          </div>
        ),
      },
    ];

    return (
      <div className="admin-survey-table table-surveys">
        <Table
          data={data}
          columns={columns}
          defaultSorted={[{
            dataField: 'id',
            order: 'desc',
          }]}
        />
        <ConfirmDialog
          title={t('survey_exclude_rule.delete_dialog.title')}
          body={t('survey_exclude_rule.delete_dialog.body')}
          open={deleteOpen}
          onClose={this.handleDeleteClose}
          onYes={this.handleDeleteYes}
          onNo={this.handleDeleteNo}
        />
      </div>
    );
  }
}

const selector = formValueSelector('adminSurveyExcludeRulesFilters');

const mapStateToProps = state => ({
  surveyExcludeRules: state.surveyExcludeRules,
  type: selector(state, 'type'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveyExcludeRules,
  removeSurveyExcludeRule,
}, dispatch);

export default withNamespaces()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminSurveyExcludeRuleTable));

AdminSurveyExcludeRuleTable.propTypes = {
  t: PropTypes.func.isRequired,
  surveyExcludeRules: PropTypes.shape({}),
  type: PropTypes.string,
  onEditClick: PropTypes.func,
  fetchSurveyExcludeRules: PropTypes.func.isRequired,
  removeSurveyExcludeRule: PropTypes.func.isRequired,
};

AdminSurveyExcludeRuleTable.defaultProps = {
  surveyExcludeRules: null,
  type: null,
  onEditClick: () => {},
};
