import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import $ from 'jquery';

import NotificationsList from '../../containers/NotificationsList';

import receiveIcon from '../../assets/images/rec-icon.svg';
import giveIcon from '../../assets/images/giv-icon.svg';
import teamIcon from '../../assets/images/team-icon.svg';
import toDoIcon from '../../assets/images/todo-icon.svg';
import support from '../../assets/images/support.svg';

import { me } from '../../redux-modules/me/actions';

class LandingPage extends Component {
  static handleCollapse(hash) {
    if (hash) {
      $(hash).collapse('show');
      $(hash).parents('.accordion-body').collapse('show');
    }
  }

  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      feedbackToMe: 0,
      feedbackToOthers: 0,
      approve: 0,
      dialogOpened: false,
    };

    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  componentDidMount() {
    const {
      history,
      token,
      me: meAction,
    } = this.props;

    this.mounted = true;
    LandingPage.handleCollapse(history.location.hash);
    this.historyListener = history.listen(({ hash }) => LandingPage.handleCollapse(hash));

    meAction(token);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.historyListener();
  }

  handleDataChange(data) {
    const notifications = _.get(data, 'payload.data.notifications', []);

    let feedbackToMe = 0;
    let feedbackToOthers = 0;
    let approve = 0;

    notifications.forEach((notification) => {
      if (notification.type === 'select_customers'
        || notification.type === 'select_peers'
        || notification.type === 'complete_self_reflection'
      ) {
        feedbackToMe += 1;
      }
      if (notification.type === 'provide_feedback') {
        feedbackToOthers += 1;
      }
      if (notification.type === 'customer_list_created'
        || notification.type === 'peer_list_created'
      ) {
        approve += 1;
      }
    });

    if (this.mounted) {
      this.setState({
        feedbackToMe,
        feedbackToOthers,
        approve,
      });
    }
  }

  handleDialogOpen() {
    this.setState({ dialogOpened: true });
  }

  handleDialogClose() {
    this.setState({ dialogOpened: false });
  }

  render() {
    const { t, userProfile } = this.props;
    const {
      feedbackToMe,
      feedbackToOthers,
      approve,
      dialogOpened,
    } = this.state;

    const isManager = userProfile && userProfile.isManager;
    const isScrumMaster = userProfile && userProfile.isScrumMaster;
    const isPartner = userProfile && userProfile.partnership;

    const displayTeam = isManager || isScrumMaster || isPartner;
    const displayTeamApprovals = isManager;
    const displayTeamResults = isManager || isScrumMaster;
    const displayTeamStatistic = isManager || isPartner;

    return (
      <main className="landing-page homepage">
        <div className="container">
          <div
            id="home"
            className="accordion"
          >
            <div className="card reciving">
              <div
                id="headingOne"
                className="card-header"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <img
                      src={receiveIcon}
                      alt="receiving-feedback"
                    />
                    {t('menu.label.receiving_feedback')}
                  </button>
                </h5>
              </div>
              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#home"
              >
                <div className="card-body">
                  <div className="sub-accordion">
                    <div className="sub-accordion-item">
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.feedback_to_me')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <Link
                            className="btn standard"
                            to="/survey"
                          >
                            {t('menu.link.view')}
                            {feedbackToMe > 0 && (
                              <span className="circle-notification">
                                {feedbackToMe}
                              </span>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="sub-accordion-item">
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.compare_with_others')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <Link
                            className="btn standard"
                            to="/statistic"
                          >
                            {t('menu.link.view')}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="sub-accordion-item">
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.request_feedback')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <button
                            className="btn standard"
                            type="button"
                            onClick={this.handleDialogOpen}
                          >
                            {t('menu.link.request')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card giving">
              <div
                id="headingTwo"
                className="card-header"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <img
                      src={giveIcon}
                      alt="giving-feedback"
                    />
                    {t('menu.label.giving_feedback')}
                  </button>
                </h5>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#home"
              >
                <div className="card-body">
                  <div className="sub-accordion">
                    <div className="sub-accordion-item">
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.feedback_to_others')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <Link
                            className="btn standard"
                            to="/feedback"
                          >
                            {t('menu.link.view_surveys')}
                            {feedbackToOthers > 0 && (
                              <span className="circle-notification">
                                {feedbackToOthers}
                              </span>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="sub-accordion-item">
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.provide_feedback_proactively')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <Link
                            className="btn standard"
                            to="/provide-feedback"
                          >
                            {t('menu.link.provide')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`card team ${displayTeam ? '' : 'd-none'}`}>
              <div
                id="headingThree"
                className="card-header"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <img
                      src={teamIcon}
                      alt="team"
                    />
                    {t('menu.label.team_feedback')}
                  </button>
                </h5>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#home"
              >
                <div className="card-body">
                  <div className="sub-accordion">
                    <div className={`sub-accordion-item ${displayTeamApprovals ? '' : 'd-none'}`}>
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.approvals')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <Link
                            className="btn standard"
                            to="/approvals"
                          >
                            {t('menu.link.view')}
                            {approve > 0 && (
                              <span className="circle-notification">
                                {approve}
                              </span>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className={`sub-accordion-item ${displayTeamResults ? '' : 'd-none'}`}>
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.team_members_feedback')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <Link
                            className="btn standard"
                            to="/survey/team"
                          >
                            {t('menu.link.view')}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className={`sub-accordion-item ${displayTeamStatistic ? '' : 'd-none'}`}>
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.result_for_my_organization')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <Link
                            className="btn standard"
                            to="/statistic/team"
                          >
                            {t('menu.link.view')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card todo">
              <div
                id="headingFour"
                className="card-header"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <img
                      src={toDoIcon}
                      alt="todo"
                    />
                    {t('menu.label.my_todo')}
                  </button>
                </h5>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-parent="#home"
              >
                <div className="card-body">
                  <div className="sub-accordion">
                    <NotificationsList onDataChange={this.handleDataChange} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card support">
              <div
                id="headingFive"
                className="card-header"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <img
                      src={support}
                      alt="support"
                    />
                    {t('menu.label.extra_support')}
                  </button>
                </h5>
              </div>
              <div
                id="collapseFive"
                className="collapse"
                aria-labelledby="headingFive"
                data-parent="#home"
              >
                <div className="card-body">
                  <div className="sub-accordion">
                    <div className="sub-accordion-item">
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.learn_more')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <a
                            className="btn standard"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://cchellenic.sharepoint.com/sites/intranet/hr/Pages/Leaders%20Hub/Talent/All%20about%20Performance/Performance%20for%20Growth/P4G.aspx"
                          >
                            {t('menu.link.leaders_hub')}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="sub-accordion-item">
                      <div className="row">
                        <div className="col my-auto">
                          <div className="sub-acordion-title">
                            {t('menu.label.ask_for_help')}
                          </div>
                        </div>
                        <div className="col text-right">
                          <a
                            className="btn standard"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://cchellenic.sharepoint.com/sites/intranet/hr/Documents/Leaders%20Hub/Leader's%20Hub/P4G%20Coordinaros%20List-v4.pdf"
                          >
                            {t('menu.link.hr_support')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`pop-up-filter-wrapper ${dialogOpened ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                role="button"
                tabIndex={0}
                onClick={this.handleDialogClose}
                onKeyPress={this.handleDialogClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('menu.label.request_feedback')}
              </h3>
              <div className="form-group choose">
                <div className="row">
                  <div className="col mt-3">
                    <Link
                      className="btn standard"
                      to="/add-survey/freestyle_feedback"
                    >
                      {t('menu.label.request_freestyle_feedback')}
                    </Link>
                  </div>
                  <div className="col mt-3">
                    <Link
                      className="btn standard"
                      to="/add-survey/project_feedback"
                    >
                      {t('menu.label.request_project_feedback')}
                    </Link>
                  </div>
                  <div className="col mt-3">
                    <Link
                      className="btn standard"
                      to="/add-survey/pod_feedback"
                    >
                      {t('menu.label.request_pod_feedback')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="form-group text-center">
                <button
                  className="btn standard gray"
                  type="button"
                  onClick={this.handleDialogClose}
                >
                  {t('survey.action.cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ userProfile, token }) => ({ userProfile, token });

const mapDispatchToProps = dispatch => bindActionCreators({ me }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(LandingPage));

LandingPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  userProfile: PropTypes.shape({}),
  token: PropTypes.string,
  me: PropTypes.func.isRequired,
};

LandingPage.defaultProps = {
  userProfile: null,
  token: null,
};
