import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import tip1 from '../../assets/images/tip-1.svg';
import tip2 from '../../assets/images/tip-2.svg';
import tip3 from '../../assets/images/tip-3.svg';
import tip4 from '../../assets/images/tip-4.svg';
import tip5 from '../../assets/images/tip-5.svg';

const TipsProvide = ({ t }) => (
  <div className="tips">
    <p align="center">
      <strong>
        {t('answer.label.tips')}
        :
      </strong>
    </p>
    <div
      className="carousel slide"
      id="carousel"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item red active">
          <div className="row">
            <div className="col-3">
              <img
                src={tip1}
                alt="tip 1"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                <strong>
                  {t('answer.label.tips_1_title')}
                  :
                  {' '}
                </strong>
                {t('answer.label.tips_1_description')}
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item green green">
          <div className="row">
            <div className="col-3">
              <img
                src={tip2}
                alt="tip 2"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                <strong>
                  {t('answer.label.tips_2_title')}
                  :
                  {' '}
                </strong>
                {t('answer.label.tips_2_description')}
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item yellow">
          <div className="row">
            <div className="col-3">
              <img
                src={tip3}
                alt="tip 3"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                <strong>
                  {t('answer.label.tips_3_title')}
                  :
                  {' '}
                </strong>
                {t('answer.label.tips_3_description')}
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item dark-red ">
          <div className="row">
            <div className="col-3">
              <img
                src={tip4}
                alt="tip 4"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                <strong>
                  {t('answer.label.tips_4_title')}
                  :
                  {' '}
                </strong>
                {t('answer.label.tips_4_description')}
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item yellow black">
          <div className="row">
            <div className="col-3">
              <img
                src={tip5}
                alt="tip 5"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                <strong>
                  {t('answer.label.tips_5_title')}
                  :
                  {' '}
                </strong>
                {t('answer.label.tips_5_description')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        className="carousel-control-prev"
        href="#carousel"
        role="button"
        data-slide="prev"
      >
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"
        />
        <span className="sr-only">
          {t('survey.action.previous')}
        </span>
      </a>
      <a
        className="carousel-control-next"
        href="#carousel"
        role="button"
        data-slide="next"
      >
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"
        />
        <span className="sr-only">
          {t('survey.action.next')}
        </span>
      </a>
    </div>
  </div>
);

export default withNamespaces()(TipsProvide);

TipsProvide.propTypes = {
  t: PropTypes.func.isRequired,
};
