import React, { Component } from 'react';

import AdminSurveyExcludeRulesFilters from '../../containers/AdminSurveyExcludeRulesFilters';
import AdminSurveyExcludeRuleTable from '../../containers/AdminSurveyExcludeRuleTable';

class AdminSurveyExcludeRulesPage extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedRule: null };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleFormOpen = this.handleFormOpen.bind(this);
    this.handleFormClose = this.handleFormClose.bind(this);
  }

  handleEditClick(selectedRule) {
    this.setState({ selectedRule });
  }

  handleFormOpen() {
    this.setState({ selectedRule: null });
  }

  handleFormClose() {
    this.setState({ selectedRule: null });
  }

  render() {
    const { selectedRule } = this.state;

    return (
      <main className="admin admin-survey-exclude-rules-page">
        <div className="container">
          <div className="table-survey-exclude-rules-wrapper">
            <AdminSurveyExcludeRulesFilters
              surveyExcludeRule={selectedRule}
              onFormClose={this.handleFormClose}
            />
            <AdminSurveyExcludeRuleTable onEditClick={this.handleEditClick} />
          </div>
        </div>
      </main>
    );
  }
}

export default AdminSurveyExcludeRulesPage;
