import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import SubmitField from '../../components/SubmitField';
import TextAreaField from '../../components/TextAreaField';
import { updateFeedback } from '../../redux-modules/feedback/actions';
import fetchCallToAction from '../../redux-modules/call-to-action/actions';
import validators from '../../utils/validators';

import './cant-provide-form.css';

class CantProvideForm extends Component {
  handleSubmit(values) {
    const {
      token,
      url,
      updateFeedback: updateFeedbackAction,
      fetchCallToAction: fetchCallToActionAction,
      onSend,
      initialize,
    } = this.props;

    if (!values.message) {
      return;
    }

    const splitUrl = url.split('/');
    const feedbackId = splitUrl[1];

    const newMessage = {
      question: 'ecd4b0b9-55fd-470d-8d99-83df24d6ab22',
      comment: values.message,
    };

    updateFeedbackAction(token, feedbackId, [newMessage]).then(
      () => {
        initialize({ message: '' });
        fetchCallToActionAction(token);
        onSend();
      },
      () => { fetchCallToActionAction(token); },
    );
  }

  render() {
    const { t, handleSubmit, onCancel } = this.props;

    return (
      <div className="cant-provide-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <p>
            {t('feedback.label.cant_provide')}
          </p>
          <p>
            {t('feedback.label.cant_provide_desc')}
          </p>
          <div className="form-group">
            <Field
              name="message"
              component={TextAreaField}
            />
          </div>
          <div className="text-center mb-2 mt-4">
            <div className="buttons-group-wrapper">
              <button
                className="d-inline-block btn standard gray"
                type="button"
                onClick={onCancel}
              >
                {t('survey.action.cancel')}
              </button>
              <Field
                inline
                className="btn standard d-inline-block"
                name="submit"
                label={t('user.action.send')}
                component={SubmitField}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.message)) {
    errors.message = t('feedback.error.message_required');
  }

  return errors;
};

const mapStateToProps = state => ({
  token: state.token,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  { updateFeedback, fetchCallToAction },
  dispatch,
);

export default withNamespaces()(reduxForm({
  validate,
  form: 'feedbackCantProvideForm',
})(connect(mapStateToProps, mapDispatchToProps)(CantProvideForm)));

CantProvideForm.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  fetchCallToAction: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
};
