import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminSurveyFilters from '../../containers/AdminSurveyFilters';
import AdminSurveyTable from '../../containers/AdminSurveyTable';

class AdminSurveysPage extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id) {
    const { history: { push } } = this.props;
    push(`/survey/${id}`);
  }

  render() {
    return (
      <main className="admin admin-surveys-page">
        <div className="container">
          <div className="table-survey-wrapper">
            <AdminSurveyFilters />
            <AdminSurveyTable onClick={id => this.handleClick(id)} />
          </div>
        </div>
      </main>
    );
  }
}

export default AdminSurveysPage;

AdminSurveysPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
