import i18next from 'i18next';
import Backend from 'i18next-locize-backend';

import translationSQAL from '../locales/sq-AL/translation';
import translationAREG from '../locales/ar-EG/translation';
import translationHYAM from '../locales/hy-AM/translation';
import translationBGBG from '../locales/bg-BG/translation';
import translationHRHR from '../locales/hr-HR/translation';
import translationCSCZ from '../locales/cs-CZ/translation';
import translationEN from '../locales/en/translation';
import translationENGB from '../locales/en-GB/translation';
import translationENUS from '../locales/en-US/translation';
import translationETEE from '../locales/et-EE/translation';
import translationFRFR from '../locales/fr-FR/translation';
import translationDEDE from '../locales/de-DE/translation';
import translationELGR from '../locales/el-GR/translation';
import translationHUHU from '../locales/hu-HU/translation';
import translationITIT from '../locales/it-IT/translation';
import translationLVLV from '../locales/lv-LV/translation';
import translationLTLT from '../locales/lt-LT/translation';
import translationPLPL from '../locales/pl-PL/translation';
import translationRORO from '../locales/ro-RO/translation';
import translationRURU from '../locales/ru-RU/translation';
import translationSR from '../locales/sr-Latn-RS/translation';
import translationSKSK from '../locales/sk-SK/translation';
import translationSLSI from '../locales/sl-SI/translation';
import translationUKUA from '../locales/uk-UA/translation';

const resourcesOptions = {
  fallbackLng: 'en-GB',
  nsSeparator: false,
  resources: {
    'sq-AL': {
      translation: translationSQAL,
    },
    'ar-EG': {
      translation: translationAREG,
    },
    'hy-AM': {
      translation: translationHYAM,
    },
    'bg-BG': {
      translation: translationBGBG,
    },
    'hr-HR': {
      translation: translationHRHR,
    },
    'cs-CZ': {
      translation: translationCSCZ,
    },
    en: {
      translation: translationEN,
    },
    'en-GB': {
      translation: translationENGB,
    },
    'en-US': {
      translation: translationENUS,
    },
    'et-EE': {
      translation: translationETEE,
    },
    'fr-FR': {
      translation: translationFRFR,
    },
    'de-DE': {
      translation: translationDEDE,
    },
    'el-GR': {
      translation: translationELGR,
    },
    'hu-HU': {
      translation: translationHUHU,
    },
    'it-IT': {
      translation: translationITIT,
    },
    'lv-LV': {
      translation: translationLVLV,
    },
    'lt-LT': {
      translation: translationLTLT,
    },
    'pl-PL': {
      translation: translationPLPL,
    },
    'ro-RO': {
      translation: translationRORO,
    },
    'ru-RU': {
      translation: translationRURU,
    },
    'sr-Latn-RS': {
      translation: translationSR,
    },
    'sk-SK': {
      translation: translationSKSK,
    },
    'sl-SI': {
      translation: translationSLSI,
    },
    'uk-UA': {
      translation: translationUKUA,
    },
  },
};

const backendOptions = {
  fallbackLng: 'en',
  backend: {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    private: true,
  },
};

const translationSource = process.env.REACT_APP_LOCIZE === 'true' ? backendOptions : resourcesOptions;
i18next
  .use(Backend)
  .init(translationSource);

export default i18next;
