import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErrorHandler from '../ErrorHandler';
import SignInCheckPage from '../../pages/SignInCheckPage';
import SignInPage from '../../pages/SignInPage';
import SignInForcePage from '../../pages/SignInForcePage';
import SignUpPage from '../../pages/SignUpPage';
import AdminLandingPage from '../../admin/pages/AdminLandingPage';
import AdminSurveysPage from '../../admin/pages/AdminSurveysPage';
import AdminUsersPage from '../../admin/pages/AdminUsersPage';
import AdminSurveyExcludeRulesPage from '../../admin/pages/AdminSurveyExcludeRulesPage';
import AdminTransferHistoryPage from '../../admin/pages/AdminTransferHistoryPage';
import AdminUpdateSurveyPage from '../../admin/pages/AdminUpdateSurveyPage';
import ActivatePage from '../../pages/ActivatePage';
import ConfirmationPage from '../../pages/ConfirmationPage';
import ResetPasswordRequestPage from '../../pages/ResetPasswordRequestPage';
import ResetPasswordConfirmPage from '../../pages/ResetPasswordConfirmPage';
import SignOutPage from '../../pages/SignOutPage';
import WelcomePage from '../../pages/WelcomePage';
import ProfilePage from '../../pages/ProfilePage';
import Notification from '../Notification';
import LanguageHandler from '../LanguageHandler';

const AdminApp = ({ token, userProfile }) => (
  <div className="app">
    <ErrorHandler />
    <Notification />
    <LanguageHandler />
    <BrowserRouter>
      <div>
        <Switch>
          <Route
            exact
            path="/login-check/:token"
            component={SignInCheckPage}
          />
          <Route
            exact
            path="/sign-in"
            component={SignInPage}
          />
          <Route
            exact
            path="/sign-in-force"
            component={SignInForcePage}
          />
          <Route
            exact
            path="/sign-up"
            component={SignUpPage}
          />
          <Route
            exact
            path="/sign-up-confirmation"
            component={ConfirmationPage}
          />
          <Route
            exact
            path="/activate/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
            component={ActivatePage}
          />
          <Route
            exact
            path="/reset-password-request"
            component={ResetPasswordRequestPage}
          />
          <Route
            exact
            path="/reset-password-request/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
            component={ResetPasswordConfirmPage}
          />
          <Route
            exact
            path="/welcome"
            component={WelcomePage}
          />
          <AdminLandingPage>
            <Route component={() => (
              <Switch>
                { token ? '' : <Redirect to="/sign-in" /> }
                { token && userProfile && userProfile.language ? '' : <Redirect to="/welcome" /> }
                <Route
                  exact
                  path="/"
                  component={AdminSurveysPage}
                />
                <Route
                  exact
                  path="/users"
                  component={AdminUsersPage}
                />
                <Route
                  exact
                  path="/profile"
                  component={ProfilePage}
                />
                <Route
                  exact
                  path="/survey/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
                  component={AdminUpdateSurveyPage}
                />
                <Route
                  exact
                  path="/survey-exclude-rules"
                  component={AdminSurveyExcludeRulesPage}
                />
                <Route
                  exact
                  path="/transfer-history"
                  component={AdminTransferHistoryPage}
                />
                <Route
                  exact
                  path="/sign-out"
                  component={SignOutPage}
                />
                <Redirect to="/" />
              </Switch>
            )}
            />
          </AdminLandingPage>
        </Switch>
      </div>
    </BrowserRouter>
  </div>
);

const mapStateToProps = ({ token, userProfile }) => ({ token, userProfile });

export default connect(mapStateToProps, null)(AdminApp);

AdminApp.propTypes = {
  token: PropTypes.string,
  userProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string,
  }),
};

AdminApp.defaultProps = {
  token: null,
  userProfile: null,
};
