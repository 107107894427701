import _ from 'lodash';

import USERS_FETCHED from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_FETCHED: {
      return _.get(action.payload, 'data.users', state);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
