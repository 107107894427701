import axios from 'axios';

import {
  SURVEYS_ADMIN_FETCHED,
  SURVEY_ADMIN_FETCHED,
  SURVEYS_ADMIN_UPDATED,
  SURVEYS_ADMIN_REMOVED,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchSurveysAdmin = (token, type, year) => {
  const url = `${REST_API_URL}v1/survey-group`;
  const request = axios.get(
    url,
    {
      params: {
        type,
        year,
      },
    },
  );

  return {
    type: SURVEYS_ADMIN_FETCHED,
    payload: request,
  };
};

const fetchSurveyAdmin = (token, id) => {
  const url = `${REST_API_URL}v1/survey-group/${id}`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEY_ADMIN_FETCHED,
    payload: request,
  };
};

const updateSurveysAdmin = (token, id, launchDate, endDate) => {
  const url = `${REST_API_URL}v1/survey-group/${id}`;
  const request = axios.put(
    url,
    {
      launchDate,
      endDate,
    },
  );

  return {
    type: SURVEYS_ADMIN_UPDATED,
    payload: request,
  };
};

const removeSurveysAdmin = (token, id) => {
  const url = `${REST_API_URL}v1/survey-group/${id}`;
  const request = axios.delete(
    url,
  );

  return {
    type: SURVEYS_ADMIN_REMOVED,
    payload: request,
  };
};

export {
  fetchSurveysAdmin,
  fetchSurveyAdmin,
  updateSurveysAdmin,
  removeSurveysAdmin,
};
