/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import ReportOverallResults from '../ReportOverallResults';
import SurveyReportItem from '../SurveyReportItem';
import CantProvideComments from '../CantProvideComments';
import ExcludedPeers from '../ExcludedPeers';
import AllPeers from '../AllPeers';
import tooltip from '../../utils/tooltip';

import './report-details.css';


class ReportDetails extends Component {
  static handleExport() {
    window.print();
  }

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('reports');
    window.scrollTo(0, 0);
    tooltip.initialize();
  }

  componentDidUpdate() {
    tooltip.initialize();
  }

  componentWillUnmount() {
    document.body.classList.remove('reports');
  }

  handleClose() {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
      return;
    }
    history.push('/');
  }

  render() {
    const { t, data } = this.props;

    const overallRating = _.groupBy(data.answers, 'rating');
    const cantProvideComments = _.values(data.answers).filter(answer => (answer.isComment));
    const questions = _.values(data.questions).map((question, index) => {
      const groupedAnswers = _.groupBy(data.answers, 'question');
      const answers = _.get(groupedAnswers, question.id, null);
      const selfAssessment = _.get(data, 'selfAssessment', []);
      const groupedRating = _.groupBy(answers, 'rating');
      const selfAssessmentIndexed = _.keyBy(selfAssessment, 'question');
      const hasAnswers = (answers || [])
        .filter(answer => answer.rating > 0 || answer.comment)
        .length > 0;

      const comments = {};
      const providers = {};
      (answers || []).forEach((answer, i) => {
        const key = `${question.id + i}`;
        if (answer.comment) {
          comments[key] = answer.comment;
        }
        if (answer.provider) {
          providers[key] = answer.provider;
        }
      });

      const showComments = data.type === 'freestyle_feedback' && index === 0;

      if (question.optional && !hasAnswers) {
        return null;
      }

      let scale = _.get(question, 'scale', []);
      scale = _.keyBy(scale, 'value');

      let questionText = t(question.text);
      if (!questionText) {
        questionText = question.text;
      }

      return (
        <div
          className="card"
          key={question.id}
        >
          <SurveyReportItem
            id={question.id}
            index={index}
            showComments={showComments}
            questionText={questionText}
            rating1={_.get(groupedRating, 1, []).length}
            rating2={_.get(groupedRating, 2, []).length}
            rating3={_.get(groupedRating, 3, []).length}
            rating4={_.get(groupedRating, 4, []).length}
            rating5={_.get(groupedRating, 5, []).length}
            scale={scale}
            comments={comments}
            providers={providers}
            selfAssessment={_.get(selfAssessmentIndexed, question.id, null)}
          />
        </div>
      );
    });

    const providers = _.values(data.answers).map(answer => answer.provider);
    const uniqProviders = _.uniq(providers).join(', ');

    const normalReport = (
      <div>
        <ReportOverallResults
          rating1={_.get(overallRating, 1, []).length}
          rating2={_.get(overallRating, 2, []).length}
          rating3={_.get(overallRating, 3, []).length}
          rating4={_.get(overallRating, 4, []).length}
          rating5={_.get(overallRating, 5, []).length}
          progress={{
            total: data.progressTotal,
            count: data.progressCount,
          }}
          favorability={data.score}
          selfAssessment={data.selfAssessment}
        />
        <div
          className="report-accordion"
          id="accordion"
        >
          {questions}
          <CantProvideComments
            comments={cantProvideComments}
          />
          {data.type === 'peer_feedback' && (
            <AllPeers
              allProviders={data.allProviders}
            />
          )}
          {data.type === 'peer_feedback' && false && (
            <ExcludedPeers
              removedProviders={data.removedProviders}
            />
          )}
        </div>
      </div>
    );

    const freestyleReport = (
      <div className="report-box">
        <div className="report-box-title">
          {t('survey.type.freestyle_feedback_proactively')}
          {' '}
          {t('survey.label.by')}
          {' '}
          {uniqProviders}
        </div>
        <hr />
        <div className="comment-section">
          <div className="comment-listing">
            <div className="comment-box">
              <strong>
                {uniqProviders}
              </strong>
              :
              {' '}
              {_.get(data, 'answers[0].comment', '')}
            </div>
          </div>
        </div>
      </div>
    );

    const isFreestyleFeedback = data.type === 'freestyle_feedback'
      && data.questions.length === 1
      && !data.questions[0].rating
      && !data.questions[0].text;

    return (
      <main className="report-details reports">
        <div className="container">
          <div className="row heading">
            <div className="report-close">
              <span
                className="close-menu"
                role="button"
                tabIndex={0}
                onClick={this.handleClose}
                onKeyPress={this.handleClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
            </div>
            <div className="col my-auto">
              <div className="report-title">
                {data.year}
                {' '}
                Q
                {data.quarter}
                {' '}
                {data.title ? data.title : ''}
                {' '}
                {t(`survey.type.${data.type}`)}
                {' '}
                {t('survey.label.for')}
                {' '}
                {data.fullName}
              </div>
              {data.initiator && (
                <small>
                  {t('survey.label.scrum_master').replace(/<scrum master>/g, data.initiator)}
                </small>
              )}
              <div>
                <p>
                  {uniqProviders}
                </p>
              </div>
            </div>
            <div className="report-export">
              <button
                type="button"
                className="btn standard gray"
                onClick={ReportDetails.handleExport}
              >
                {t('survey.label.export')}
              </button>
            </div>
          </div>
          <hr />
          {isFreestyleFeedback ? freestyleReport : normalReport}
        </div>
      </main>
    );
  }
}

export default withNamespaces()(withRouter(ReportDetails));

ReportDetails.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

ReportDetails.defaultProps = {
  data: null,
};
