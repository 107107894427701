import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import InputField from '../../components/InputField';
import SubmitField from '../../components/SubmitField';
import { login } from '../../redux-modules/auth/actions';
import { me } from '../../redux-modules/me/actions';
import validators from '../../utils/validators';

class SignInForm extends Component {
  handleSubmit(values) {
    const {
      t,
      login: loginAction,
      me: meAction,
      onSignIn,
    } = this.props;

    return loginAction(
      values.email,
      values.password,
    ).then(
      (data) => {
        const token = _.get(data, 'payload.data.token', null);
        meAction(token).then(
          onSignIn || (() => {}),
          () => {},
        );
      },
      (error) => {
        const code = _.get(error, 'response.data.code', 200);
        if (code === 401) {
          throw new SubmissionError({
            email: t('user.error.email_bad_entry'),
            password: t('user.error.password_bad_entry'),
          });
        }
      },
    );
  }

  render() {
    const { t, handleSubmit } = this.props;

    return (
      <div className="sign-in-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group">
            <Field
              name="email"
              type="email"
              label={t('user.label.email')}
              showLabel
              placeholder={t('user.placeholder.email')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="password"
              type="password"
              showLabel
              label={t('user.label.password')}
              placeholder={t('user.placeholder.password')}
              component={InputField}
            />
          </div>
          <div className="form-group text-center">
            <Field
              name="submit"
              label={t('user.action.login')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.email) || !validators.email(values.email)) {
    errors.email = t('user.error.email_required');
  }

  if (!validators.minLength(values.password, 8)) {
    errors.password = t('user.error.password_min_length');
  }

  return errors;
};

const mapDispatchToProps = dispatch => bindActionCreators({ login, me }, dispatch);

export default withNamespaces()(reduxForm({
  validate,
  form: 'signInForm',
})(connect(null, mapDispatchToProps)(SignInForm)));

SignInForm.propTypes = {
  t: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  me: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSignIn: PropTypes.func,
};

SignInForm.defaultProps = {
  onSignIn: null,
};
