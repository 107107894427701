import axios from 'axios';

import BUSINESS_UNIT_FETCHED from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchBusinessUnit = () => {
  const url = `${REST_API_URL}v1/business-unit`;
  const request = axios.get(
    url,
  );

  return {
    type: BUSINESS_UNIT_FETCHED,
    payload: request,
  };
};

export default fetchBusinessUnit;
