import axios from 'axios';

import CALL_TO_ACTION_FETCHED from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchCallToAction = () => {
  const url = `${REST_API_URL}v1/notification`;
  const request = axios.get(
    url,
  );

  return {
    type: CALL_TO_ACTION_FETCHED,
    payload: request,
  };
};

export default fetchCallToAction;
