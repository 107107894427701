import _ from 'lodash';

import USER_REGISTERED from './types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTERED: {
      return _.get(action.payload, 'data', state);
    }
    default: {
      return state;
    }
  }
};
