import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReportDetails from '../../components/ReportDetails';
import { fetchSurveyReport } from '../../redux-modules/survey-report/actions';

class SurveyReportDetails extends Component {
  componentDidMount() {
    const { token, id, fetchSurveyReport: fetchSurveyReportAction } = this.props;
    fetchSurveyReportAction(token, id);
  }

  render() {
    const { surveyReport } = this.props;

    if (!surveyReport) {
      return <div />;
    }

    return (
      <div>
        <ReportDetails data={surveyReport} />
      </div>
    );
  }
}

const mapStateToProps = ({ token, surveyReport }) => ({ token, surveyReport });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchSurveyReport }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyReportDetails);

SurveyReportDetails.propTypes = {
  id: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  surveyReport: PropTypes.shape({}),
  fetchSurveyReport: PropTypes.func.isRequired,
};

SurveyReportDetails.defaultProps = {
  surveyReport: null,
};
