import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import { Line, Radar, Bar } from 'react-chartjs-2';
import moment from 'moment';

import SelectField from '../SelectField';

class SurveyStatistic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterType: '',
      timeframe: '',
      chartType: 'all',
      selectedUser: 0,
    };
    this.renderStatistic = this.renderStatistic.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentDidMount() {
    const { initialize, isPartner } = this.props;

    initialize({
      compare: 'business-unit',
      participation: 'progress_in_time',
      user: isPartner ? 'all_bu' : null,
    });
  }

  handleFilterChange(chartType, filterType) {
    const { onChartChange } = this.props;
    this.setState({
      filterType,
      chartType,
    });

    if (!filterType) {
      onChartChange('all', '');
      return;
    }
    if (chartType === 'participation') {
      onChartChange('participation', filterType);
      return;
    }

    onChartChange('progress', filterType);
  }

  handleUserChange(user) {
    const { onUserChange } = this.props;

    this.setState({ selectedUser: user });

    onUserChange(user);
  }

  renderSpiderWeb(user, compare, chartType) {
    const { t, isManager } = this.props;

    const a = [];
    const b = [];

    user.forEach((u) => {
      const isCustomerFeedback = u.type === 'external_customer_feedback'
        || u.type === 'internal_customer_feedback';
      const type = isCustomerFeedback ? 'customer_feedback' : u.type;

      if (!(type in a)) {
        a[type] = [];
      }
      a[type].push(u);
    });

    compare.forEach((u) => {
      const isCustomerFeedback = u.type === 'external_customer_feedback'
        || u.type === 'internal_customer_feedback';
      const type = isCustomerFeedback ? 'customer_feedback' : u.type;

      if (!(type in b)) {
        b[type] = [];
      }
      b[type].push(u);
    });

    const dataForUpwardsScore = 'upwards_feedback' in a
      ? a.upwards_feedback.filter(u => u.score > 0) : [];
    const dataForCustomerScore = 'customer_feedback' in a
      ? a.customer_feedback.filter(u => u.score > 0) : [];
    const dataForPeerScore = 'peer_feedback' in a
      ? a.peer_feedback.filter(u => u.score > 0) : [];
    const dataForProjectScore = 'project_feedback' in a
      ? a.project_feedback.filter(u => u.score > 0) : [];

    const dataForUpwardsScoreCompare = 'upwards_feedback' in b
      ? b.upwards_feedback.filter(u => u.score > 0) : [];
    const dataForCustomerScoreCompare = 'customer_feedback' in b
      ? b.customer_feedback.filter(u => u.score > 0) : [];
    const dataForPeerScoreCompare = 'peer_feedback' in b
      ? b.peer_feedback.filter(u => u.score > 0) : [];
    const dataForProjectScoreCompare = 'project_feedback' in b
      ? b.project_feedback.filter(u => u.score > 0) : [];

    const upwardsScore = dataForUpwardsScore.length > 0
      ? Math.round(dataForUpwardsScore
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForUpwardsScore.length)
      : 0;
    const customerScore = dataForCustomerScore.length > 0
      ? Math.round(dataForCustomerScore
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForCustomerScore.length)
      : 0;
    const peerScore = dataForPeerScore.length > 0
      ? Math.round(dataForPeerScore
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForPeerScore.length)
      : 0;
    const projectScore = dataForProjectScore.length > 0
      ? Math.round(dataForProjectScore
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForProjectScore.length)
      : 0;

    const upwardsScoreCompare = dataForUpwardsScoreCompare.length > 0
      ? Math.round(dataForUpwardsScoreCompare
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForUpwardsScoreCompare.length)
      : 0;
    const customerScoreCompare = dataForCustomerScoreCompare.length > 0
      ? Math.round(dataForCustomerScoreCompare
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForCustomerScoreCompare.length)
      : 0;
    const peerScoreCompare = dataForPeerScoreCompare.length > 0
      ? Math.round(dataForPeerScoreCompare
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForPeerScoreCompare.length)
      : 0;
    const projectScoreCompare = dataForProjectScoreCompare.length > 0
      ? Math.round(dataForProjectScoreCompare
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / dataForProjectScoreCompare.length)
      : 0;

    const upwardsProgress = 'upwards_feedback' in a
      ? Math.round(a.upwards_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / a.upwards_feedback.length)
      : 0;
    const customerProgress = 'customer_feedback' in a
      ? Math.round(a.customer_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / a.customer_feedback.length)
      : 0;

    const peerProgress = 'peer_feedback' in a
      ? Math.round(a.peer_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / a.peer_feedback.length)
      : 0;
    const projectProgress = 'project_feedback' in a
      ? Math.round(a.project_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / a.project_feedback.length)
      : 0;
    const upwardsProgressCompare = 'upwards_feedback' in b
      ? Math.round(b.upwards_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / b.upwards_feedback.length)
      : 0;

    const customerProgressCompare = 'customer_feedback' in b
      ? Math.round(b.customer_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / b.customer_feedback.length)
      : 0;
    const peerProgressCompare = 'peer_feedback' in b
      ? Math.round(b.peer_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / b.peer_feedback.length)
      : 0;
    const projectProgressCompare = 'project_feedback' in b
      ? Math.round(b.project_feedback
        .map(u => u.progressCount / u.progressTotal)
        .reduce((first, second) => first + second) * 100 / b.project_feedback.length)
      : 0;

    const managerData = chartType === 'score'
      ? [upwardsScore, customerScore, peerScore, projectScore]
      : [upwardsProgress, customerProgress, peerProgress, projectProgress];

    const userData = chartType === 'score'
      ? [customerScore, peerScore, projectScore]
      : [customerProgress, peerProgress, projectProgress];

    const managerDataCompare = chartType === 'score'
      ? [upwardsScoreCompare, customerScoreCompare, peerScoreCompare, projectScoreCompare]
      : [
        upwardsProgressCompare,
        customerProgressCompare,
        peerProgressCompare,
        projectProgressCompare,
      ];

    const userDataCompare = chartType === 'score'
      ? [customerScoreCompare, peerScoreCompare, projectScoreCompare]
      : [customerProgressCompare, peerProgressCompare, projectProgressCompare];

    const data = {
      labels: isManager
        ? [
          t('survey.type.upwards_feedback').split(' '),
          t('survey.type.customer_feedback').split(' '),
          t('survey.type.peer_feedback').split(' '),
          t('survey.type.project_feedback').split(' '),
        ]
        : [
          t('survey.type.customer_feedback').split(' '),
          t('survey.type.peer_feedback').split(' '),
          t('survey.type.project_feedback').split(' '),
        ],
      datasets: [
        {
          label: t('survey.label.your_results'),
          backgroundColor: 'transparent',
          borderColor: 'rgb(228, 33, 44)',
          fill: false,
          radius: 4,
          pointRadius: 4,
          pointBorderWidth: 3,
          pointBackgroundColor: 'rgb(228, 33, 44)',
          pointBorderColor: 'rgb(228, 33, 44)',
          pointHoverRadius: 10,
          data: isManager
            ? managerData
            : userData,
        },
        {
          label: t('survey.label.benchmark'),
          backgroundColor: 'transparent',
          borderColor: 'rgb(0, 0, 0)',
          fill: false,
          radius: 4,
          pointRadius: 4,
          pointBorderWidth: 3,
          pointBackgroundColor: 'rgb(0, 0, 0)',
          pointBorderColor: 'rgb(0, 0, 0)',
          pointHoverRadius: 10,
          data: isManager
            ? managerDataCompare
            : userDataCompare,
        },
      ],
    };
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scale: {
        labelMaxWidth: 50,
        ticks: {
          mirror: true,
          display: false,
          beginAtZero: true,
          min: 0,
          max: 100,
          stepSize: 20,
        },
        pointLabels: {
          fontSize: 12,
          fontColor: 'rgb(228, 33, 44)',
          fontStyle: 'bold',
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: () => '',
          label: (item, dataset) => `${Number(item.yLabel)}% - ${dataset.labels[item.index].join(' ')}`,
        },
      },
    };

    return (
      <div style={{ position: 'relative', height: '350px' }}>
        <Radar
          data={data}
          options={chartOptions}
        />
      </div>
    );
  }

  renderScoreBar(user, compare) {
    const { t } = this.props;

    const a = [];
    const b = [];
    const x = [];

    user.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in a)) {
        a[key] = [];
      }
      a[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    compare.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in b)) {
        b[key] = [];
      }
      b[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    const data1 = x.sort().map(key => (key in a
      ? Math.round(a[key]
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / a[key].length)
      : 0
    ));
    const data2 = x.sort().map(key => (key in b
      ? Math.round(b[key]
        .map(u => u.score)
        .reduce((first, second) => first + second) * 100 / b[key].length)
      : 0
    ));
    const dataFirst = {
      label: t('survey.label.your_results'),
      backgroundColor: 'transparent',
      borderColor: 'rgb(228, 33, 44)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(228, 33, 44)',
      pointBorderColor: 'rgb(228, 33, 44)',
      pointHoverRadius: 4,
      data: data1,
    };
    const dataSecond = {
      label: t('survey.label.benchmark'),
      backgroundColor: 'transparent',
      borderColor: 'rgb(0, 0, 0)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(0, 0, 0)',
      pointBorderColor: 'rgb(0, 0, 0)',
      pointHoverRadius: 4,
      data: data2,
    };
    const speedData = {
      labels: x.sort(),
      datasets: [dataFirst, dataSecond],
    };
    const chartOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              maxTicksLimit: 100,
              min: 0,
              max: 100,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: item => `${item.yLabel}%`,
        },
      },
    };

    return (
      <Line
        data={speedData}
        options={chartOptions}
      />
    );
  }

  renderParticipationBar(user, compare) {
    const { t } = this.props;
    const { filterType } = this.state;

    const a = [];
    const b = [];
    const x = [];

    user.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in a)) {
        a[key] = [];
      }
      a[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    compare.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in b)) {
        b[key] = [];
      }
      b[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    const data1 = x.sort().map(key => (key in a
      ? Math.round(a[key]
        .map(u => (u.progressCount))
        .reduce((first, second) => first + second) / a[key].length)
      : 0
    ));
    const data1Total = x.sort().map(key => (key in a
      ? Math.round(a[key]
        .map(u => (u.progressTotal))
        .reduce((first, second) => first + second) / a[key].length)
      : 0
    ));
    const data2 = x.sort().map(key => (key in b
      ? Math.round(b[key]
        .map(u => (u.progressCount))
        .reduce((first, second) => first + second) / b[key].length)
      : 0
    ));
    const data2Total = x.sort().map(key => (key in b
      ? Math.round(b[key]
        .map(u => (u.progressTotal))
        .reduce((first, second) => first + second) / b[key].length)
      : 0
    ));
    const data1TotalSub = data1.map((item, index) => (data1Total[index] - item));
    const data2TotalSub = data2.map((item, index) => (data2Total[index] - item));
    const dataFirst = {
      label: t('survey.label.your_results'),
      backgroundColor: 'rgb(228, 33, 44)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(228, 33, 44)',
      pointBorderColor: 'rgb(228, 33, 44)',
      pointHoverRadius: 4,
      data: data1,
      stack: `${filterType}1`,
    };
    const dataFirstTotal = {
      label: t('survey.label.your_results'),
      backgroundColor: 'rgb(167, 169, 172)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(228, 33, 44)',
      pointBorderColor: 'rgb(228, 33, 44)',
      pointHoverRadius: 4,
      data: data1TotalSub,
      stack: `${filterType}1`,
    };
    const dataSecond = {
      label: t('survey.label.benchmark'),
      backgroundColor: 'rgb(0, 0, 0)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(0, 0, 0)',
      pointBorderColor: 'rgb(0, 0, 0)',
      pointHoverRadius: 4,
      data: data2,
      stack: `${filterType}2`,
    };
    const dataSecondTotal = {
      label: t('survey.label.benchmark'),
      backgroundColor: 'rgb(167, 169, 172)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(0, 0, 0)',
      pointBorderColor: 'rgb(0, 0, 0)',
      pointHoverRadius: 4,
      data: data2TotalSub,
      stack: `${filterType}2`,
    };
    const speedData = {
      labels: x.sort(),
      datasets: [dataFirst, dataFirstTotal, dataSecond, dataSecondTotal],
    };
    const chartOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              maxTicksLimit: 100,
              min: 0,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (item, dataset) => {
            const datasetItem = dataset.datasets[item.datasetIndex];
            let resultTotal = datasetItem.data[item.index];

            if (item.datasetIndex === 1) {
              resultTotal += dataset.datasets[0].data[item.index];
            }
            if (item.datasetIndex === 3) {
              resultTotal += dataset.datasets[2].data[item.index];
            }

            return `${resultTotal}`;
          },
        },
      },
    };

    return (
      <Bar
        data={speedData}
        options={chartOptions}
        redraw
      />
    );
  }

  renderStatistic(user, compare) {
    const { filterType, chartType } = this.state;
    if (!filterType && chartType === 'participation') {
      return this.renderSpiderWeb(user, compare, 'progress');
    }

    if (!filterType) {
      return this.renderSpiderWeb(user, compare, 'score');
    }

    if (chartType === 'participation') {
      return this.renderParticipationBar(user, compare);
    }
    return this.renderScoreBar(user, compare);
  }

  render() {
    const {
      t,
      user,
      compare,
      legend,
      handleSubmit,
      isManager,
      userOptions,
      onCompareChange,
      isPartner,
    } = this.props;
    const {
      timeframe,
      filterType,
      chartType,
      selectedUser,
    } = this.state;
    const currentYear = moment().year();
    const currentQuarter = moment().quarter();

    const userStatistic = user
      .filter((u) => {
        if (filterType && !u.type.includes(filterType)) {
          return false;
        }
        if (timeframe && timeframe === 'this_quarter' && (u.quarter !== currentQuarter || u.year !== currentYear)) {
          return false;
        }
        if (timeframe && timeframe === 'this_year' && u.year !== currentYear) {
          return false;
        }
        return true;
      });

    const compareStatistic = compare
      .filter((c) => {
        if (filterType && !c.type.includes(filterType)) {
          return false;
        }
        if (timeframe && timeframe === 'this_quarter' && (c.quarter !== currentQuarter || c.year !== currentYear)) {
          return false;
        }
        if (timeframe && timeframe === 'this_year' && c.year !== currentYear) {
          return false;
        }
        return true;
      });

    const compareOptions = userOptions
    && (Number(selectedUser) < 1 || Number.isNaN(Number(selectedUser)))
      ? [
        {
          label: t('user.label.business_unit'),
          value: 'business-unit',
        },
      ]
      : [
        {
          label: t('user.label.business_unit'),
          value: 'business-unit',
        },
        {
          label: t('user.label.workforce_segment_similar'),
          value: 'workforce-segment',
        },
      ];

    return (
      <div className="compare-filters">
        <form
          noValidate
          onSubmit={handleSubmit(() => {})}
        >
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.type')}:`}
                </strong>
              </div>
              <Field
                name="type"
                showErrors={false}
                placeholder={t('survey.placeholder.any_type')}
                hasEmpty
                options={isManager
                  ? [
                    {
                      label: t('survey.type.upwards_feedback'),
                      value: 'upwards_feedback',
                    },
                    {
                      label: t('survey.type.customer_feedback'),
                      value: 'customer_feedback',
                    },
                    {
                      label: t('survey.type.peer_feedback'),
                      value: 'peer_feedback',
                    },
                    {
                      label: t('survey.type.project_feedback'),
                      value: 'project_feedback',
                    },
                    {
                      label: t('survey.type.freestyle_feedback'),
                      value: 'freestyle_feedback',
                    },
                  ]
                  : [
                    {
                      label: t('survey.type.customer_feedback'),
                      value: 'customer_feedback',
                    },
                    {
                      label: t('survey.type.peer_feedback'),
                      value: 'peer_feedback',
                    },
                    {
                      label: t('survey.type.project_feedback'),
                      value: 'project_feedback',
                    },
                    {
                      label: t('survey.type.freestyle_feedback'),
                      value: 'freestyle_feedback',
                    },
                  ]
                }
                component={SelectField}
                onValueChange={(value) => {
                  this.handleFilterChange(chartType, value);
                }}
              />
            </div>
            {userOptions && (
              <div className="col">
                <div className="compare-filter-title">
                  <strong>
                    {`${t('survey.label.feedback_receiver')}:`}
                  </strong>
                </div>
                <Field
                  name="user"
                  label={t('survey.label.user')}
                  showErrors={false}
                  placeholder={t('survey.placeholder.any_user')}
                  hasEmpty={!isPartner}
                  options={userOptions}
                  component={SelectField}
                  onValueChange={value => this.handleUserChange(value)}
                />
              </div>
            )}
          </div>
          <div>
            <div className="col">
              <div className="survey-statistic mt-3">
                {this.renderStatistic(userStatistic, compareStatistic)}
                <div className="legend">
                  <ul>
                    <li className="your-results-legend">
                      {legend.results}
                    </li>
                    <li className="benchmark-legend">
                      {legend.benchmark}
                    </li>
                    {legend && legend.total && (
                      <li className="total-legend">
                        {legend.total}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.compare')}:`}
                </strong>
              </div>
              <Field
                name="compare"
                showErrors={false}
                options={compareOptions}
                component={SelectField}
                onValueChange={(value) => { onCompareChange(value); }}
              />
            </div>
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.timeframe')}:`}
                </strong>
              </div>
              <Field
                name="timeframe"
                showErrors={false}
                placeholder={t('survey.placeholder.all_time')}
                hasEmpty
                options={[
                  {
                    label: t('survey.label.this_quarter'),
                    value: 'this_quarter',
                  },
                  {
                    label: t('survey.label.this_year'),
                    value: 'this_year',
                  },
                ]}
                component={SelectField}
                onValueChange={value => this.setState({ timeframe: value })}
              />
            </div>
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.results_participation')}:`}
                </strong>
              </div>
              <Field
                name="participation"
                showErrors={false}
                options={[
                  {
                    label: t('survey.label.results'),
                    value: 'progress_in_time',
                  },
                  {
                    label: t('survey.label.participation_rate'),
                    value: 'participation',
                  },
                ]}
                component={SelectField}
                onValueChange={(value) => {
                  this.handleFilterChange(value, filterType);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withNamespaces()(reduxForm({
  form: 'surveyStatisticFilters',
})(SurveyStatistic));

SurveyStatistic.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  compare: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legend: PropTypes.shape({
    results: PropTypes.string,
    benchmark: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChartChange: PropTypes.func.isRequired,
  onCompareChange: PropTypes.func.isRequired,
  onUserChange: PropTypes.func,
  isManager: PropTypes.bool,
  isPartner: PropTypes.bool,
  userOptions: PropTypes.arrayOf(PropTypes.shape({})),
  initialize: PropTypes.func.isRequired,
};

SurveyStatistic.defaultProps = {
  isManager: true,
  isPartner: false,
  userOptions: null,
  onUserChange: null,
};
