const FILTER_ADMIN_SURVEY_TYPE_SELECTED = '@@filter-admin-survey/filter-type-selected';
const FILTER_ADMIN_BUSINESS_UNIT_SELECTED = '@@filter-admin-survey/filter-unit-selected';
const FILTER_ADMIN_WORKFORCE_SEGMENT_SELECTED = '@@filter-admin-survey/filter-segment-selected';
const FILTER_ADMIN_STATUS_SELECTED = '@@filter-admin-survey/filter-status-selected';
const FILTER_ADMIN_YEAR_SELECTED = '@@filter-admin-survey/filter-year-selected';

export {
  FILTER_ADMIN_SURVEY_TYPE_SELECTED,
  FILTER_ADMIN_BUSINESS_UNIT_SELECTED,
  FILTER_ADMIN_WORKFORCE_SEGMENT_SELECTED,
  FILTER_ADMIN_STATUS_SELECTED,
  FILTER_ADMIN_YEAR_SELECTED,
};
