import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const ExcludedPeers = ({
  t,
  removedProviders,
}) => {
  if (!removedProviders.length) {
    return <div />;
  }

  return (
    <div className="report-excluded-peers report-box card">
      <div className="card-header">
        <div className="report-box-title">
          {t('survey.label.excluded_peers')}
        </div>
      </div>
      <hr />
      <div className="comment-box">
        {removedProviders.join(', ')}
      </div>
    </div>
  );
};

export default withNamespaces()(ExcludedPeers);

ExcludedPeers.propTypes = {
  t: PropTypes.func.isRequired,
  removedProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
};
