import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReportList from '../../components/ReportList';
import { fetchTeamSurveyReports } from '../../redux-modules/survey-report/actions';

class SurveyReportTeamList extends Component {
  componentDidMount() {
    const {
      token,
      fetchTeamSurveyReports: fetchTeamSurveyReportsAction,
    } = this.props;

    fetchTeamSurveyReportsAction(token);
  }

  render() {
    const { teamSurveyReports, filterUserSurvey } = this.props;

    if (!teamSurveyReports) {
      return <div />;
    }

    return (
      <div className="survey-report-team-list">
        <ReportList
          data={teamSurveyReports}
          filter={filterUserSurvey}
          team
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  teamSurveyReports,
  filterUserSurvey,
}) => ({ token, teamSurveyReports, filterUserSurvey });

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTeamSurveyReports,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyReportTeamList);

SurveyReportTeamList.propTypes = {
  token: PropTypes.string.isRequired,
  teamSurveyReports: PropTypes.shape({}),
  filterUserSurvey: PropTypes.shape({}),
  fetchTeamSurveyReports: PropTypes.func.isRequired,
};

SurveyReportTeamList.defaultProps = {
  teamSurveyReports: null,
  filterUserSurvey: null,
};
