import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const CantProvideComments = ({
  t,
  comments,
}) => {
  const listOfComments = (comments || []).map((c) => {
    const { comment } = c;

    return (
      <div
        className="comment-box"
        key={c.comment}
      >
        {comment}
      </div>
    );
  });

  if (!comments.length) {
    return <div />;
  }

  return (
    <div className="report-cant-provide-comments report-box card">
      <div className="card-header">
        <div className="report-box-title">
          {t('survey.label.cant_provide_comments')}
        </div>
      </div>
      <hr />
      <div className="comment-section">
        <div className="comment-listing">
          {listOfComments}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(CantProvideComments);

CantProvideComments.propTypes = {
  t: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
