import _ from 'lodash';

import { SURVEYS_ADMIN_FETCHED, SURVEY_ADMIN_FETCHED } from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

const surveysAdmin = (state = initialState, action) => {
  switch (action.type) {
    case SURVEYS_ADMIN_FETCHED: {
      const data = _.get(action.payload, 'data.surveyGroups', null);

      return data ? _.keyBy(data, 'id') : state;
    }
    case SURVEY_ADMIN_FETCHED: {
      const survey = _.get(action.payload, 'data', null);

      if (!survey) {
        return state;
      }

      const newSurvey = {};
      newSurvey[survey.id] = survey;

      return { ...state, ...newSurvey };
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default surveysAdmin;
