import _ from 'lodash';

import { FEEDBACK_FETCHED, FEEDBACK_LIST_FETCHED } from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK_FETCHED: {
      return _.get(action.payload, 'data', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const feedbackList = (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK_LIST_FETCHED: {
      const data = _.get(action.payload, 'data.feedback', null);

      return data ? _.keyBy(data, 'id') : state;
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { feedback, feedbackList };
