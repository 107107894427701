import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import ChangeLanguageForm from '../ChangeLanguageForm';
import subareas from '../../utils/subareas';

const UserProfile = ({ t, userProfile }) => (
  <div className="user-profile">
    <div className="settings-group">
      <div className="settings-lable">
        {t('user.label.username')}
        :
      </div>
      <div className="settings-data">
        {userProfile.name}
      </div>
    </div>
    <div className="settings-group">
      <div className="settings-lable">
        {t('user.label.email')}
        :
      </div>
      <div className="settings-data">
        {userProfile.email}
      </div>
    </div>
    <div className="settings-group">
      <div className="settings-lable">
        {t('user.label.business_unit')}
        :
      </div>
      <div className="settings-data">
        {userProfile.businessUnit ? userProfile.businessUnit : '-'}
      </div>
    </div>
    <div className="settings-group">
      <div className="settings-lable">
        {t('user.label.function')}
        :
      </div>
      <div className="settings-data">
        {userProfile.function ? subareas.getValue(userProfile.function) : '-'}
      </div>
    </div>
    <div className="settings-group">
      <div className="settings-lable">
        {t('user.label.workforce_segment')}
        :
      </div>
      <div className="settings-data">
        {userProfile.workforceSegment ? userProfile.workforceSegment : '-'}
      </div>
    </div>
    <div className="settings-group">
      <div className="settings-lable">
        {t('user.label.country')}
        :
      </div>
      <div className="settings-data">
        {userProfile.country ? userProfile.country : '-'}
      </div>
    </div>
    <div className="settings-group">
      <div className="settings-lable">
        {t('user.label.language')}
        :
      </div>
      <div className="settings-data">
        <ChangeLanguageForm />
      </div>
    </div>
  </div>
);

const mapStateToProps = ({ userProfile }) => ({ userProfile });

export default withNamespaces()(connect(mapStateToProps, null)(UserProfile));

UserProfile.propTypes = {
  t: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string,
  }).isRequired,
};
