const SURVEYS_ADMIN_FETCHED = '@@survey-admin/surveys-admin-fetched';
const SURVEY_ADMIN_FETCHED = '@@survey-admin/survey-admin-fetched';
const SURVEYS_ADMIN_UPDATED = '@@survey-admin/surveys-admin-updated';
const SURVEYS_ADMIN_REMOVED = '@@survey-admin/surveys-admin-removed';

export {
  SURVEYS_ADMIN_FETCHED,
  SURVEY_ADMIN_FETCHED,
  SURVEYS_ADMIN_UPDATED,
  SURVEYS_ADMIN_REMOVED,
};
