import React from 'react';
import PropTypes from 'prop-types';

const CheckboxField = ({
  meta: { touched, error, submitting },
  input,
  input: { name },
  label,
  showLabel,
  showErrors,
  children,
}) => (
  <div className="checkbox-field-component form-check">
    <input
      className="form-check-input"
      {...input}
      id={name}
      disabled={submitting}
      type="checkbox"
    />
    <label
      className={`form-check-label ${showLabel ? 'visible' : ''}`}
      htmlFor={name}
    >
      {label}
    </label>
    <span>{children}</span>
    <small className="input-field__error">
      {touched && error && showErrors ? error : '' }
    </small>
  </div>
);

export default CheckboxField;

CheckboxField.propTypes = {
  meta: PropTypes.shape({
    submitting: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({ name: PropTypes.string }).isRequired,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
};

CheckboxField.defaultProps = {
  label: '',
  showLabel: false,
  showErrors: true,
};
