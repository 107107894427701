import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import SubmitField from '../../../components/SubmitField';
import TransferHistoryReportList from '../../components/TransferHistoryReportList';
import { transferHistoryExecute } from '../../../redux-modules/transfer-history/actions';

class AdminTransferHistoryExecuteForm extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit() {
    const {
      transferHistory,
      transferHistoryExecute: transferHistoryExecuteAction,
    } = this.props;

    if (!transferHistory) {
      return null;
    }

    const deletedUsers = transferHistory.deletedUsers.users.map(user => user.id);
    const newUser = transferHistory.newUser.id;

    return transferHistoryExecuteAction(deletedUsers, newUser);
  }

  render() {
    const { t, transferHistory, handleSubmit } = this.props;

    if (!transferHistory) {
      return null;
    }

    return (
      <form
        noValidate
        onSubmit={handleSubmit(this.handleFormSubmit)}
      >
        <div className="row">
          <div className="col" style={{ overflow: 'hidden' }}>
            {transferHistory.deletedUsers.users.map(user => (
              <div key={user.id} style={{ marginBottom: '15px' }}>
                <div>{user.name}</div>
                <div>{user.email}</div>
                <div>{user.user3rdPartyId}</div>
                <div>{user.businessUnit}</div>
              </div>
            ))}
          </div>
          <div className="col" style={{ marginLeft: '30px' }}>
            <div>{transferHistory.newUser.name}</div>
            <div>{transferHistory.newUser.email}</div>
            <div>{transferHistory.newUser.user3rdPartyId}</div>
            <div>{transferHistory.newUser.businessUnit}</div>
          </div>
          <div className="col" />
        </div>
        <hr />
        {transferHistory.surveyReports.totalItems > 0
          ? (
            <div>
              <div className="row">
                <div className="col">
                  <TransferHistoryReportList data={transferHistory.surveyReports.surveys} />
                </div>
              </div>
              <div className="row pt-4">
                <div className="col">
                  <Field
                    inline
                    name="submit"
                    label={t('transfer_history.action.execute')}
                    component={SubmitField}
                  />
                </div>
              </div>
            </div>
          )
          : (
            <div className="row">
              <div className="col-12">
                <strong>{t('transfer_history.error.no_reports')}</strong>
              </div>
            </div>
          )
        }
      </form>
    );
  }
}

function mapStateToProps(state) {
  const initialValues = {};

  return {
    initialValues,
    transferHistory: state.transferHistory,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({ transferHistoryExecute }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'adminTransferHistoryExecuteForm',
  destroyOnUnmount: true,
})(AdminTransferHistoryExecuteForm)));

AdminTransferHistoryExecuteForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  transferHistoryExecute: PropTypes.func.isRequired,
  transferHistory: PropTypes.shape({}),
};

AdminTransferHistoryExecuteForm.defaultProps = {
  transferHistory: null,
};
