import axios from 'axios';

import {
  USER_ACTIVATED,
  CONFIRMATION_MAIL_SENT,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const activateUser = (id) => {
  const url = `${REST_API_URL}v1/user-confirm`;
  const request = axios.put(
    url,
    { id },
  );

  return {
    type: USER_ACTIVATED,
    payload: request,
  };
};

const sendConfirmationEmail = (id) => {
  const url = `${REST_API_URL}v1/user-confirm`;
  const request = axios.post(
    url,
    { id },
  );

  return {
    type: CONFIRMATION_MAIL_SENT,
    payload: request,
  };
};

export {
  activateUser,
  sendConfirmationEmail,
};
