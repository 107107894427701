import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErrorHandler from '../ErrorHandler';
import HomePage from '../../pages/HomePage';
import SignInCheckPage from '../../pages/SignInCheckPage';
import SignInPage from '../../pages/SignInPage';
import SignInForcePage from '../../pages/SignInForcePage';
import SignUpPage from '../../pages/SignUpPage';
import LandingPage from '../../pages/LandingPage';
import ActivatePage from '../../pages/ActivatePage';
import ConfirmationPage from '../../pages/ConfirmationPage';
import ResetPasswordRequestPage from '../../pages/ResetPasswordRequestPage';
import ResetPasswordConfirmPage from '../../pages/ResetPasswordConfirmPage';
import SignOutPage from '../../pages/SignOutPage';
import WelcomePage from '../../pages/WelcomePage';
import AddSurveyPage from '../../pages/AddSurveyPage';
import UpdateSurveyPage from '../../pages/UpdateSurveyPage';
import CreateSelfAssessmentPage from '../../pages/CreateSelfAssessmentPage';
import SurveysPage from '../../pages/SurveysPage';
import SurveysTeamPage from '../../pages/SurveysTeamPage';
import ReportSurveyDetailsPage from '../../pages/ReportSurveyDetailsPage';
import ReportSurveyPreviewPage from '../../pages/ReportSurveyPreviewPage';
import ManualReminderPage from '../../pages/ManualReminderPage';
import StatisticTeamPage from '../../pages/StatisticTeamPage';
import StatisticPage from '../../pages/StatisticPage';
import FeedbackListPage from '../../pages/FeedbackListPage';
import ApprovalPage from '../../pages/ApprovalPage';
import FeedbackProvidePage from '../../pages/FeedbackProvidePage';
import FeedbackProvideExternalPage from '../../pages/FeedbackProvideExternalPage';
import FeedbackCreatePage from '../../pages/FeedbackCreatePage';
import FeedbackPreviewPage from '../../pages/FeedbackPreviewPage';
import ProfilePage from '../../pages/ProfilePage';
import Notification from '../Notification';
import LanguageHandler from '../LanguageHandler';

const App = ({ token, language }) => (
  <div className="app">
    <ErrorHandler />
    <Notification />
    <LanguageHandler />
    <BrowserRouter>
      <div>
        <Switch>
          <Route
            exact
            path="/login-check/:token"
            component={SignInCheckPage}
          />
          <Route
            exact
            path="/sign-in"
            component={SignInPage}
          />
          <Route
            exact
            path="/sign-in-force"
            component={SignInForcePage}
          />
          <Route
            exact
            path="/sign-up"
            component={SignUpPage}
          />
          <Route
            exact
            path="/sign-up-confirmation"
            component={ConfirmationPage}
          />
          <Route
            exact
            path="/activate/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
            component={ActivatePage}
          />
          <Route
            exact
            path="/reset-password-request"
            component={ResetPasswordRequestPage}
          />
          <Route
            exact
            path="/reset-password-request/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
            component={ResetPasswordConfirmPage}
          />
          <Route
            exact
            path="/feedback-external/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
            component={FeedbackProvideExternalPage}
          />
          <Route
            exact
            path="/welcome"
            component={WelcomePage}
          />
          <HomePage>
            <Route component={() => (
              <Switch>
                { token ? '' : <Redirect to="/sign-in" /> }
                { token && language ? '' : <Redirect to="/welcome" /> }
                <Route
                  exact
                  path="/"
                  component={LandingPage}
                />
                <Route
                  exact
                  path="/add-survey/:type(freestyle_feedback|project_feedback|pod_feedback)"
                  component={AddSurveyPage}
                />
                <Route
                  exact
                  path="/survey/:id([0-9]+)"
                  component={UpdateSurveyPage}
                />
                <Route
                  exact
                  path="/survey/:id([0-9]+)/self-assessment"
                  component={CreateSelfAssessmentPage}
                />
                <Route
                  exact
                  path="/survey/:id([0-9]+)/reminder"
                  component={ManualReminderPage}
                />
                <Route
                  exact
                  path="/survey"
                  component={SurveysPage}
                />
                <Route
                  exact
                  path="/survey/team"
                  component={SurveysTeamPage}
                />
                <Route
                  exact
                  path="/reports/:id([0-9]+)"
                  component={ReportSurveyDetailsPage}
                />
                <Route
                  exact
                  path="/reports/preview/:id([0-9]+)"
                  component={ReportSurveyPreviewPage}
                />
                <Route
                  exact
                  path="/statistic"
                  component={StatisticPage}
                />
                <Route
                  exact
                  path="/statistic/team"
                  component={StatisticTeamPage}
                />
                <Route
                  exact
                  path="/feedback"
                  component={FeedbackListPage}
                />
                <Route
                  exact
                  path="/approvals"
                  component={ApprovalPage}
                />
                <Route
                  exact
                  path="/feedback/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
                  component={FeedbackProvidePage}
                />
                <Route
                  exact
                  path="/feedback/preview/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
                  component={FeedbackPreviewPage}
                />
                <Route
                  exact
                  path="/provide-feedback"
                  component={FeedbackCreatePage}
                />
                <Route
                  exact
                  path="/profile"
                  component={ProfilePage}
                />
                <Route
                  exact
                  path="/sign-out"
                  component={SignOutPage}
                />
                <Redirect to="/" />
              </Switch>
            )}
            />
          </HomePage>
        </Switch>
      </div>
    </BrowserRouter>
  </div>
);


const selectUserProfileLanguage = state => (state.userProfile ? state.userProfile.language : '');

const mapStateToProps = state => ({
  token: state.token,
  language: selectUserProfileLanguage(state),
});

export default connect(mapStateToProps, null)(App);

App.propTypes = {
  token: PropTypes.string,
  language: PropTypes.string,
};

App.defaultProps = {
  token: null,
  language: '',
};
