/* global document */
import React, { Component } from 'react';

import cchLogo from '../../assets/images/cch-logo.svg';

class Footer extends Component {
  componentDidMount() {
    document.addEventListener('touchstart', () => {}, false);
  }

  render() {
    return (
      <footer id="footer">
        <div className="footer-image">
          <img
            src={cchLogo}
            alt="logo"
          />
        </div>
      </footer>
    );
  }
}

export default Footer;
