import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import SubmitField from '../../components/SubmitField';
import TextAreaField from '../../components/TextAreaField';
import { sendReminder, fetchSurvey } from '../../redux-modules/survey/actions';
import validators from '../../utils/validators';

import './manual-reminder-form.css';

class ManualReminderForm extends Component {
  componentDidMount() {
    const { fetchSurvey: fetchSurveyAction, token, id } = this.props;
    fetchSurveyAction(token, id);
  }

  handleSubmit(values) {
    const {
      token,
      id,
      sendReminder: sendReminderAction,
      onSend,
    } = this.props;

    return sendReminderAction(token, id, values.message).then(
      onSend || (() => {}),
      () => {},
    );
  }

  render() {
    const {
      t,
      handleSubmit,
      onCancel,
      userProfile,
      surveys,
      id,
    } = this.props;

    const survey = _.get(surveys, id, null);
    if (!survey) {
      return <div />;
    }

    return (
      <div className="manual-reminder-form">
        <div className="content-title">
          <div className="col my-auto">
            <span className="box-title">
              {survey.year}
              {' '}
              Q
              {survey.quarter}
              {' '}
              {survey.title ? survey.title : ''}
              {' '}
              {t(`survey.type.${survey.type}`)}
              {' '}
              {t('survey.label.for')}
              {' '}
              {survey.fullName}
            </span>
            <div className="information">
              <span className="box-information">
                <span className="box-information">
                  <strong>
                    {t('survey.date.due')}
                    :
                    {' '}
                  </strong>
                  {' '}
                  {moment(survey.endDate).format(t('date_format'))}
                </span>
              </span>
            </div>
          </div>
        </div>
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="content-box accordion">
            <p>
              {t('survey.label.hi')}
              {' '}
              {userProfile.name}
              ,
            </p>
            <p>
              {t('survey.label.reminder_text')}
            </p>
            <Field
              name="message"
              label={t('survey.label.message')}
              component={TextAreaField}
            />
          </div>
          <div className="buttons-group">
            <div className="buttons-group-wrapper">
              <button
                className="btn standard gray"
                type="button"
                onClick={onCancel}
              >
                {t('survey.action.back')}
              </button>
              <Field
                inline
                name="submit"
                label={t('survey.action.send_reminder')}
                component={SubmitField}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.message)) {
    errors.message = t('survey.error.reminder_required');
  }

  return errors;
};

const mapStateToProps = ({ token, userProfile, surveys }) => ({ token, userProfile, surveys });

const mapDispatchToProps = dispatch => bindActionCreators({ sendReminder, fetchSurvey }, dispatch);

export default withNamespaces()(reduxForm({
  form: 'manualReminderForm',
  validate,
})(connect(mapStateToProps, mapDispatchToProps)(ManualReminderForm)));

ManualReminderForm.propTypes = {
  token: PropTypes.string,
  sendReminder: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  userProfile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  fetchSurvey: PropTypes.func.isRequired,
  surveys: PropTypes.shape({}),
};

ManualReminderForm.defaultProps = {
  token: null,
  surveys: null,
};
