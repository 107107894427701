import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import NotificationItem from '../../components/NotificationItem';
import fetchCallToAction from '../../redux-modules/call-to-action/actions';

class ApprovalList extends Component {
  componentDidMount() {
    const { token, fetchCallToAction: fetchCallToActionAction } = this.props;
    fetchCallToActionAction(token);
  }

  render() {
    const { callToActions } = this.props;

    const data = (callToActions || [])
      .filter(notification => notification.type === 'customer_list_created' || notification.type === 'peer_list_created')
      .sort((a, b) => {
        const endDateA = moment(a.endDate);
        const endDateB = moment(b.endDate);
        if (endDateA.isBefore(endDateB)) {
          return -1;
        }
        if (endDateA.isAfter(endDateB)) {
          return 1;
        }
        return 0;
      })
      .map(notification => (
        <div
          className="sub-accordion-item"
          key={notification.url}
        >
          <NotificationItem
            url={notification.url}
            quarter={notification.quarter}
            year={notification.year}
            title={notification.surveyTitle}
            type={notification.surveyType}
            notificationType={notification.type}
            name={notification.fullName}
            endDate={moment(notification.endDate)}
          />
        </div>
      ));

    return (
      <div className="notification-list">
        {data}
      </div>
    );
  }
}

const mapStateToProps = ({ token, callToActions }) => ({ token, callToActions });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCallToAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalList);

ApprovalList.propTypes = {
  token: PropTypes.string.isRequired,
  callToActions: PropTypes.arrayOf(PropTypes.shape({})),
  fetchCallToAction: PropTypes.func.isRequired,
};

ApprovalList.defaultProps = {
  callToActions: null,
};
