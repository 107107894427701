import { SURVEY_TYPE_SELECTED, SURVEY_TIMEFRAME_SELECTED, SURVEY_USER_SELECTED } from './types';

const selectSurveyType = type => ({
  type: SURVEY_TYPE_SELECTED,
  payload: type,
});

const selectSurveyTimeframe = timeframe => ({
  type: SURVEY_TIMEFRAME_SELECTED,
  payload: timeframe,
});

const selectSurveyUser = user => ({
  type: SURVEY_USER_SELECTED,
  payload: user,
});

export {
  selectSurveyType,
  selectSurveyTimeframe,
  selectSurveyUser,
};
