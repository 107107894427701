import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import InputField from '../../../components/InputField';
import SubmitField from '../../../components/SubmitField';
import { transferHistoryCheck } from '../../../redux-modules/transfer-history/actions';
import validators from '../../../utils/validators';

class AdminTransferHistoryCheckForm extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { transferHistoryCheck: transferHistoryCheckAction } = this.props;

    return transferHistoryCheckAction(values.from, values.to);
  }

  render() {
    const { t, handleSubmit } = this.props;

    return (
      <div className="admin-filters">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleFormSubmit)}
        >
          <div className="row">
            <div className="col">
              <div className="form-group">
                <Field
                  name="from"
                  placeholder={t('transfer_history.label.old_user')}
                  label={t('transfer_history.label.old_user')}
                  component={InputField}
                />
              </div>
            </div>
            <div
              className="col"
              style={{
                maxWidth: '30px',
                fontSize: '28px',
                padding: 0,
                textAlign: 'center',
              }}
            >
              <div className="form-group" style={{ fontSize: '28px' }}>
                →
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <Field
                  name="to"
                  placeholder={t('transfer_history.label.new_user')}
                  label={t('transfer_history.label.new_user')}
                  component={InputField}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group text-right" style={{ marginTop: '24px' }}>
                <Field
                  name="submit"
                  label={t('transfer_history.action.check')}
                  component={SubmitField}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.from)) {
    errors.from = t('transfer_history.error.old_user_required');
  }

  if (!validators.required(values.to)) {
    errors.to = t('transfer_history.error.new_user_required');
  }

  return errors;
};

function mapStateToProps() {
  const initialValues = {
    from: '',
    to: '',
  };

  return {
    initialValues,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({ transferHistoryCheck }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  validate,
  form: 'adminTransferHistoryCheckForm',
  destroyOnUnmount: true,
})(AdminTransferHistoryCheckForm)));

AdminTransferHistoryCheckForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  transferHistoryCheck: PropTypes.func.isRequired,
};
