import _ from 'lodash';
import { TRANSFER_HISTORY_CHECKED, TRANSFER_HISTORY_EXECUTED } from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

const transferHistory = (state = initialState, action) => {
  switch (action.type) {
    case TRANSFER_HISTORY_CHECKED: {
      return _.get(action.payload, 'data', initialState);
    }
    case TRANSFER_HISTORY_EXECUTED: {
      return initialState;
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default transferHistory;
