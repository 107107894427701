import React from 'react';
import PropTypes from 'prop-types';

import AdminTopBar from '../../components/AdminTopBar';
import Footer from '../../../components/Footer';

const AdminLandingPage = ({ children }) => (
  <div className="admin-landing-page">
    <AdminTopBar />
    {children}
    <Footer />
  </div>
);

export default AdminLandingPage;

AdminLandingPage.propTypes = {
  children: PropTypes.element.isRequired,
};
