import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import UserProfile from '../../containers/UserProfile';

const ProfilePage = ({ t }) => (
  <main className="profile-page settings">
    <div className="container">
      <div className="content-title my-auto">
        <span>
          {t('menu.label.settings')}
        </span>
      </div>
      <div className="content-box accordion">
        <UserProfile />
      </div>
      <div className="buttons-group">
        <Link
          className="btn standard"
          to="/sign-out"
        >
          {t('user.action.logout')}
        </Link>
      </div>
    </div>
  </main>
);

export default withNamespaces()(ProfilePage);

ProfilePage.propTypes = {
  t: PropTypes.func.isRequired,
};
