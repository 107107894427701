const SURVEY_REPORT_FETCHED = '@@survey-report/survey-report-fetched';
const SURVEY_REPORTS_FETCHED = '@@survey-report/survey-reports-fetched';
const SURVEY_REPORTS_TEAM_FETCHED = '@@survey-report/survey-reports-team-fetched';
const SURVEY_REPORT_PREVIEW_FETCHED = '@@survey-report/survey-report-preview-fetched';

export {
  SURVEY_REPORT_FETCHED,
  SURVEY_REPORTS_FETCHED,
  SURVEY_REPORTS_TEAM_FETCHED,
  SURVEY_REPORT_PREVIEW_FETCHED,
};
