import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import _ from 'lodash';

import { logout, logoutWebapp, refreshToken } from '../../redux-modules/auth/actions';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = { refreshing: false };

    axios.interceptors.request.use(this.handleRequest.bind(this));
    axios.interceptors.response.use(null, this.handleResponse.bind(this));
    axios.defaults.withCredentials = true;
  }

  handleRequest(request) {
    const { jwtToken } = this.props;

    if (!jwtToken) {
      return request;
    }

    if (request.url.includes('/v1/user-confirm')
      || request.url.includes('/v1/login-check')
      || request.url.includes('/saml/login')
      || request.url.includes('/v1/token/refresh')
      || request.url.includes('/v1/external-customer')
      || request.url.includes('/v1/feedback-external')
      || request.url.includes('/v1/reset-password')
    ) {
      return request;
    }

    const isExpired = parseInt(jwtToken, 10) < ((new Date().getTime() / 1000) + 30);

    if (isExpired) {
      return this.handleRefreshToken(jwtToken).then(() => request);
    }

    return request;
  }

  handleResponse(error) {
    const code = _.get(error, 'response.data.code', 200);
    const message = _.get(error, 'response.data.message', '');

    if (code === 401 && message === 'JWT Token not found') {
      return this.handleTokenExpired();
    }

    return Promise.reject(error);
  }

  handleRefreshToken(oldToken) {
    const { refreshToken: refreshTokenAction } = this.props;
    const { refreshing } = this.state;
    return new Promise((resolve) => {
      if (refreshing) {
        this.watchNewToken(oldToken, resolve);
        return;
      }

      this.setState({ refreshing: true });
      refreshTokenAction().then(
        () => {
          this.setState({ refreshing: false });
          this.watchNewToken(oldToken, resolve);
        },
        () => {
          this.setState({ refreshing: false });
          this.handleRefreshTokenExpired();
        },
      );
    });
  }

  handleTokenExpired() {
    const { logout: logoutAction } = this.props;
    logoutAction();

    return Promise.resolve();
  }

  handleRefreshTokenExpired() {
    const { logoutWebapp: logoutAction } = this.props;
    logoutAction();

    return Promise.resolve();
  }

  watchNewToken(oldToken, resolve) {
    const { jwtToken } = this.props;
    if (oldToken !== jwtToken) {
      resolve(jwtToken);
    } else {
      setTimeout(() => this.watchNewToken(oldToken, resolve), 1000);
    }
  }

  render() {
    return (
      <div className="error-handler" />
    );
  }
}

const mapStateToProps = ({ token }) => ({
  jwtToken: token,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  logoutWebapp,
  refreshToken,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);

ErrorHandler.propTypes = {
  logout: PropTypes.func.isRequired,
  logoutWebapp: PropTypes.func.isRequired,
  refreshToken: PropTypes.func.isRequired,
  jwtToken: PropTypes.string,
};

ErrorHandler.defaultProps = {
  jwtToken: null,
};
