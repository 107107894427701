import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import SurveyItem from '../SurveyItem';

const SurveyList = ({
  data,
  filter: { type, timeframe, user },
  team,
  closed,
}) => {
  const currentYear = moment().year();
  const currentQuarter = moment().quarter();
  const currentMonth = moment().month();

  const surveys = _.values(data)
    .filter((survey) => {
      const endDate = moment(survey.endDate);

      if (timeframe && timeframe === 'this_month' && (endDate.month() !== currentMonth || endDate.year() !== currentYear)) {
        return false;
      }
      if (timeframe && timeframe === 'this_quarter' && (endDate.quarter() !== currentQuarter || endDate.year() !== currentYear)) {
        return false;
      }
      if (timeframe && timeframe === 'this_year' && endDate.year() !== currentYear) {
        return false;
      }
      if (type && survey.type !== type) {
        return false;
      }
      if (team && user && survey.user !== user) {
        return false;
      }
      if (!team && !closed && !survey.selfAssessmentCompleted && survey.type === 'upwards_feedback') {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      const endDateA = moment(a.endDate);
      const endDateB = moment(b.endDate);
      if (endDateA.isBefore(endDateB)) {
        return closed ? 1 : -1;
      }
      if (endDateA.isAfter(endDateB)) {
        return closed ? -1 : 1;
      }
      return 0;
    })
    .map((survey) => {
      const endDate = moment(survey.endDate);

      return (
        <div
          className="sub-accordion-item"
          key={survey.id}
        >
          <SurveyItem
            id={survey.id}
            quarter={survey.quarter}
            year={survey.year}
            progress={{
              total: survey.progressTotal,
              count: survey.progressCount,
            }}
            title={survey.title}
            type={survey.type}
            name={survey.fullName}
            endDate={endDate}
            team={team}
            closed={closed}
          />
        </div>
      );
    });

  return (
    <div className="survey-list">
      {surveys}
    </div>
  );
};

export default SurveyList;

SurveyList.propTypes = {
  data: PropTypes.shape({}),
  filter: PropTypes.shape({
    type: PropTypes.string,
    timeframe: PropTypes.string,
    user: PropTypes.number,
  }),
  team: PropTypes.bool,
  closed: PropTypes.bool,
};

SurveyList.defaultProps = {
  data: null,
  filter: {
    type: '',
    timeframe: '',
    user: null,
  },
  team: false,
  closed: false,
};
