import React from 'react';

import SignOutForm from '../../containers/SignOutForm';

const SignOutPage = () => (
  <div className="sign-out-page">
    <SignOutForm />
  </div>
);
export default SignOutPage;
