import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import './top-bar.css';

import logo from '../../assets/images/logo.svg';
import cchLogo from '../../assets/images/cch-logo.svg';
import receiveIcon from '../../assets/images/rec-icon.svg';
import giveIcon from '../../assets/images/giv-icon.svg';
import teamIcon from '../../assets/images/team-icon.svg';
import toDoIcon from '../../assets/images/todo-icon.svg';
import support from '../../assets/images/support.svg';

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = { menuOpen: false, dialogOpen: false };

    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  handleMenuOpen() {
    this.setState({ menuOpen: true });
  }

  handleMenuClose() {
    this.setState({ menuOpen: false });
  }

  handleDialogOpen() {
    this.setState({ dialogOpen: true });
  }

  handleDialogClose() {
    this.setState({ dialogOpen: false });
  }

  render() {
    const { t, userProfile } = this.props;
    const { menuOpen, dialogOpen } = this.state;

    const isManager = userProfile && userProfile.isManager;
    const isScrumMaster = userProfile && userProfile.isScrumMaster;
    const isPartner = userProfile && userProfile.partnership;

    const displayTeam = isManager || isScrumMaster || isPartner;
    const displayTeamApprovals = isManager;
    const displayTeamResults = isManager || isScrumMaster;
    const displayTeamStatistic = isManager || isPartner;

    return (
      <nav className="top-bar navbar navbar-expand-lg navbar-gray static-top">
        <div className="mobile-header">
          <div className="mobile-header-logo">
            <NavLink to="/">
              <img
                src={cchLogo}
                alt="logo"
              />
            </NavLink>
          </div>
          <span
            className="mobile-menu-button"
            role="button"
            tabIndex={0}
            onClick={this.handleMenuOpen}
            onKeyPress={this.handleMenuOpen}
          >
            {t('menu.label.menu')}
            <i className="fas fa-bars" />
          </span>
        </div>
        <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
          <ul className="main-menu-mobile-menu">
            <span
              className="close-menu"
              role="button"
              tabIndex={0}
              onClick={this.handleMenuClose}
              onKeyPress={this.handleMenuClose}
            >
              {t('menu.label.close')}
              <i className="fas fa-times" />
            </span>
            <li className="nav-item">
              <NavLink
                to="/"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.home')}
              </NavLink>
            </li>
            <li className="nav-item reciving">
              <NavLink
                to="/#collapseOne"
                onClick={this.handleMenuClose}
              >
                <img
                  src={receiveIcon}
                  alt="receiving-feedback"
                />
                {t('menu.label.receiving_feedback')}
              </NavLink>
            </li>
            <li className="nav-item sub-item">
              <NavLink
                to="/survey"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.feedback_to_me')}
              </NavLink>
            </li>
            <li className="nav-item sub-item">
              <NavLink
                to="/statistic"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.compare_with_others')}
              </NavLink>
            </li>
            <li className="nav-item sub-item">
              <NavLink
                to="#request-feedback"
                onClick={() => {
                  this.handleDialogOpen();
                  this.handleMenuClose();
                }}
              >
                {t('menu.label.request_feedback')}
              </NavLink>
            </li>
            <li className="nav-item giving">
              <NavLink
                to="/#collapseTwo"
                onClick={this.handleMenuClose}
              >
                <img
                  src={giveIcon}
                  alt="giving-feedback"
                />
                {t('menu.label.giving_feedback')}
              </NavLink>
            </li>
            <li className="nav-item sub-item">
              <NavLink
                to="/feedback"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.feedback_to_others')}
              </NavLink>
            </li>
            <li className="nav-item sub-item">
              <NavLink
                to="/provide-feedback"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.provide_feedback_proactively')}
              </NavLink>
            </li>
            <li className={`nav-item team ${displayTeam ? '' : 'd-none'}`}>
              <NavLink
                to="/#collapseThree"
                onClick={this.handleMenuClose}
              >
                <img
                  src={teamIcon}
                  alt="team"
                />
                {t('menu.label.team_feedback')}
              </NavLink>
            </li>
            <li className={`nav-item sub-item ${displayTeamApprovals ? '' : 'd-none'}`}>
              <NavLink
                to="/approvals"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.approvals')}
              </NavLink>
            </li>
            <li className={`nav-item sub-item ${displayTeamResults ? '' : 'd-none'}`}>
              <NavLink
                to="/survey/team"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.team_members_feedback')}
              </NavLink>
            </li>
            <li className={`nav-item sub-item ${displayTeamStatistic ? '' : 'd-none'}`}>
              <NavLink
                to="/statistic/team"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.result_for_my_organization')}
              </NavLink>
            </li>
            <li className="nav-item todo">
              <NavLink
                to="/#collapseFour"
                onClick={this.handleMenuClose}
              >
                <img
                  src={toDoIcon}
                  alt="todo"
                />
                {t('menu.label.my_todo')}
              </NavLink>
            </li>
            <li className="nav-item support">
              <NavLink
                to="/#collapseFive"
                onClick={this.handleMenuClose}
              >
                <img
                  src={support}
                  alt="support"
                />
                {t('menu.label.extra_support')}
              </NavLink>
            </li>
            <li className="nav-item sub-item">
              <a
                onClick={this.handleMenuClose}
                target="_blank"
                rel="noopener noreferrer"
                href="https://cchellenic.sharepoint.com/sites/intranet/hr/Pages/Leaders%20Hub/Talent/All%20about%20Performance/Performance%20for%20Growth/P4G.aspx"
              >
                {t('menu.label.learn_more')}
              </a>
            </li>
            <li className="nav-item sub-item">
              <a
                onClick={this.handleMenuClose}
                target="_blank"
                rel="noopener noreferrer"
                href="https://cchellenic.sharepoint.com/:x:/r/sites/intranet/hr/_layouts/15/Doc.aspx?sourcedoc=%7BD7E0C1D2-4B21-4A28-A814-65336CF07EA5%7D&file=P4G%20Coordinator_editable.xlsx&action=default&mobileredirect=true"
              >
                {t('menu.label.ask_for_help')}
              </a>
            </li>
            <li className="nav-item last">
              <NavLink
                to="/profile"
                onClick={this.handleMenuClose}
              >
                {t('menu.label.settings')}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="logo">
            <NavLink to="/">
              <img
                src={logo}
                alt="logo"
              />
            </NavLink>
          </div>
          <ul className="main-menu ml-auto">
            <li className="nav-item">
              <NavLink to="/">
                {t('menu.label.home')}
              </NavLink>
            </li>
            <li className="nav-item dropdown reciving">
              <NavLink
                to="/#collapseOne"
                id="navbarDropdown"
                className="nav-link dropdown-toggle"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-hover="dropdown"
              >
                <img
                  src={receiveIcon}
                  alt="receiving-feedback"
                />
                <span>
                  {t('menu.label.receiving_feedback')}
                </span>
              </NavLink>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <NavLink
                  className="dropdown-item"
                  to="/survey"
                >
                  {t('menu.label.feedback_to_me')}
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/statistic"
                >
                  {t('menu.label.compare_with_others')}
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="#request-feedback"
                  onClick={() => this.handleDialogOpen()}
                >
                  {t('menu.label.request_feedback')}
                </NavLink>
              </div>
            </li>
            <li className="nav-item dropdown giving">
              <NavLink
                to="/#collapseTwo"
                id="navbarDropdown"
                className="nav-link dropdown-toggle"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-hover="dropdown"
              >
                <img
                  src={giveIcon}
                  alt="giving-feedback"
                />
                <span>
                  {t('menu.label.giving_feedback')}
                </span>
              </NavLink>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <NavLink
                  className="dropdown-item"
                  to="/feedback"
                >
                  {t('menu.label.feedback_to_others')}
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/provide-feedback"
                >
                  {t('menu.label.provide_feedback_proactively')}
                </NavLink>
              </div>
            </li>
            <li className={`nav-item dropdown team ${displayTeam ? '' : 'd-none'}`}>
              <NavLink
                to="/#collapseThree"
                id="navbarDropdown"
                className="nav-link dropdown-toggle"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-hover="dropdown"
              >
                <img
                  src={teamIcon}
                  alt="team"
                />
                <span>
                  {t('menu.label.team_feedback')}
                </span>
              </NavLink>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <NavLink
                  className={`dropdown-item ${displayTeamApprovals ? '' : 'd-none'}`}
                  to="/approvals"
                >
                  {t('menu.label.approvals')}
                </NavLink>
                <NavLink
                  className={`dropdown-item ${displayTeamResults ? '' : 'd-none'}`}
                  to="/survey/team"
                >
                  {t('menu.label.team_members_feedback')}
                </NavLink>
                <NavLink
                  className={`dropdown-item ${displayTeamStatistic ? '' : 'd-none'}`}
                  to="/statistic/team"
                >
                  {t('menu.label.result_for_my_organization')}
                </NavLink>
              </div>
            </li>
            <li className="nav-item todo">
              <NavLink to="/#collapseFour">
                <img
                  src={toDoIcon}
                  alt="todo"
                />
                <span>
                  {t('menu.label.my_todo')}
                </span>
              </NavLink>
            </li>
            <li className="nav-item dropdown support">
              <NavLink
                to="/#collapseFive"
                id="navbarDropdown"
                className="nav-link dropdown-toggle"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-hover="dropdown"
              >
                <img
                  src={support}
                  alt="team"
                />
                <span>
                  {t('menu.label.extra_support')}
                </span>
              </NavLink>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <a
                  className="dropdown-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cchellenic.sharepoint.com/sites/intranet/hr/Pages/Leaders%20Hub/Talent/All%20about%20Performance/Performance%20for%20Growth/P4G.aspx"
                >
                  {t('menu.label.learn_more')}
                </a>
                <a
                  className="dropdown-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cchellenic.sharepoint.com/:x:/r/sites/intranet/hr/_layouts/15/Doc.aspx?sourcedoc=%7BD7E0C1D2-4B21-4A28-A814-65336CF07EA5%7D&file=P4G%20Coordinator_editable.xlsx&action=default&mobileredirect=true"
                >
                  {t('menu.label.ask_for_help')}
                </a>
              </div>
            </li>
            <li className="nav-item">
              <NavLink to="/profile">
                {t('menu.label.settings')}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={`pop-up-filter-wrapper ${dialogOpen ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                role="button"
                tabIndex={0}
                onClick={this.handleDialogClose}
                onKeyPress={this.handleDialogClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('menu.label.request_feedback')}
              </h3>
              <div className="form-group choose">
                <div className="row">
                  <div className="col mt-3">
                    <Link
                      className="btn standard"
                      to="/add-survey/freestyle_feedback"
                      onClick={() => {
                        this.handleMenuClose();
                        this.handleDialogClose();
                      }}
                    >
                      {t('menu.label.request_freestyle_feedback')}
                    </Link>
                  </div>
                  <div className="col mt-3">
                    <Link
                      className="btn standard"
                      to="/add-survey/project_feedback"
                      onClick={() => {
                        this.handleMenuClose();
                        this.handleDialogClose();
                      }}
                    >
                      {t('menu.label.request_project_feedback')}
                    </Link>
                  </div>
                  <div className="col mt-3">
                    <Link
                      className="btn standard"
                      to="/add-survey/pod_feedback"
                      onClick={() => {
                        this.handleMenuClose();
                        this.handleDialogClose();
                      }}
                    >
                      {t('menu.label.request_pod_feedback')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="form-group text-center">
                <button
                  className="btn standard gray"
                  type="button"
                  onClick={this.handleDialogClose}
                >
                  {t('survey.action.cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ userProfile }) => ({ userProfile });

export default withNamespaces()(connect(mapStateToProps, null)((TopBar)));

TopBar.propTypes = {
  t: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({}),
};

TopBar.defaultProps = {
  userProfile: null,
};
