import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import SurveyStatistic from '../../components/SurveyStatistic';
import { fetchSurveyStatistic } from '../../redux-modules/survey-statistic/actions';

class SurveyStatisticUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compareWith: 'business-unit',
      selectedChart: 'all',
      selectedValue: '',
    };

    this.handleChartChange = this.handleChartChange.bind(this);
    this.handleCompareWithChange = this.handleCompareWithChange.bind(this);
  }

  componentDidMount() {
    const { token, fetchSurveyStatistic: fetchSurveyStatisticAction } = this.props;
    const { compareWith } = this.state;

    fetchSurveyStatisticAction(token, compareWith);
  }

  handleCompareWithChange(compareWith) {
    const { token, fetchSurveyStatistic: fetchSurveyStatisticAction } = this.props;

    this.setState({ compareWith });
    fetchSurveyStatisticAction(token, compareWith);
  }

  handleChartChange(chart, value) {
    const { selectedChart, selectedValue } = this.state;
    if (selectedChart !== chart) {
      this.setState({ selectedChart: chart });
    }
    if (selectedValue !== value) {
      this.setState({ selectedValue: value });
    }
  }

  render() {
    const { statistic, t, userProfile } = this.props;
    const { selectedChart } = this.state;

    if (!statistic) {
      return <div />;
    }

    const isManager = userProfile && userProfile.isManager;

    return (
      <div className="survey-statistic-user">
        <SurveyStatistic
          isManager={isManager}
          user={statistic.user}
          compare={statistic.compare}
          onChartChange={this.handleChartChange}
          onCompareChange={this.handleCompareWithChange}
          onUserChange={this.handleUserChange}
          legend={{
            results: t('survey.label.your_results'),
            benchmark: t('survey.label.benchmark'),
            total: selectedChart === 'participation' ? t('user.label.total') : null,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ token, statistic, userProfile }) => ({ token, statistic, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchSurveyStatistic }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SurveyStatisticUser));

SurveyStatisticUser.propTypes = {
  t: PropTypes.func.isRequired,
  fetchSurveyStatistic: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  statistic: PropTypes.shape({}),
  userProfile: PropTypes.shape({}),
};

SurveyStatisticUser.defaultProps = {
  statistic: null,
  userProfile: null,
};
