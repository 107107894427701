import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import AdminCreateSurveyForm from '../AdminCreateSurveyForm';
import SelectField from '../../../components/SelectField';
import {
  selectType,
  selectUnit,
  selectSegment,
  selectStatus,
  selectYear,
} from '../../../redux-modules/filter-admin-survey/actions';
import { fetchSurveysAdmin } from '../../../redux-modules/survey-admin/actions';

class AdminSurveyFilters extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleTypeChange(type) {
    const {
      selectType: selectTypeAction,
      fetchSurveysAdmin: fetchSurveysAdminAction,
      filterAdminSurvey: { year },
      token,
    } = this.props;
    selectTypeAction(type);
    fetchSurveysAdminAction(token, type, year);
  }

  handleYearChange(year) {
    const {
      selectYear: selectYearAction,
      fetchSurveysAdmin: fetchSurveysAdminAction,
      filterAdminSurvey: { type },
      token,
    } = this.props;
    selectYearAction(year);
    fetchSurveysAdminAction(token, type, year);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      t,
      handleSubmit,
      workforceSegment,
      businessUnit,
      selectUnit: selectUnitAction,
      selectSegment: selectSegmentAction,
      selectStatus: selectStatusAction,
    } = this.props;
    const { open } = this.state;

    const workforceSegmentOptions = (workforceSegment || []).map(w => ({
      label: w.name,
      value: w.name,
    }));

    const businessUnitOptions = (businessUnit || []).map(b => ({
      label: b.name,
      value: b.name,
    }));

    let yearOptions = [];
    for (let year = moment().add(1, 'month').year(); year >= 2019; year -= 1) {
      yearOptions = [
        ...yearOptions,
        {
          label: year.toString(),
          value: year,
        },
      ];
    }

    return (
      <div className="admin-filters">
        <form
          noValidate
          onSubmit={handleSubmit(() => {})}
        >
          <div className="row">
            <div className="col">
              <Field
                name="year"
                label={t('survey.label.year')}
                placeholder={yearOptions[0].label}
                options={yearOptions}
                component={SelectField}
                onValueChange={this.handleYearChange}
              />
            </div>
            <div className="col">
              <Field
                name="type"
                label={t('survey.label.type')}
                showErrors={false}
                placeholder={t('survey.type.upwards_feedback')}
                options={[
                  {
                    label: t('survey.type.upwards_feedback'),
                    value: 'upwards_feedback',
                  },
                  {
                    label: t('survey.type.customer_feedback'),
                    value: 'customer_feedback',
                  },
                  {
                    label: t('survey.type.peer_feedback'),
                    value: 'peer_feedback',
                  },
                ]}
                component={SelectField}
                onValueChange={this.handleTypeChange}
              />
            </div>
            <div className="col">
              <Field
                name="businessUnit"
                showErrors={false}
                hasEmpty
                label={t('user.label.business_unit')}
                placeholder={t('survey.placeholder.business_unit')}
                options={businessUnitOptions}
                component={SelectField}
                onValueChange={selectUnitAction}
              />
            </div>
            <div className="col d-none">
              <Field
                name="segment"
                showErrors={false}
                hasEmpty
                label={t('survey.label.workforce_segment')}
                placeholder={t('survey.placeholder.workforce_segment')}
                options={workforceSegmentOptions}
                component={SelectField}
                onValueChange={selectSegmentAction}
              />
            </div>
            <div className="col">
              <Field
                name="type"
                label={t('survey.label.type')}
                showErrors={false}
                placeholder={t('survey.placeholder.any_status')}
                hasEmpty
                options={[
                  {
                    label: t('survey.label.active'),
                    value: 'active',
                  },
                  {
                    label: t('survey.label.complete'),
                    value: 'complete',
                  },
                ]}
                component={SelectField}
                onValueChange={selectStatusAction}
              />
            </div>
            <div className="col">
              <div className="form-group text-right">
                <button
                  className="btn standard add-survey"
                  type="button"
                  onClick={this.handleOpen}
                >
                  <i className="fas fa-plus" />
                  {' '}
                  {t('menu.label.new')}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className={`pop-up-filter-wrapper ${open ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                style={{ position: 'absolute' }}
                role="button"
                tabIndex={0}
                onClick={this.handleClose}
                onKeyPress={this.handleClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('menu.label.new')}
              </h3>
              <AdminCreateSurveyForm
                onSubmit={this.handleClose}
                onCancel={this.handleClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  workforceSegment,
  businessUnit,
  filterAdminSurvey,
}) => ({
  token,
  workforceSegment,
  businessUnit,
  filterAdminSurvey,
  initialValues: {
    year: moment().year(),
  },
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveysAdmin,
  selectType,
  selectUnit,
  selectSegment,
  selectStatus,
  selectYear,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'adminSurveyFilters',
  destroyOnUnmount: false,
})(AdminSurveyFilters)));

AdminSurveyFilters.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  workforceSegment: PropTypes.arrayOf(PropTypes.shape({})),
  businessUnit: PropTypes.arrayOf(PropTypes.shape({})),
  fetchSurveysAdmin: PropTypes.func.isRequired,
  filterAdminSurvey: PropTypes.shape({}).isRequired,
  selectType: PropTypes.func.isRequired,
  selectUnit: PropTypes.func.isRequired,
  selectSegment: PropTypes.func.isRequired,
  selectStatus: PropTypes.func.isRequired,
  selectYear: PropTypes.func.isRequired,
};

AdminSurveyFilters.defaultProps = {
  workforceSegment: null,
  businessUnit: null,
};
