/* global document */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import i18next from '../../utils/i18next';

const LanguageHandler = ({ userProfile }) => {
  if (userProfile && userProfile.language) {
    const { language } = userProfile;
    document.documentElement.setAttribute('lang', language);
    document.documentElement.setAttribute('dir', (language === 'ar-EG' ? 'rtl' : 'ltr'));
    setTimeout(() => i18next.changeLanguage(language), 0);
  }

  return (
    <div className="language-handler" />
  );
};

const mapStateToProps = ({ userProfile }) => ({ userProfile });

export default connect(mapStateToProps, null)(LanguageHandler);

LanguageHandler.propTypes = {
  userProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string,
  }),
};

LanguageHandler.defaultProps = {
  userProfile: null,
};
