/* global localStorage */
import _ from 'lodash';

import { USER_FETCHED, USER_UPDATED } from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const USER_PROFILE = 'user_profile';
const initialState = JSON.parse(localStorage.getItem(USER_PROFILE));

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCHED:
    case USER_UPDATED: {
      let newProfileData = _.get(action.payload, 'data', state);
      if (newProfileData) {
        const isManager = newProfileData.team.users.length > 0;
        const isScrumMaster = newProfileData.scrumTeam.users.length > 0;
        newProfileData = { ...newProfileData, ...{ isManager, isScrumMaster } };
        localStorage.setItem(USER_PROFILE, JSON.stringify(newProfileData));
        return newProfileData;
      }
      return state;
    }
    case USER_LOGGED_OUT: {
      localStorage.removeItem(USER_PROFILE);
      return null;
    }
    default: {
      return state;
    }
  }
};
