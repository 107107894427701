import React from 'react';
import PropTypes from 'prop-types';

import FeedbackPreview from '../../containers/FeedbackPreview';

const FeedbackPreviewPage = ({ match: { params: { id } } }) => (
  <div className="feedback-preview-page">
    <FeedbackPreview id={id} />
  </div>
);

export default FeedbackPreviewPage;

FeedbackPreviewPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
