import { FEEDBACK_TIMEFRAME_SELECTED, FEEDBACK_USER_SELECTED } from './types';

const selectFeedbackTimeframe = timeframe => ({
  type: FEEDBACK_TIMEFRAME_SELECTED,
  payload: timeframe,
});

const selectFeedbackUser = user => ({
  type: FEEDBACK_USER_SELECTED,
  payload: user,
});

export {
  selectFeedbackTimeframe,
  selectFeedbackUser,
};
