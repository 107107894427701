import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import SelectField from '../../components/SelectField';
import { updateUser } from '../../redux-modules/me/actions';
import i18next from '../../utils/i18next';

const AVAILABLE_LANGUAGES = process.env.REACT_APP_AVAILABLE_LANGUAGES;

class ChangeLanguageForm extends Component {
  constructor(props) {
    super(props);

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleLanguageChange(language) {
    const {
      token,
      updateUser: updateUserAction,
    } = this.props;

    updateUserAction(token, language).then(
      () => {
        i18next.changeLanguage(language).catch();
      },
      () => {},
    );
  }

  handleSubmit(values) {
    const {
      token,
      updateUser: updateUserAction,
    } = this.props;

    updateUserAction(token, values.language);
  }

  render() {
    const { t, handleSubmit } = this.props;

    const languageOptions = AVAILABLE_LANGUAGES
      .split(',')
      .map(language => (
        {
          value: language,
          label: t(`language.${language}`),
        }
      ));

    return (
      <div className="change-language-form">
        <form
          noValidate
          onSubmit={handleSubmit(() => {})}
        >
          <div>
            <Field
              name="language"
              label={t('user.label.language')}
              options={languageOptions}
              onValueChange={value => this.handleLanguageChange(value)}
              component={SelectField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ token, userProfile }) => {
  let initialValues;
  if (userProfile) {
    initialValues = {
      language: userProfile.language,
    };
  }

  return {
    token,
    initialValues,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ updateUser }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'changeLanguageForm',
    destroyOnUnmount: false,
  })(ChangeLanguageForm),
));

ChangeLanguageForm.propTypes = {
  token: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ChangeLanguageForm.defaultProps = {
  token: null,
};
