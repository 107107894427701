import axios from 'axios';

import {
  SURVEY_EXCLUDE_RULES_FETCHED,
  SURVEY_EXCLUDE_RULE_FETCHED,
  SURVEY_EXCLUDE_RULE_CREATED,
  SURVEY_EXCLUDE_RULE_DELETED,
  SURVEY_EXCLUDE_RULE_UPDATED,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchSurveyExcludeRules = (type) => {
  const url = `${REST_API_URL}v1/survey-exclude-rule`;
  const request = axios.get(
    url,
    {
      params: {
        type,
      },
    },
  );

  return {
    type: SURVEY_EXCLUDE_RULES_FETCHED,
    payload: request,
  };
};

const fetchSurveyExcludeRule = (id) => {
  const url = `${REST_API_URL}v1/survey-exclude-rule/${id}`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEY_EXCLUDE_RULE_FETCHED,
    payload: request,
  };
};

const createSurveyExcludeRule = (
  type,
  businessUnit,
  workforceSegment,
  personnelSubarea,
  countryCode,
  companyCode,
  email,
  locationId,
  positionName,
  standardGroupJobNumber,
  originalHireDate,
) => {
  const url = `${REST_API_URL}v1/survey-exclude-rule`;
  const request = axios.post(
    url,
    {
      type,
      businessUnit,
      workforceSegment,
      personnelSubarea,
      countryCode,
      companyCode,
      email,
      locationId,
      positionName,
      standardGroupJobNumber,
      originalHireDate,
    },
  );

  return {
    type: SURVEY_EXCLUDE_RULE_CREATED,
    payload: request,
    meta: {
      type,
    },
  };
};

const updateSurveyExcludeRule = (
  id,
  type,
  businessUnit,
  workforceSegment,
  personnelSubarea,
  countryCode,
  companyCode,
  email,
  locationId,
  positionName,
  standardGroupJobNumber,
  originalHireDate,
) => {
  const url = `${REST_API_URL}v1/survey-exclude-rule/${id}`;
  const request = axios.put(
    url,
    {
      type,
      businessUnit,
      workforceSegment,
      personnelSubarea,
      countryCode,
      companyCode,
      email,
      locationId,
      positionName,
      standardGroupJobNumber,
      originalHireDate,
    },
  );

  return {
    type: SURVEY_EXCLUDE_RULE_UPDATED,
    payload: request,
    meta: {
      type,
    },
  };
};

const removeSurveyExcludeRule = (id) => {
  const url = `${REST_API_URL}v1/survey-exclude-rule/${id}`;
  const request = axios.delete(url);

  return {
    type: SURVEY_EXCLUDE_RULE_DELETED,
    payload: request,
  };
};

export {
  fetchSurveyExcludeRules,
  fetchSurveyExcludeRule,
  createSurveyExcludeRule,
  updateSurveyExcludeRule,
  removeSurveyExcludeRule,
};
