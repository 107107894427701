import _ from 'lodash';

export default {
  calculateRatings: (rating1, rating2, rating3, rating4, rating5) => {
    const sum = rating1 + rating2 + rating3 + rating4 + rating5;

    if (sum === 0) {
      return null;
    }

    const dataSet = [
      { rating: 1, value: 100 * rating1 / sum },
      { rating: 2, value: 100 * rating2 / sum },
      { rating: 3, value: 100 * rating3 / sum },
      { rating: 4, value: 100 * rating4 / sum },
      { rating: 5, value: 100 * rating5 / sum },
    ];
    const diff = 100 - dataSet.map(a => Math.floor(a.value)).reduce((a, b) => a + b);
    const sortedDataSet = dataSet
      .sort((a, b) => {
        const aDiff = Math.floor(a.value) - a.value;
        const bDiff = Math.floor(b.value) - b.value;
        if (aDiff === bDiff) {
          return 0;
        }
        return aDiff > bDiff ? 1 : -1;
      })
      .map((a, index) => (index < diff
        ? ({
          rating: a.rating,
          value: Math.floor(a.value) + 1,
        })
        : ({
          rating: a.rating,
          value: Math.floor(a.value),
        })
      ));

    return _.keyBy(sortedDataSet, 'rating');
  },
  calculateFavorability: (rating1, rating2, rating3, rating4, rating5) => {
    const sum = rating1 + rating2 + rating3 + rating4 + rating5;

    if (sum === 0) {
      return null;
    }

    return Math.floor(100 * (rating4 + rating5) / sum);
  },
};
