/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import closeNotification from '../../redux-modules/notification/actions';

import './notification.css';

class Notification extends Component {
  constructor(props) {
    super(props);

    this.handleBackdropClick = this.handleBackdropClick.bind(this);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleBackdropClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBackdropClick, false);
  }

  handleBackdropClick() {
    const { notifications, closeNotification: closeNotificationAction } = this.props;
    if (notifications.visible) {
      closeNotificationAction();
    }
  }

  render() {
    const { notifications } = this.props;

    return (
      <div className={`notification text-center fade ${notifications.visible ? 'show' : 'hidden'}`}>
        <div className={`alert alert-${notifications.type}`}>
          {notifications.message}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ notifications }) => ({ notifications });

const mapDispatchToProps = dispatch => bindActionCreators({ closeNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

Notification.propTypes = {
  closeNotification: PropTypes.func.isRequired,
  notifications: PropTypes.shape({
    message: PropTypes.string,
    visible: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
};
