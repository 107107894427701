import _ from 'lodash';

import {
  SURVEY_EXCLUDE_RULE_FETCHED,
  SURVEY_EXCLUDE_RULES_FETCHED,
} from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

const surveyExcludeRules = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_EXCLUDE_RULES_FETCHED: {
      const data = _.get(action.payload, 'data.surveyExcludeRules', null);

      return data ? _.keyBy(data, 'id') : state;
    }
    case SURVEY_EXCLUDE_RULE_FETCHED: {
      const rule = _.get(action.payload, 'data', null);

      if (!rule) {
        return state;
      }

      const newRule = {};
      newRule[rule.id] = rule;

      return { ...state, ...newRule };
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default surveyExcludeRules;
