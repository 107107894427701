import axios from 'axios';

import {
  SURVEY_STATISTIC_FETCHED,
  SURVEY_STATISTIC_TEAM_FETCHED,
  SURVEY_STATISTIC_PARTNER_FETCHED,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchSurveyStatistic = (token, compareWith) => {
  const url = `${REST_API_URL}v1/survey-statistic`;
  const request = axios.get(
    url,
    {
      params: {
        compareWith,
      },
    },
  );

  return {
    type: SURVEY_STATISTIC_FETCHED,
    payload: request,
  };
};

const fetchSurveyStatisticTeam = (token, compareWith, depth = 1, user = null) => {
  const url = `${REST_API_URL}v1/survey-statistic/team`;
  const request = axios.get(
    url,
    {
      params: {
        compareWith,
        user,
        depth,
      },
    },
  );

  return {
    type: SURVEY_STATISTIC_TEAM_FETCHED,
    payload: request,
  };
};

const fetchSurveyStatisticPartner = (token, partner = null) => {
  const url = `${REST_API_URL}v1/survey-statistic/partner`;
  const request = axios.get(
    url,
    {
      params: {
        personnelAreas: partner,
      },
    },
  );

  return {
    type: SURVEY_STATISTIC_PARTNER_FETCHED,
    payload: request,
  };
};

export { fetchSurveyStatistic, fetchSurveyStatisticTeam, fetchSurveyStatisticPartner };
