import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import AutocompleteSelectField from '../../components/AutocompleteSelectField';
import TextAreaField from '../../components/TextAreaField';
import InputField from '../../components/InputField';
import CheckboxField from '../../components/CheckboxField';
import SelectField from '../../components/SelectField';
import SubmitField from '../../components/SubmitField';
import fetchBusinessUnit from '../../redux-modules/business-unit/actions';
import fetchUsers from '../../redux-modules/user/actions';
import validators from '../../utils/validators';
import './add-survey-form.css';

class AddSurveyForm extends Component {
  static handleEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  constructor(props) {
    super(props);

    this.renderFreestyleQuestions = this.renderFreestyleQuestions.bind(this);
  }

  componentDidMount() {
    const {
      token,
      users,
      type,
      fetchUsers: fetchUsersAction,
      fetchBusinessUnit: fetchBusinessUnitAction,
    } = this.props;

    if (!users || users.length === 0) {
      fetchUsersAction(token);
    }

    if (type === 'pod_feedback') {
      fetchBusinessUnitAction(token);
    }
  }

  handleSubmit(values) {
    const { onSubmit } = this.props;
    const providers = (values.providers || []).map(value => value.value);

    onSubmit(values.title, providers, values.questions, values.businessUnit);
  }

  renderQuestions() {
    const { t, type } = this.props;

    return (
      <div>
        {[...Array(type === 'project_feedback' ? 7 : 10)].map((value, index) => {
          const questionText = t(`question.${type}.question_${index + 1}`).split('<br>');

          return (
            <div
              className="question-box"
              key={`question.${type}.question_${index + 1}`}
            >
              {questionText.map(question => (
                <div key={question}>
                  {question}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }

  renderFreestyleQuestions({ fields }) {
    const { t } = this.props;

    return (
      <div className="questions">
        {fields.map(question => (
          <div
            className="write-feedback-section"
            key={question}
          >
            <Field
              name={`${question}.text`}
              label={t('feedback.label.ask_question')}
              component={TextAreaField}
            />
            <Field
              name={`${question}.rating`}
              type="checkbox"
              label={t('survey.label.include_rating')}
              showLabel
              component={CheckboxField}
            />
          </div>
        ))
        }
        <div className="form-group text-center">
          <button
            className="btn standard add-question"
            type="button"
            onClick={() => fields.push({})}
          >
            +
            {' '}
            {t('survey.action.add_question')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      t,
      handleSubmit,
      onCancel,
      userProfile,
      users,
      businessUnit,
      type,
    } = this.props;

    const filteredUsers = (users || []).filter(u => userProfile && u.value !== userProfile.email);

    const titleLabel = type === 'project_feedback'
      ? t('survey.label.title')
      : t('survey.label.pod_title');

    let providersLabel = t('survey.label.select_providers_freestyle_feedback');
    if (type === 'project_feedback') {
      providersLabel = t('survey.label.select_providers_project_feedback');
    } else if (type === 'pod_feedback') {
      providersLabel = t('survey.label.select_providers_pod_feedback');
    }

    const launchLabel = type === 'project_feedback'
      ? t('feedback.label.launch_note_project')
      : t('feedback.label.launch_note_pod');

    const businessUnitOptions = (businessUnit || [])
      .filter(b => !b.name.includes('Multon'))
      .map(b => (b.name.includes('Russia')
        ? { name: 'Coca-Cola HBC Russia & Multon', id: 'Coca-Cola HBC Russia & Multon' }
        : b))
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map(b => ({
        label: b.name,
        value: b.id,
      }));

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
      <div className="add-survey-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
          onKeyPress={AddSurveyForm.handleEnterKey}
        >
          <div className="content-box accordion">
            {type === 'pod_feedback' && (
              <p>
                {t('survey.label.question_preview')}
              </p>
            )}
            {type !== 'freestyle_feedback' && this.renderQuestions()}
            {type !== 'freestyle_feedback' && (
              <div className="form-group">
                <Field
                  name="title"
                  type="text"
                  label={titleLabel}
                  showLabel
                  component={InputField}
                />
              </div>
            )}
            {type === 'pod_feedback' && (
              <div className="form-group form-group--business-unit">
                <Field
                  name="businessUnit"
                  hasEmpty
                  label={t('user.label.business_unit')}
                  showLabel
                  placeholder={t('survey.placeholder.business_unit_select')}
                  options={businessUnitOptions}
                  component={SelectField}
                />
              </div>
            )}
            <div className={`select-feedback-provider-section select-feedback-provider-section--${type}`}>
              <Field
                name="providers"
                label={providersLabel}
                options={filteredUsers}
                component={AutocompleteSelectField}
              />
            </div>
            {type === 'freestyle_feedback' && (<FieldArray
              name="questions"
              component={this.renderFreestyleQuestions}
            />
            )}
            {type !== 'freestyle_feedback' && (
              <p align="center">
                <strong>
                  {launchLabel}
                </strong>
              </p>
            )}
          </div>
          <div className="buttons-group">
            <div className="buttons-group-wrapper">
              <button
                className="btn standard gray"
                type="button"
                onClick={onCancel}
              >
                {t('survey.action.back')}
              </button>
              <Field
                inline
                name="submit"
                label={t(type === 'freestyle_feedback' ? 'survey.action.next' : 'survey.action.submit')}
                component={SubmitField}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t, type }) => {
  const errors = {};

  if (!validators.required(values.providers)) {
    errors.providers = t('survey.error.provider_required');
  }

  if (type === 'project_feedback' && !validators.minLength(values.providers, 5)) {
    errors.providers = t('survey.error.provider_min_5');
  }

  if (type === 'pod_feedback' && !validators.required(values.title)) {
    errors.title = t('survey.error.name_required');
  }

  if (type === 'pod_feedback' && !validators.required(values.businessUnit)) {
    errors.businessUnit = t('survey.error.business_unit_required');
  }

  if (type === 'pod_feedback' && !validators.minLength(values.providers, 2)) {
    errors.providers = t('survey.error.provider_min_2');
  }

  if (!validators.required(values.questions)) {
    errors.questions = { _error: t('survey.error.question_required') };
  } else {
    const questionArrayErrors = [];
    values.questions.forEach((question, questionIndex) => {
      const questionErrors = {};
      if (!validators.required(question.text)) {
        questionErrors.text = { _error: t('survey.error.question_required') };
        questionArrayErrors[questionIndex] = questionErrors;
      }
      return questionErrors;
    });

    if (questionArrayErrors.length) {
      errors.questions = { _error: questionArrayErrors };
    }
  }

  return errors;
};

const mapStateToProps = ({
  token,
  userProfile,
  users,
  businessUnit,
}) => ({
  token,
  userProfile,
  users,
  businessUnit,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchUsers,
  fetchBusinessUnit,
}, dispatch);

export default withNamespaces()(reduxForm({
  validate,
  form: 'addSurveyForm',
  initialValues: { questions: [{}] },
})(connect(mapStateToProps, mapDispatchToProps)(AddSurveyForm)));

AddSurveyForm.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
  businessUnit: PropTypes.arrayOf(PropTypes.shape({})),
  fetchUsers: PropTypes.func.isRequired,
  fetchBusinessUnit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AddSurveyForm.defaultProps = {
  users: null,
  businessUnit: null,
};
