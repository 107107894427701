import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import FeedbackPreviewItem from '../../components/FeedbackPreviewItem/FeedbackPreviewItem';
import TipsProvide from '../../components/TipsProvide';
import SubmitField from '../../components/SubmitField';
import TextAreaField from '../../components/TextAreaField';
import AutocompleteSelectField from '../../components/AutocompleteSelectField';
import CheckboxField from '../../components/CheckboxField';
import fetchUsers from '../../redux-modules/user/actions';
import { createFeedback } from '../../redux-modules/feedback/actions';
import validators from '../../utils/validators';

import visibility from '../../assets/images/visibility.svg';
import anonimity from '../../assets/images/anonimity.svg';
import cheerUp from '../../assets/images/cheer-up.png';

import './feedback-create-form.css';

class FeedbackCreateForm extends Component {
  constructor(props) {
    super(props);

    this.state = { step: 1 };
  }

  componentDidMount() {
    const { token, users, fetchUsers: fetchUsersAction } = this.props;

    if (!users || users.length === 0) {
      fetchUsersAction(token);
    }
  }

  handleSubmit(values) {
    const { token, createFeedback: createFeedbackAction } = this.props;
    const { step } = this.state;

    if (step === 2) {
      this.setState({ step: 3 });
      return Promise.resolve();
    }

    const users = (values.user || []).map(value => value.value);
    return createFeedbackAction(token, values.answer, users, values.visibility).then(
      () => this.setState({ step: 4 }),
      () => {},
    );
  }

  renderStepOne() {
    const { t, userProfile } = this.props;

    return (
      <div>
        <div className="content-box accordion">
          <p>
            {t('survey.label.hi')}
            {' '}
            {userProfile.name}
            ,
          </p>
          <p>
            {t('answer.label.tips_proactively_1')}
          </p>
          <div className="row visibility-anonimity">
            <div className="col my-auto">
              <div className="row">
                <div className="col-sm-4 my-auto">
                  <img
                    src={visibility}
                    alt="visibility"
                  />
                </div>
                <div className="col-sm my-auto">
                  <p>
                    {t('answer.label.tips_proactively_2')}
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-auto">
              <div className="row">
                <div className="col-sm-4 my-auto">
                  <img
                    src={anonimity}
                    alt="anonimity"
                  />
                </div>
                <div className="col-sm my-auto">
                  <p>
                    {t('answer.label.tips_proactively_3')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <TipsProvide />
        </div>
        <div className="buttons-group">
          <div className="buttons-group-wrapper">
            <Link
              className="btn standard gray"
              to="/"
            >
              {t('survey.action.cancel')}
            </Link>
            <button
              className="btn standard"
              type="button"
              onClick={() => this.setState({ step: 2 })}
            >
              {t('survey.action.next')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderStepTwo() {
    const {
      t,
      userProfile,
      users,
      answer,
      handleSubmit,
    } = this.props;
    const { step } = this.state;

    const filteredUsers = (users || []).filter(u => userProfile && u.value !== userProfile.email);

    return (
      <form
        noValidate
        onSubmit={handleSubmit(this.handleSubmit.bind(this))}
      >
        <div className="content-box accordion">
          {step === 2 && (
            <div>
              <div className="select-feedback-provider-section">
                <Field
                  name="user"
                  label={t('survey.label.select_user')}
                  isMulti
                  options={filteredUsers}
                  component={AutocompleteSelectField}
                />
              </div>
              <div className="write-feedback-section">
                <Field
                  name="answer"
                  label={t('feedback.label.answer_question')}
                  component={TextAreaField}
                />
              </div>
              <p align="center">
                <strong>
                  {t('feedback.label.launch_note_provide')}
                </strong>
              </p>
              <div className="content-box text-center">
                <Field
                  name="visibility"
                  type="checkbox"
                  label={t('survey.label.result_visible_other')}
                  showLabel
                  component={CheckboxField}
                />
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="reports">
              <div className="report-accordion feed-back-list">
                <FeedbackPreviewItem
                  index={0}
                  question={t('feedback.label.answer_question')}
                  scale={null}
                  answer={answer}
                  rating={null}
                />
              </div>
            </div>
          )}
          <div className="buttons-group">
            <div className="buttons-group-wrapper">
              <button
                className="btn standard gray"
                type="button"
                onClick={() => { this.setState({ step: step === 3 ? 2 : 1 }); }}
              >
                {t('survey.action.back')}
              </button>
              <Field
                className="btn standard"
                name="submit"
                inline
                label={t(step === 3 ? 'feedback.action.submit' : 'survey.action.next')}
                component={SubmitField}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderStepFour() {
    const { t, onSubmit } = this.props;

    return (
      <div>
        <div className="content-box accordion">
          <p align="center">
            <strong>
              {t('answer.label.thank_you')}
              !
            </strong>
          </p>
          <p align="center">
            {t('answer.label.thank_you_desc')}
          </p>
          <div className="cheer-up">
            <img
              align="center"
              alt="cheer up"
              src={cheerUp}
            />
          </div>
        </div>
        <div className="buttons-group">
          <button
            type="button"
            className="btn standard"
            onClick={onSubmit || (() => {})}
          >
            {t('menu.label.close')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { step } = this.state;

    return (
      <div className="feedback-create-form">
        <div className="content-title">
          <div className="row">
            <div className="col my-auto">
              <span className="box-title">
                {moment().year()}
                {' '}
                Q
                {moment().quarter()}
                {' '}
                {t('answer.label.provide_proactively_title')}
              </span>
            </div>
          </div>
        </div>
        {step === 1 && this.renderStepOne()}
        {(step === 2 || step === 3) && this.renderStepTwo()}
        {(step === 4) && this.renderStepFour()}
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.user)) {
    errors.user = t('feedback.error.user_required');
  }

  if (!validators.required(values.answer)) {
    errors.answer = t('feedback.error.answer_required');
  }

  return errors;
};

const selector = formValueSelector('feedbackCreateForm');

const mapStateToProps = state => ({
  token: state.token,
  users: state.users,
  userProfile: state.userProfile,
  answer: selector(state, 'answer'),
});

const mapDispatchToProps = dispatch => bindActionCreators(
  { fetchUsers, createFeedback },
  dispatch,
);

export default withNamespaces()(reduxForm({
  validate,
  form: 'feedbackCreateForm',
  initialValues: {
    visibility: true,
  },
})(connect(mapStateToProps, mapDispatchToProps)(FeedbackCreateForm)));

FeedbackCreateForm.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
  userProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string,
  }),
  answer: PropTypes.string,
  fetchUsers: PropTypes.func.isRequired,
  createFeedback: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

FeedbackCreateForm.defaultProps = {
  users: null,
  userProfile: null,
  answer: '',
};
