import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { activateUser } from '../../redux-modules/activate-user/actions';

class ActivateForm extends Component {
  componentDidMount() {
    const { id, activateUser: activateUserAction } = this.props;
    activateUserAction(id).catch(() => {});
  }

  // eslint-disable-next-line class-methods-use-this
  renderLoading() {
    return (
      <div className="activate-form">
        Loading...
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSuccess() {
    const { t } = this.props;

    return (
      <div className="activate-form">
        <div className="form-group text-center">
          <Link
            className="btn standard"
            to="/sign-in"
          >
            {t('user.action.login')}
          </Link>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderError() {
    return (
      <div className="activate-form" />
    );
  }

  render() {
    const { activateUserStatus } = this.props;

    if (activateUserStatus === null) {
      return this.renderLoading();
    }

    if (activateUserStatus === false) {
      return this.renderError();
    }

    return this.renderSuccess();
  }
}

const mapStateToProps = ({ activateUserStatus }) => ({ activateUserStatus });

const mapDispatchToProps = dispatch => bindActionCreators({ activateUser }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(ActivateForm));

ActivateForm.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  activateUser: PropTypes.func.isRequired,
  activateUserStatus: PropTypes.bool,
};

ActivateForm.defaultProps = {
  activateUserStatus: null,
};
