import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import SelectField from '../../components/SelectField';
import SubmitField from '../../components/SubmitField';
import {
  selectFeedbackTimeframe,
  selectFeedbackUser,
} from '../../redux-modules/filter-feedback/actions';

class UserFeedbackFilters extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillUnmount() {
    const {
      selectFeedbackTimeframe: selectFeedbackTimeframeAction,
      selectFeedbackUser: selectFeedbackUserAction,
    } = this.props;

    selectFeedbackTimeframeAction(null);
    selectFeedbackUserAction(null);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSubmit(values) {
    const {
      selectFeedbackTimeframe: selectFeedbackTimeframeAction,
      selectFeedbackUser: selectFeedbackUserAction,
    } = this.props;

    const { timeframe, user } = values;

    selectFeedbackTimeframeAction(timeframe || null);
    selectFeedbackUserAction(user || null);

    this.setState({ open: false });
  }

  render() {
    const {
      t,
      handleSubmit,
      filterUserFeedback,
      callToActions,
      feedbackList,
    } = this.props;
    const { open } = this.state;

    const dataA = (callToActions || [])
      .filter(notification => notification.type === 'provide_feedback')
      .map(notification => notification.fullName);
    const dataB = _.values(feedbackList).map(feedback => feedback.fullName);
    const users = _.uniq([...dataA, ...dataB]);
    const userOptions = users.map(user => ({
      value: user,
      label: user,
    }));
    const userNames = _.keyBy(userOptions, 'value');

    const selectedTimeFrame = _.get(filterUserFeedback, 'timeframe', null);
    const selectedUser = _.get(filterUserFeedback, 'user', null);
    const selectedUserName = _.get(userNames, `${selectedUser}.label`, '');

    return (
      <div className="user-feedback-filters fiter-box">
        <div className="row">
          <div className="col my-auto">
            <ul className="filter-selected">
              <li>
                {!selectedUser
                  ? t('survey.placeholder.any_receiver')
                  : selectedUserName
                }
              </li>
              <li>
                {!selectedTimeFrame
                  ? t('survey.placeholder.all_time')
                  : t(`survey.label.${selectedTimeFrame}`)
                }
              </li>
            </ul>
          </div>
          <div className="col text-right my-auto">
            <button
              type="button"
              className="btn standard gray"
              onClick={this.handleOpen}
            >
              {t('survey.action.filter')}
            </button>
          </div>
        </div>
        <div className={`pop-up-filter-wrapper ${open ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                role="button"
                tabIndex={0}
                onClick={this.handleClose}
                onKeyPress={this.handleClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('survey.action.filter')}
              </h3>
              <form
                noValidate
                onSubmit={handleSubmit(this.handleSubmit.bind(this))}
              >
                <div>
                  <Field
                    name="user"
                    label={t('survey.label.user')}
                    showErrors={false}
                    placeholder={t('survey.placeholder.any_receiver')}
                    hasEmpty
                    options={userOptions}
                    component={SelectField}
                  />
                </div>
                <div>
                  <Field
                    name="timeframe"
                    label={t('survey.label.timeframe')}
                    showErrors={false}
                    placeholder={t('survey.placeholder.all_time')}
                    hasEmpty
                    options={[
                      {
                        label: t('survey.label.this_quarter'),
                        value: 'this_quarter',
                      },
                      {
                        label: t('survey.label.this_year'),
                        value: 'this_year',
                      },
                    ]}
                    component={SelectField}
                  />
                </div>
                <div className="form-group text-center">
                  <Field
                    name="submit"
                    label={t('survey.action.apply')}
                    component={SubmitField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ filterUserFeedback, feedbackList, callToActions }) => ({
  filterUserFeedback,
  feedbackList,
  callToActions,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectFeedbackTimeframe,
  selectFeedbackUser,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'userFeedbackFilters',
})(UserFeedbackFilters)));

UserFeedbackFilters.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  filterUserFeedback: PropTypes.shape({
    user: PropTypes.string,
    timeframe: PropTypes.string,
  }),
  selectFeedbackTimeframe: PropTypes.func.isRequired,
  selectFeedbackUser: PropTypes.func.isRequired,
  callToActions: PropTypes.arrayOf(PropTypes.shape({})),
  feedbackList: PropTypes.shape({}),
};

UserFeedbackFilters.defaultProps = {
  filterUserFeedback: null,
  feedbackList: null,
  callToActions: null,
};
