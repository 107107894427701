import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ title }) => (
  <i
    className="fas fa-info-circle"
    data-toggle="tooltip"
    data-html="true"
    title={title}
  />
);

export default Tooltip;

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
};
