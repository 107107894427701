import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css'

import { unregister } from './registerServiceWorker';
import './polyfills';
import './index.css';

import reducers from './redux-modules/reducers';
import i18next from './utils/i18next';

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);

const APP = process.env.REACT_APP_APP;
const App = require('./containers/' + APP).default;

unregister();

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={createStoreWithMiddleware(reducers)}>
      <App />
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
