import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

const FeedbackPreviewItem = ({
  t,
  index,
  question,
  scale,
  answer,
  rating,
  selfAssessment,
}) => {
  const cardClass = {
    1: 'has-i-strongly-disagree',
    2: 'has-i-disagree',
    3: 'has-i-partly-agree',
    4: 'has-i-agree',
    5: 'has-i-strongly-agree',
  };

  let questionKey = 'survey.type.freestyle_feedback';
  if (question && selfAssessment) {
    questionKey = `${question}_self_assessment`;
  } else if (question) {
    questionKey = question;
  }

  let questionText = t(questionKey);
  if (!questionText) {
    questionText = questionKey;
  }
  if (questionText) {
    questionText = questionText.replace('<br>', ' ');
  }

  const scaleIndexed = _.keyBy(scale, 'value');
  let answerKey = `answer.label.rating_${rating}`;
  if (!_.isEmpty(scale) && selfAssessment) {
    answerKey = `${_.get(scaleIndexed, `${rating}.text`, `answer.label.rating_${rating}`)}_self_assessment`;
  } else if (!_.isEmpty(scale)) {
    answerKey = _.get(scaleIndexed, `${rating}.text`, `answer.label.rating_${rating}`);
  }

  return (
    <div className={`feedback-preview-item card ${_.get(cardClass, rating, '')}`}>
      <div className="card-header">
        <div className="report-box-title">
          {index + 1}
          {'. '}
          {questionText}
        </div>
      </div>
      <hr />
      <div className="card-body p-2">
        {rating > 0 && (
          <p className="your-answer">
            <strong>
              {t('answer.label.your_answer')}
              :
            </strong>
            {' '}
            {t(answerKey)}
          </p>
        )}
        {!!answer && (
          <p className="your-answer">
            <strong>
              {t('answer.label.comment')}
              :
            </strong>
            {' '}
            {answer}
          </p>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(FeedbackPreviewItem);

FeedbackPreviewItem.propTypes = {
  t: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  scale: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
  answer: PropTypes.string,
  rating: PropTypes.number,
  selfAssessment: PropTypes.bool,
};

FeedbackPreviewItem.defaultProps = {
  scale: [],
  answer: '',
  rating: null,
  selfAssessment: false,
};
