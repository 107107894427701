import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const TransferHistoryReportItem = ({
  t,
  quarter,
  year,
  progress,
  title,
  type,
  name,
  endDate,
  favorability,
}) => (
  <div className="survey-item row">
    <div className="col">
      <div className="sub-acordion-title">
        {year}
        {' '}
        Q
        {quarter}
        {' '}
        {title}
        {' '}
        {t(`survey.type.${type}`)}
        {' '}
        {t('survey.label.for')}
        {' '}
        {name}
      </div>
      <div className="sub-acordion-response">
        {t('survey.label.responses')}
        {': '}
        {progress.count}
        {'/'}
        {progress.total}
      </div>
      <div className="sub-acordion-date">
        {t('survey.date.closed')}
        {': '}
        {endDate.format(t('date_format'))}
      </div>
      <div>
        {t('survey.label.favorability')}
        {': '}
        {Math.floor(favorability * 100)}
      </div>
    </div>
  </div>
);

export default withNamespaces()(TransferHistoryReportItem);

TransferHistoryReportItem.propTypes = {
  t: PropTypes.func.isRequired,
  quarter: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  progress: PropTypes.shape({
    total: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  endDate: PropTypes.shape({}).isRequired,
  favorability: PropTypes.number.isRequired,
};

TransferHistoryReportItem.defaultProps = {
  title: '',
};
