import React from 'react';
import PropTypes from 'prop-types';

import FeedbackProvideExternalForm from '../../containers/FeedbackProvideExternalForm';
import Footer from '../../components/Footer';

import cchLogo from '../../assets/images/cch-logo.svg';
import logo from '../../assets/images/logo.svg';

const FeedbackProvideExternalPage = ({ match: { params: { id } } }) => (
  <div className="feedback-provide-external-page">
    <nav className="navbar navbar-expand-lg navbar-gray static-top">
      <div className="mobile-header">
        <div className="mobile-header-logo">
          <img
            src={cchLogo}
            alt="logo"
          />
        </div>
      </div>
      <div className="container">
        <div className="logo">
          <img
            src={logo}
            alt="logo"
          />
        </div>
      </div>
    </nav>
    <main className="survey">
      <div className="container">
        <FeedbackProvideExternalForm
          onRedirect={() => window.location.replace('https://coca-colahellenic.com/')}
          id={id}
        />
      </div>
    </main>
    <Footer />
  </div>
);

export default FeedbackProvideExternalPage;

FeedbackProvideExternalPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
