import _ from 'lodash';

import {
  SURVEY_REPORT_FETCHED,
  SURVEY_REPORTS_FETCHED,
  SURVEY_REPORTS_TEAM_FETCHED,
  SURVEY_REPORT_PREVIEW_FETCHED,
} from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

const surveyReports = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_REPORTS_FETCHED: {
      const data = _.get(action.payload, 'data.surveys', state);

      return data ? _.keyBy(data, 'id') : state;
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const teamSurveyReports = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_REPORTS_TEAM_FETCHED: {
      const data = _.get(action.payload, 'data.surveys', state);

      return data ? _.keyBy(data, 'id') : state;
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const surveyReport = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_REPORT_PREVIEW_FETCHED:
    case SURVEY_REPORT_FETCHED: {
      return _.get(action.payload, 'data', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { surveyReports, teamSurveyReports, surveyReport };
