import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const AllPeers = ({
  t,
  allProviders,
}) => {
  if (!allProviders.length) {
    return <div />;
  }

  return (
    <div className="report-excluded-peers report-box card">
      <div className="card-header">
        <div className="report-box-title">
          {t('survey.label.invited_peers')}
        </div>
      </div>
      <hr />
      <div className="comment-box">
        {allProviders.join(', ')}
      </div>
    </div>
  );
};

export default withNamespaces()(AllPeers);

AllPeers.propTypes = {
  t: PropTypes.func.isRequired,
  allProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
};
