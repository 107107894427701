import _ from 'lodash';

import NOTIFICATION_CLOSED from './types';
import { RESET_PASSWORD_CREATED, RESET_PASSWORD_UPDATED } from '../reset-password/types';
import { SURVEY_CREATED } from '../survey/types';
import {
  SEND_SURVEY_REPORT,
  SEND_SURVEY_INITIAL_REPORT,
  SEND_TASK_REPORT,
  SEND_PROGRESS_REPORT,
  SEND_QUALITATIVE_REPORT,
  SEND_FULL_EXPORT_REPORT,
  SEND_SCORE_REPORT,
  SEND_PARTICIPATION_RATE_REPORT,
} from '../admin-report/types';

const initialState = {
  message: '',
  visible: false,
  type: 'success',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_CLOSED: {
      return {
        message: '',
        visible: false,
        type: 'success',
      };
    }
    case RESET_PASSWORD_CREATED: {
      return {
        message: 'Please follow the instructions from the email.',
        visible: true,
        type: 'success',
      };
    }
    case SURVEY_CREATED: {
      const status = _.get(action.payload, 'status', 500);
      const notifyTypes = [
        'upwards_feedback',
        'peer_feedback',
        'customer_feedback',
      ];
      const { type } = action.meta;

      if (notifyTypes.includes(type)) {
        return status === 201 ? {
          message: 'Your survey will be created shortly. Please check in a few minutes.',
          visible: true,
          type: 'success',
        } : {
          message: 'Failed to create survey.',
          visible: true,
          type: 'danger',
        };
      }

      return state;
    }
    case RESET_PASSWORD_UPDATED: {
      const status = _.get(action.payload, 'status', 500);

      return status === 204 ? {
        message: 'Password successfully changed.',
        visible: true,
        type: 'success',
      } : {
        message: 'Failed to change password.',
        visible: true,
        type: 'danger',
      };
    }
    case SEND_PROGRESS_REPORT:
    case SEND_QUALITATIVE_REPORT:
    case SEND_FULL_EXPORT_REPORT:
    case SEND_SURVEY_REPORT:
    case SEND_SURVEY_INITIAL_REPORT:
    case SEND_TASK_REPORT:
    case SEND_SCORE_REPORT:
    case SEND_PARTICIPATION_RATE_REPORT: {
      const status = _.get(action.payload, 'status', 500);

      return status === 201 ? {
        message: 'Notification reports sent successfully.',
        visible: true,
        type: 'success',
      } : {
        message: 'Something went wrong. Failed sending reports.',
        visible: true,
        type: 'danger',
      };
    }
    default: {
      return state;
    }
  }
};
