import React from 'react';
import PropTypes from 'prop-types';

import './input-field.css';

const InputField = ({
  meta: { touched, error, submitting },
  input,
  input: { name },
  label,
  type,
  placeholder,
  children,
  showLabel,
  showErrors,
}) => (
  <div className="input-field-component">
    <label
      className={`${showLabel ? 'visible' : ''}`}
      htmlFor={name}
    >
      {label}
    </label>
    <div className="input-field">
      <input
        className="form-control"
        {...input}
        id={name}
        disabled={submitting}
        type={type}
        placeholder={placeholder}
        autoComplete={type === 'password' ? 'current-password' : 'on'}
      />
      <span>{children}</span>
      <small className="input-field__error">
        {touched && error && showErrors ? error : '' }
      </small>
    </div>
  </div>
);

export default InputField;

InputField.propTypes = {
  meta: PropTypes.shape({
    submitting: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({ name: PropTypes.string }).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
};

InputField.defaultProps = {
  label: '',
  type: 'text',
  placeholder: null,
  children: null,
  showLabel: false,
  showErrors: true,
};
