import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import InputField from '../../components/InputField';
import SubmitField from '../../components/SubmitField';
import register from '../../redux-modules/register-user/actions';
import validators from '../../utils/validators';

class SignUpForm extends Component {
  handleSubmit(values) {
    const { t, register: registerAction, onSignUp } = this.props;

    return registerAction(
      values.email,
      values.password,
      values.name,
    ).then(
      onSignUp || (() => {}),
      (error) => {
        const code = _.get(error, 'response.data.code', 200);
        if (code === 422) {
          throw new SubmissionError({ email: t('user.error.email_already_exists') });
        }
      },
    );
  }

  render() {
    const { t, handleSubmit } = this.props;

    return (
      <div className="sign-up-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group">
            <Field
              name="email"
              type="email"
              showLabel
              label={t('user.label.email')}
              placeholder={t('user.placeholder.email')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="password"
              type="password"
              showLabel
              label={t('user.label.password')}
              placeholder={t('user.placeholder.password')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="passwordConfirm"
              type="password"
              showLabel
              label={t('user.label.password_confirm')}
              placeholder={t('user.placeholder.password_confirm')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="name"
              type="text"
              showLabel
              label={t('user.label.username')}
              placeholder={t('user.placeholder.username')}
              component={InputField}
            />
          </div>
          <div className="form-group text-center">
            <Field
              name="submit"
              label={t('user.action.register')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.email) || !validators.email(values.email)) {
    errors.email = t('user.error.email_required');
  }

  if (!validators.minLength(values.password, 8)) {
    errors.password = t('user.error.password_min_length');
  }

  if (!validators.equals(values.password, values.passwordConfirm)) {
    errors.passwordConfirm = t('user.error.password_do_not_match');
  }

  if (!validators.required(values.name)) {
    errors.name = t('user.error.username_required');
  }

  return errors;
};

const mapDispatchToProps = dispatch => bindActionCreators({ register }, dispatch);

export default withNamespaces()(reduxForm({
  validate,
  form: 'signUpForm',
})(connect(null, mapDispatchToProps)(SignUpForm)));

SignUpForm.propTypes = {
  t: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSignUp: PropTypes.func,
};

SignUpForm.defaultProps = {
  onSignUp: null,
};
