import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import ApprovalList from '../../containers/ApprovalList';

import teamIcon from '../../assets/images/team-icon.svg';

const ApprovalPage = ({ t, callToActions }) => {
  const loading = callToActions === null;
  const listCount = loading
    ? 0
    : callToActions.filter(notification => notification.type === 'customer_list_created' || notification.type === 'peer_list_created').length;

  const renderList = (
    <div className={`render-list ${loading || listCount === 0 ? 'd-none' : ''}`}>
      <div className="content-box accordion">
        <div className="box-list">
          <ApprovalList />
        </div>
      </div>
    </div>
  );
  const renderLoading = (
    <div
      style={{ padding: '15px' }}
      className={`content-box accordion ${!loading && listCount === 0 ? '' : 'd-none'}`}
    >
      <div className="box-list">
        <p align="center">
          <strong>
            {t('survey.label.no_approval')}
          </strong>
        </p>
        <p align="center">
          {t('survey.label.no_approval_desc')}
        </p>
      </div>
    </div>
  );

  return (
    <main className="approval-list-page list">
      <div className="container">
        <div className="content-title team">
          <div className="row">
            <div className="col-2">
              <Link to="/#collapseThree">
                <img
                  src={teamIcon}
                  alt="team"
                />
              </Link>
            </div>
            <div className="col my-auto">
              <Link
                className="without-underline"
                to="/#collapseThree"
              >
                <span className="box-title">
                  {t('menu.label.team_feedback')}
                </span>
              </Link>
            </div>
            <div className="col my-auto">
              <span className="box-subtitle">
                {t('menu.label.approvals')}
              </span>
            </div>
          </div>
        </div>
        {renderList}
        {renderLoading}
      </div>
    </main>
  );
};

const mapStateToProps = ({ callToActions }) => ({ callToActions });

export default connect(mapStateToProps)(withNamespaces()(ApprovalPage));

ApprovalPage.propTypes = {
  t: PropTypes.func.isRequired,
  callToActions: PropTypes.arrayOf(PropTypes.shape({})),
};

ApprovalPage.defaultProps = {
  callToActions: null,
};
