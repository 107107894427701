import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import SelectField from '../../../components/SelectField';
import SubmitField from '../../../components/SubmitField';
import InputField from '../../../components/InputField';
import fetchBusinessUnit from '../../../redux-modules/business-unit/actions';
import {
  createSurveyExcludeRule,
  fetchSurveyExcludeRules,
  updateSurveyExcludeRule,
} from '../../../redux-modules/survey-exclude-rule/actions';
import validators from '../../../utils/validators';

class AdminSurveyExcludeRuleForm extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { fetchBusinessUnit: fetchBusinessUnitAction } = this.props;

    fetchBusinessUnitAction();
  }

  handleSubmit(values) {
    const {
      type,
      createSurveyExcludeRule: createSurveyExcludeRuleAction,
      updateSurveyExcludeRule: updateSurveyExcludeRuleAction,
      fetchSurveyExcludeRules: fetchSurveyExcludeRulesAction,
      onSubmit,
    } = this.props;

    const submitAction = values.id
      ? updateSurveyExcludeRuleAction(
        values.id,
        values.type,
        values.businessUnit,
        values.workforceSegment,
        values.personnelSubarea,
        values.countryCode,
        values.companyCode,
        values.email,
        values.locationId,
        values.positionName,
        values.standardGroupJobNumber,
        values.originalHireDate,
      ) : createSurveyExcludeRuleAction(
        values.type,
        values.businessUnit,
        values.workforceSegment,
        values.personnelSubarea,
        values.countryCode,
        values.companyCode,
        values.email,
        values.locationId,
        values.positionName,
        values.standardGroupJobNumber,
        values.originalHireDate,
      );

    submitAction.then(
      () => {
        fetchSurveyExcludeRulesAction(type);
        onSubmit();
      },
      () => {},
    );
  }

  handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  render() {
    const {
      t,
      businessUnit,
      handleSubmit,
    } = this.props;

    const businessUnitOptions = (businessUnit || []).map(b => ({
      label: b.name,
      value: b.id,
    }));

    return (
      <div className="admin-create-survey-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <Field
            name="id"
            type="hidden"
            component="input"
          />
          <Field
            name="type"
            showErrors={false}
            label={t('survey.label.type')}
            placeholder={t('survey.placeholder.any_type')}
            options={[
              {
                label: t('survey.type.upwards_feedback'),
                value: 'upwards_feedback',
              },
              {
                label: t('survey.type.peer_feedback'),
                value: 'peer_feedback',
              },
            ]}
            component={SelectField}
          />
          <div className="form-group">
            <Field
              name="email"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.email')}
              placeholder={t('survey_exclude_rule.label.email')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="countryCode"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.country_code')}
              placeholder={t('survey_exclude_rule.label.country_code')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="personnelSubarea"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.personnel_subarea')}
              placeholder={t('survey_exclude_rule.label.personnel_subarea')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="companyCode"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.company_code')}
              placeholder={t('survey_exclude_rule.label.company_code')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="workforceSegment"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.workforce_segment')}
              placeholder={t('survey_exclude_rule.label.workforce_segment')}
              component={InputField}
            />
          </div>
          <Field
            name="businessUnit"
            showErrors={false}
            hasEmpty
            label={t('survey_exclude_rule.placeholder.business_unit')}
            placeholder={t('survey_exclude_rule.placeholder.business_unit')}
            options={businessUnitOptions}
            component={SelectField}
          />
          <div className="form-group">
            <Field
              name="locationId"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.location_id')}
              placeholder={t('survey_exclude_rule.label.location_id')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="positionName"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.position_name')}
              placeholder={t('survey_exclude_rule.label.position_name')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="standardGroupJobNumber"
              showErrors={false}
              hasEmpty
              label={t('survey_exclude_rule.label.standard_group_job_number')}
              placeholder={t('survey_exclude_rule.label.standard_group_job_number')}
              component={InputField}
            />
          </div>
          <div className="form-group">
            <Field
              name="originalHireDate"
              label={t('survey_exclude_rule.label.original_hire_date')}
              placeholder={t('survey_exclude_rule.label.original_hire_date')}
              hasEmpty
              options={[
                { label: '30 days', value: '30 days' },
                { label: '60 days', value: '60 days' },
                { label: '90 days', value: '90 days' },
              ]}
              component={SelectField}
            />
          </div>
          <div className="form-group text-center mb-2 mt-4">
            <button
              className="btn standard gray"
              type="button"
              onClick={this.handleCancel}
            >
              {t('survey_exclude_rule.action.cancel')}
            </button>
            <Field
              inline
              name="submit"
              label={t('survey_exclude_rule.action.create')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('adminSurveyExcludeRulesFilters');

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.type)) {
    errors.type = t('survey.error.type_required');
  }

  return errors;
};

const mapStateToProps = (state, { surveyExcludeRule }) => ({
  businessUnit: state.businessUnit,
  initialValues: surveyExcludeRule ? { ...surveyExcludeRule } : null,
  type: selector(state, 'type'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchBusinessUnit,
  fetchSurveyExcludeRules,
  createSurveyExcludeRule,
  updateSurveyExcludeRule,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  validate,
  form: 'adminSurveyExcludeRuleForm',
  enableReinitialize: true,
})(AdminSurveyExcludeRuleForm)));

AdminSurveyExcludeRuleForm.propTypes = {
  t: PropTypes.func.isRequired,
  businessUnit: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({}),
  type: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetchBusinessUnit: PropTypes.func.isRequired,
  fetchSurveyExcludeRules: PropTypes.func.isRequired,
  createSurveyExcludeRule: PropTypes.func.isRequired,
  updateSurveyExcludeRule: PropTypes.func.isRequired,
};

AdminSurveyExcludeRuleForm.defaultProps = {
  businessUnit: null,
  type: null,
  initialValues: null,
};
