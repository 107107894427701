import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import NotificationItem from '../../components/NotificationItem';
import fetchCallToAction from '../../redux-modules/call-to-action/actions';

class CustomerPeerNotificationList extends Component {
  componentDidMount() {
    const { token, callToActions, fetchCallToAction: fetchCallToActionAction } = this.props;
    if (!callToActions || callToActions.length === 0) {
      fetchCallToActionAction(token);
    }
  }

  render() {
    const { callToActions, filterUserSurvey } = this.props;
    const { timeframe, type } = filterUserSurvey;

    const currentYear = moment().year();
    const currentQuarter = moment().quarter();
    const currentMonth = moment().month();

    const data = (callToActions || [])
      .filter(notification => (notification.type === 'select_customers'
        || notification.type === 'select_peers'
        || notification.type === 'peers_selected'
        || notification.type === 'complete_self_reflection'
      )).filter((notification) => {
        const endDate = moment(notification.endDate);

        if (timeframe && timeframe === 'this_month' && (endDate.month() !== currentMonth || endDate.year() !== currentYear)) {
          return false;
        }
        if (timeframe && timeframe === 'this_quarter' && (endDate.quarter() !== currentQuarter || endDate.year() !== currentYear)) {
          return false;
        }
        if (timeframe && timeframe === 'this_year' && endDate.year() !== currentYear) {
          return false;
        }
        if (type && notification.surveyType !== type) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        const endDateA = moment(a.endDate);
        const endDateB = moment(b.endDate);
        if (endDateA.isBefore(endDateB)) {
          return -1;
        }
        if (endDateA.isAfter(endDateB)) {
          return 1;
        }
        return 0;
      })
      .map(notification => (
        <div
          className="sub-accordion-item"
          key={notification.url}
        >
          <NotificationItem
            url={notification.url}
            notificationType={notification.type}
            quarter={notification.quarter}
            year={notification.year}
            title={notification.surveyTitle}
            type={notification.surveyType}
            name={notification.fullName}
            endDate={moment(notification.endDate)}
          />
        </div>
      ));

    return (
      <div className="customer-peer-notification-list">
        {data}
      </div>
    );
  }
}

const mapStateToProps = ({ token, callToActions, filterUserSurvey }) => ({
  token,
  callToActions,
  filterUserSurvey,
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCallToAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPeerNotificationList);

CustomerPeerNotificationList.propTypes = {
  token: PropTypes.string.isRequired,
  callToActions: PropTypes.arrayOf(PropTypes.shape({})),
  fetchCallToAction: PropTypes.func.isRequired,
  filterUserSurvey: PropTypes.shape({
    type: PropTypes.string,
    timeframe: PropTypes.string,
  }),
};

CustomerPeerNotificationList.defaultProps = {
  callToActions: null,
  filterUserSurvey: null,
};
