import _ from 'lodash';

import {
  SURVEY_STATISTIC_FETCHED,
  SURVEY_STATISTIC_TEAM_FETCHED,
  SURVEY_STATISTIC_PARTNER_FETCHED,
} from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

const statistic = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_STATISTIC_FETCHED: {
      return _.get(action.payload, 'data', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const teamStatistic = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_STATISTIC_TEAM_FETCHED:
    case SURVEY_STATISTIC_PARTNER_FETCHED: {
      return _.get(action.payload, 'data', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { statistic, teamStatistic };
