import axios from 'axios';

import CUSTOMER_EXTERNAL_FETCHED from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchCustomerExternal = (id) => {
  const url = `${REST_API_URL}v1/external-customer/${id}`;
  const request = axios.get(url);

  return {
    type: CUSTOMER_EXTERNAL_FETCHED,
    payload: request,
  };
};

export default fetchCustomerExternal;
