import React from 'react';
import PropTypes from 'prop-types';

import FeedbackProvideForm from '../../containers/FeedbackProvideForm';

const FeedbackProvidePage = ({ history, match: { params: { id } } }) => (
  <main className="feedback-provide-page survey">
    <div className="container">
      <FeedbackProvideForm
        onRedirect={() => history.push('/#collapseFour')}
        id={id}
      />
    </div>
  </main>
);

export default FeedbackProvidePage;

FeedbackProvidePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
