import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import SurveyStatisticUser from '../../containers/SurveyStatisticUser';

import receiveIcon from '../../assets/images/rec-icon.svg';

const StatisticPage = ({ t }) => (
  <main className="statistic-page">
    <div className="container">
      <div className="content-title reciving">
        <div className="row">
          <div className="col-2">
            <Link to="/#collapseOne">
              <img
                src={receiveIcon}
                alt="receiving-feedback"
              />
            </Link>
          </div>
          <div className="col my-auto">
            <Link
              className="without-underline"
              to="/#collapseOne"
            >
              <span className="box-title">
                {t('menu.label.receiving_feedback')}
              </span>
            </Link>
          </div>
          <div className="col my-auto">
            <span className="box-subtitle">
              {t('menu.label.compare_with_others')}
            </span>
          </div>
        </div>
      </div>
      <div className="content-box accordion">
        <div className="box-list">
          <SurveyStatisticUser />
        </div>
      </div>
    </div>
  </main>
);

export default withNamespaces()(StatisticPage);

StatisticPage.propTypes = {
  t: PropTypes.func.isRequired,
};
