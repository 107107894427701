import axios from 'axios';

import {
  SURVEY_FETCHED,
  SURVEY_LAST_FETCHED,
  SURVEYS_FETCHED,
  SURVEYS_CLOSED_FETCHED,
  SURVEYS_TEAM_FETCHED,
  SURVEY_CREATED,
  SURVEY_REMOVED,
  SURVEY_UPDATED,
  SURVEY_SELF_ASSESSMENT,
  SURVEY_REMINDER,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchSurvey = (token, id) => {
  const url = `${REST_API_URL}v1/survey/${id}`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEY_FETCHED,
    payload: request,
  };
};

const fetchLastSurvey = (token, type) => {
  const url = `${REST_API_URL}v1/survey/last`;
  const request = axios.post(
    url,
    {
      type,
    },
  );

  return {
    type: SURVEY_LAST_FETCHED,
    payload: request,
  };
};


const fetchSurveys = (token, status, type) => {
  const url = `${REST_API_URL}v1/survey`;
  const request = axios.get(
    url,
    {
      params: {
        status,
        type,
      },
    },
  );

  return {
    type: SURVEYS_FETCHED,
    payload: request,
  };
};

const fetchClosedSurveys = () => {
  const url = `${REST_API_URL}v1/survey/closed`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEYS_CLOSED_FETCHED,
    payload: request,
  };
};

const fetchTeamSurveys = () => {
  const url = `${REST_API_URL}v1/survey/team`;
  const request = axios.get(
    url,
    {
      params: {
        status: 'active',
      },
    },
  );

  return {
    type: SURVEYS_TEAM_FETCHED,
    payload: request,
  };
};

const createSurvey = (
  token,
  quarter,
  year,
  title,
  type,
  providers,
  questions,
  workforceSegment,
  businessUnit,
  launchDate,
  endDate,
  visibility,
  targets,
) => {
  const url = `${REST_API_URL}v1/survey`;
  const request = axios.post(
    url,
    {
      quarter,
      year,
      title,
      type,
      providers,
      questions,
      workforceSegment,
      businessUnit,
      launchDate,
      endDate,
      visibility,
      targets,
    },
  );

  return {
    type: SURVEY_CREATED,
    payload: request,
    meta: {
      type,
    },
  };
};

const updateSurvey = (
  token,
  id,
  providers,
  type,
  customQuestion,
) => {
  const url = `${REST_API_URL}v1/survey/${id}`;
  const request = axios.put(
    url,
    {
      providers,
      type,
      customQuestion,
    },
  );

  return {
    type: SURVEY_UPDATED,
    payload: request,
  };
};

const removeSurvey = (token, id) => {
  const url = `${REST_API_URL}v1/survey/${id}`;
  const request = axios.delete(
    url,
  );

  return {
    type: SURVEY_REMOVED,
    payload: request,
    meta: { id },
  };
};

const createSelfAssessment = (token, id, answers) => {
  const url = `${REST_API_URL}v1/survey/${id}/self-assessment`;
  const request = axios.post(
    url,
    {
      answers,
    },
  );

  return {
    type: SURVEY_SELF_ASSESSMENT,
    payload: request,
  };
};

const sendReminder = (token, id, message) => {
  const url = `${REST_API_URL}v1/survey/${id}/email-reminder`;
  const request = axios.post(
    url,
    {
      message,
    },
  );

  return {
    type: SURVEY_REMINDER,
    payload: request,
  };
};

export {
  fetchSurvey,
  fetchLastSurvey,
  fetchSurveys,
  fetchClosedSurveys,
  fetchTeamSurveys,
  createSurvey,
  removeSurvey,
  updateSurvey,
  createSelfAssessment,
  sendReminder,
};
