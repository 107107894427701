import axios from 'axios';

import { FEEDBACK_EXTERNAL_FETCHED, FEEDBACK_EXTERNAL_UPDATED } from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchFeedbackExternal = (id) => {
  const url = `${REST_API_URL}v1/feedback-external/${id}`;
  const request = axios.get(url);

  return {
    type: FEEDBACK_EXTERNAL_FETCHED,
    payload: request,
  };
};

const updateFeedbackExternal = (id, answers) => {
  const url = `${REST_API_URL}v1/feedback-external/${id}`;
  const request = axios.put(
    url,
    {
      answers,
    },
  );

  return {
    type: FEEDBACK_EXTERNAL_UPDATED,
    payload: request,
    meta: { id },
  };
};

export { fetchFeedbackExternal, updateFeedbackExternal };
