import _ from 'lodash';

import {
  SURVEY_FETCHED,
  SURVEY_LAST_FETCHED,
  SURVEYS_FETCHED,
  SURVEYS_CLOSED_FETCHED,
  SURVEYS_TEAM_FETCHED,
} from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

const surveys = (state = initialState, action) => {
  switch (action.type) {
    case SURVEYS_FETCHED: {
      const data = _.get(action.payload, 'data.surveys', null);

      return data ? _.keyBy(data, 'id') : state;
    }
    case SURVEY_FETCHED: {
      const survey = _.get(action.payload, 'data', null);

      if (!survey) {
        return state;
      }

      const newSurvey = {};
      newSurvey[survey.id] = survey;

      return { ...state, ...newSurvey };
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const lastSurvey = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_LAST_FETCHED: {
      return _.get(action.payload, 'data', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const closedSurveys = (state = initialState, action) => {
  switch (action.type) {
    case SURVEYS_CLOSED_FETCHED: {
      const data = _.get(action.payload, 'data.surveys', null);

      return data ? _.keyBy(data, 'id') : state;
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const teamSurveys = (state = initialState, action) => {
  switch (action.type) {
    case SURVEYS_TEAM_FETCHED: {
      const data = _.get(action.payload, 'data.surveys', null);

      return data ? _.keyBy(data, 'id') : state;
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const totalSurveys = (state = initialState, action) => {
  switch (action.type) {
    case SURVEYS_FETCHED: {
      return _.get(action.payload, 'data.totalItems', state);
    }

    default: {
      return state;
    }
  }
};

const totalTeamSurveys = (state = initialState, action) => {
  switch (action.type) {
    case SURVEYS_TEAM_FETCHED: {
      return _.get(action.payload, 'data.totalItems', state);
    }

    default: {
      return state;
    }
  }
};

export {
  surveys,
  lastSurvey,
  closedSurveys,
  teamSurveys,
  totalSurveys,
  totalTeamSurveys,
};
