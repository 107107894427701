/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import FeedbackPreviewItem from '../../components/FeedbackPreviewItem';
import FeedbackOverallResults from '../../components/FeedbackOverallResults';
import CantProvideComments from '../../components/CantProvideComments';
import { fetchFeedbackList, fetchFeedback } from '../../redux-modules/feedback/actions';

import './feedback-preview.css';

class FeedbackPreview extends Component {
  static handleExport() {
    window.print();
  }

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const {
      token,
      id,
      fetchFeedbackList: fetchFeedbackListAction,
      fetchFeedback: fetchFeedbackAction,
    } = this.props;
    fetchFeedbackListAction(token);
    fetchFeedbackAction(token, id);

    document.body.classList.add('reports');
  }

  componentWillUnmount() {
    document.body.classList.remove('reports');
  }

  handleClose() {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
      return;
    }
    history.push('/');
  }

  render() {
    const {
      id,
      feedbackList,
      feedback: feedbackDetails,
      t,
    } = this.props;

    const feedback = _.get(feedbackList, id, null);

    if (!feedback) {
      return <div />;
    }

    const cantProvideComments = _.values(feedback.answers).filter(answer => (answer.isComment));
    const overallRating = _.groupBy(feedback.answers, 'rating');
    const questions = _.values(feedback.questions).map((question, index) => {
      const answers = _.keyBy(feedback.answers, 'question');
      const answer = _.get(answers, question.id);
      const hasAnswer = answer && (answer.rating > 0 || answer.comment);

      if (question.optional && !hasAnswer) {
        return null;
      }

      if (answer == null) {
        return (
          <div key={question.id} />
        );
      }

      return (
        <div key={question.id}>
          <FeedbackPreviewItem
            index={index}
            question={question.text}
            scale={question.scale}
            answer={answer.comment}
            rating={answer.rating}
          />
        </div>
      );
    });

    return (
      <main className="feedback-preview reports">
        <div className="container">
          <div className="row heading">
            <div className="report-close">
              <span
                className="close-menu"
                role="button"
                tabIndex={0}
                onClick={this.handleClose}
                onKeyPress={this.handleClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
            </div>
            <div className="col my-auto">
              <div className="report-title">
                {feedback.year}
                {' '}
                Q
                {feedback.quarter}
                {' '}
                {feedback.title ? feedback.title : ''}
                {' '}
                {t(`survey.type.${feedback.surveyType}`)}
                {' '}
                {t('survey.label.for')}
                {' '}
                {feedback.fullName}
              </div>
              {feedbackDetails && feedbackDetails.initiator && (
                <small>
                  {t('survey.label.scrum_master').replace(/<scrum master>/g, feedbackDetails.initiator)}
                </small>
              )}
            </div>
            <div className="report-export">
              <button
                type="button"
                className="btn standard gray"
                onClick={FeedbackPreview.handleExport}
              >
                {t('survey.label.export')}
              </button>
            </div>
          </div>
          <hr />
          <FeedbackOverallResults
            rating1={_.get(overallRating, 1, []).length}
            rating2={_.get(overallRating, 2, []).length}
            rating3={_.get(overallRating, 3, []).length}
            rating4={_.get(overallRating, 4, []).length}
            rating5={_.get(overallRating, 5, []).length}
          />
          <div className="report-accordion feed-back-list">
            {questions}
            <CantProvideComments
              comments={cantProvideComments}
            />
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ token, feedbackList, feedback }) => ({ token, feedbackList, feedback });

const mapDispatchToProps = dispatch => bindActionCreators(
  { fetchFeedbackList, fetchFeedback },
  dispatch,
);

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedbackPreview)),
);

FeedbackPreview.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  feedbackList: PropTypes.shape({}),
  feedback: PropTypes.shape({}),
  fetchFeedbackList: PropTypes.func.isRequired,
  fetchFeedback: PropTypes.func.isRequired,
};

FeedbackPreview.defaultProps = {
  feedbackList: null,
  feedback: null,
};
