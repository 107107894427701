import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import UserSurveyFilters from '../../containers/UserSurveyFilters';
import CustomerPeerNotificationList from '../../containers/CustomerPeerNotificationList';
import SurveyList from '../../containers/SurveyList';
import SurveyClosedList from '../../containers/SurveyClosedList';
import SurveyReportList from '../../containers/SurveyReportList';

import './survey-page.css';

import receiveIcon from '../../assets/images/rec-icon.svg';

const SurveysPage = ({
  t,
  surveys,
  callToActions,
  surveyReports,
  closedSurveys,
}) => {
  const loading = (surveys === null
    || callToActions === null
    || surveyReports === null
    || closedSurveys === null
  );
  const listCount = loading
    ? 0
    : _.values(surveys).length
      + callToActions.filter(notification => (notification.type === 'select_customers'
        || notification.type === 'select_peers'
        || notification.type === 'peers_selected'
        || notification.type === 'complete_self_reflection'
      )).length
      + _.values(surveyReports).length
      + _.values(closedSurveys).length;

  const renderList = (
    <div className={`render-list ${loading || listCount === 0 ? 'd-none' : ''}`}>
      <UserSurveyFilters />
      <div className="content-box accordion">
        <div className="box-list">
          <CustomerPeerNotificationList />
          <SurveyList />
          <SurveyReportList />
          <SurveyClosedList />
        </div>
      </div>
    </div>
  );
  const renderLoading = (
    <div
      style={{ padding: '15px' }}
      className={`content-box accordion ${!loading && listCount === 0 ? '' : 'd-none'}`}
    >
      <div className="box-list">
        <p align="center">
          <strong>
            {t('survey.label.no_feedback')}
          </strong>
        </p>
        <p align="center">
          {t('survey.label.no_feedback_desc')}
        </p>
        <div className="form-group text-center">
          <Link
            className="btn standard"
            to="/add-survey/freestyle_feedback"
          >
            {t('survey.action.no_feedback')}
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <main className="surveys-page list">
      <div className="container">
        <div className="content-title reciving">
          <div className="row">
            <div className="col-2">
              <Link to="/#collapseOne">
                <img
                  src={receiveIcon}
                  alt="receiving-feedback"
                />
              </Link>
            </div>
            <div className="col my-auto">
              <Link
                className="without-underline"
                to="/#collapseOne"
              >
                <span className="box-title">
                  {t('menu.label.receiving_feedback')}
                </span>
              </Link>
            </div>
            <div className="col my-auto">
              <span className="box-subtitle">
                {t('menu.label.feedback_to_me')}
              </span>
            </div>
          </div>
        </div>
        {renderList}
        {renderLoading}
      </div>
    </main>
  );
};

const mapStateToProps = ({
  surveys,
  callToActions,
  surveyReports,
  closedSurveys,
}) => ({
  surveys,
  callToActions,
  surveyReports,
  closedSurveys,
});

export default connect(mapStateToProps)(withNamespaces()(SurveysPage));

SurveysPage.propTypes = {
  t: PropTypes.func.isRequired,
  surveys: PropTypes.shape({}),
  callToActions: PropTypes.arrayOf(PropTypes.shape({})),
  surveyReports: PropTypes.shape({}),
  closedSurveys: PropTypes.shape({}),
};

SurveysPage.defaultProps = {
  surveys: null,
  callToActions: null,
  surveyReports: null,
  closedSurveys: null,
};
