import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import DatePickerField from '../../../components/DatePickerField';
import SubmitField from '../../../components/SubmitField';
import { fetchSurveyAdmin, updateSurveysAdmin } from '../../../redux-modules/survey-admin/actions';
import validators from '../../../utils/validators';

class AdminUpdateSurveyForm extends Component {
  componentDidMount() {
    const {
      token,
      id,
      fetchSurveyAdmin: fetchSurveyAdminAction,
    } = this.props;

    fetchSurveyAdminAction(token, id);
  }

  handleSubmit(values) {
    const {
      token,
      id,
      onUpdate,
      updateSurveysAdmin: updateSurveysAdminAction,
    } = this.props;

    const launchDate = moment.utc().set({
      year: values.launchDate.year,
      month: parseInt(values.launchDate.month, 10) - 1,
      date: values.launchDate.day,
      hour: 2,
      minute: 0,
      second: 0,
      millisecond: 0,
    }).format();

    const endDate = moment.utc().set({
      year: values.endDate.year,
      month: parseInt(values.endDate.month, 10) - 1,
      date: values.endDate.day,
      hour: 20,
      minute: 0,
      second: 0,
      millisecond: 0,
    }).format();

    return updateSurveysAdminAction(token, id, launchDate, endDate).then(
      () => onUpdate(),
      () => {},
    );
  }

  render() {
    const {
      t,
      id,
      handleSubmit,
      surveysAdmin,
    } = this.props;

    const survey = _.get(surveysAdmin, id, null);

    if (!survey) {
      return <div />;
    }

    return (
      <div className="admin-update-survey-form">
        <h3>
          {survey.year}
          {' Q'}
          {survey.quarter}
          {' '}
          {t(`survey.type.${survey.type}`)}
        </h3>
        <hr />
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group">
            <div className="form-group">
              <Field
                name="launchDate"
                label={t('survey.label.launch_date')}
                disabled={!survey.isDeletable}
                component={DatePickerField}
              />
            </div>
            <Field
              name="endDate"
              label={t('survey.label.end_date')}
              component={DatePickerField}
            />
          </div>
          <div className="form-group text-center row">
            <div className="col">
              <Link
                className="btn standard gray float-right"
                to="/"
              >
                {t('survey.action.back')}
              </Link>
            </div>
            <div className="col text-left">
              <Field
                name="submit"
                label={t('survey.action.update')}
                component={SubmitField}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!(values.endDate && values.endDate.day && values.endDate.month && values.endDate.year)
    || (values.endDate
      && !validators.date(values.endDate.month, values.endDate.day, values.endDate.year))
    || (values.endDate.year < 1900)
  ) {
    errors.endDate = t('survey.error.date');
  }

  if (!(values.launchDate
    && values.launchDate.day && values.launchDate.month && values.launchDate.year)
    || (values.launchDate
      && !validators.date(values.launchDate.month, values.launchDate.day, values.launchDate.year))
    || (values.launchDate.year < 1900)
  ) {
    errors.launchDate = t('survey.error.date');
  }

  return errors;
};

function mapStateToProps({ token, surveysAdmin }, { id }) {
  const survey = _.get(surveysAdmin, id, null);

  let initialValues;
  if (survey) {
    const endDate = moment.utc(survey.endDate);
    const launchDate = moment.utc(survey.launchDate);

    initialValues = {
      endDate: {
        day: parseInt(endDate.format('D'), 10),
        month: endDate.format('M'),
        year: parseInt(endDate.format('YYYY'), 10),
      },
      launchDate: {
        day: parseInt(launchDate.format('D'), 10),
        month: launchDate.format('M'),
        year: parseInt(launchDate.format('YYYY'), 10),
      },
    };
  }

  return {
    token,
    surveysAdmin,
    initialValues,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveyAdmin,
  updateSurveysAdmin,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    validate,
    form: 'adminUpdateSurveyForm',
  })(AdminUpdateSurveyForm),
));

AdminUpdateSurveyForm.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  surveysAdmin: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  fetchSurveyAdmin: PropTypes.func.isRequired,
  updateSurveysAdmin: PropTypes.func.isRequired,
};

AdminUpdateSurveyForm.defaultProps = {
  surveysAdmin: null,
};
