import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Footer from '../../components/Footer';
import ActivateForm from '../../containers/ActivateForm';

import cchLogo from '../../assets/images/cch-logo.svg';
import logo from '../../assets/images/logo.svg';

const ActivatePage = ({ t, match: { params: { id } } }) => (
  <div className="activate-page">
    <nav className="navbar navbar-expand-lg navbar-gray static-top">
      <div className="mobile-header">
        <div className="mobile-header-logo">
          <img
            src={cchLogo}
            alt="logo"
          />
        </div>
      </div>
      <div className="container">
        <div className="logo">
          <img
            src={logo}
            alt="logo"
          />
        </div>
      </div>
    </nav>
    <main className="login">
      <div className="container">
        <div className="content-box">
          <h2 align="center">
            {t('user.notification.account_activation_success')}
          </h2>
          <ActivateForm id={id} />
        </div>
      </div>
    </main>
    <Footer />
  </div>
);

export default withNamespaces()(ActivatePage);

ActivatePage.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
