import _ from 'lodash';

import { FEEDBACK_EXTERNAL_FETCHED } from './types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK_EXTERNAL_FETCHED: {
      return _.get(action.payload, 'data', null);
    }
    default: {
      return state;
    }
  }
};
