import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const SurveyItem = ({
  t,
  id,
  quarter,
  year,
  progress,
  title,
  type,
  name,
  endDate,
  team,
  closed,
}) => (
  <div className="survey-item row">
    <div className="col">
      <div className="sub-acordion-title">
        {team}
        {year}
        {' '}
        Q
        {quarter}
        {' '}
        {title}
        {' '}
        {t(`survey.type.${type}`)}
        {' '}
        {t('survey.label.for')}
        {' '}
        {name}
      </div>
      <div className="sub-acordion-response">
        {t('survey.label.responses')}
        {': '}
        {progress.count}
        {'/'}
        {progress.total}
      </div>
      <div className="sub-acordion-date">
        {closed ? t('survey.date.closed') : t('survey.date.due')}
        {': '}
        {endDate.format(t('date_format'))}
      </div>
    </div>
    <div className="col text-right my-auto">
      {!team && (progress.count < progress.total) && !closed && (
        <Link
          className="btn standard gray"
          to={`/survey/${id}/reminder`}
        >
          {t('survey.action.send_reminder')}
        </Link>
      )
      }
      {type !== 'pod_feedback' && (
        (progress.count < 3 && type !== 'freestyle_feedback') || progress.count === 0
          ? (
            <Link
              className="btn standard no-action"
              to={`/reports/preview/${id}`}
            >
              {t('survey.label.too_few_responses')}
            </Link>
          )
          : (
            <Link
              className="btn standard gray"
              to={`/reports/preview/${id}`}
            >
              {t('survey.action.preview_report')}
            </Link>
          )
      )}
    </div>
  </div>
);

export default withNamespaces()(SurveyItem);

SurveyItem.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  quarter: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  progress: PropTypes.shape({
    total: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  endDate: PropTypes.shape({}).isRequired,
  team: PropTypes.bool,
  closed: PropTypes.bool,
};

SurveyItem.defaultProps = {
  title: '',
  team: false,
  closed: false,
};
