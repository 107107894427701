import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import SurveyStatisticTeam from '../../containers/SurveyStatisticTeam';

import teamIcon from '../../assets/images/team-icon.svg';

const StatisticTeamPage = ({ t }) => (
  <main className="statistic-team-page">
    <div className="container">
      <div className="content-title team">
        <div className="row">
          <div className="col-2">
            <Link to="/#collapseThree">
              <img
                src={teamIcon}
                alt="team"
              />
            </Link>
          </div>
          <div className="col my-auto">
            <Link
              className="without-underline"
              to="/#collapseThree"
            >
              <span className="box-title">
                {t('menu.label.team_feedback')}
              </span>
            </Link>
          </div>
          <div className="col my-auto">
            <span className="box-subtitle">
              {t('menu.label.result_for_my_organization')}
            </span>
          </div>
        </div>
      </div>
      <div className="content-box accordion">
        <div className="box-list">
          <SurveyStatisticTeam />
        </div>
      </div>
    </div>
  </main>
);

export default withNamespaces()(StatisticTeamPage);

StatisticTeamPage.propTypes = {
  t: PropTypes.func.isRequired,
};
