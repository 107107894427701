import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';

import SubmitField from '../../components/SubmitField';
import { sendConfirmationEmail } from '../../redux-modules/activate-user/actions';

class ConfirmationForm extends Component {
  handleSubmit() {
    const { registeredUser, sendConfirmationEmail: sendConfirmationEmailAction } = this.props;

    return sendConfirmationEmailAction(registeredUser.id);
  }

  // eslint-disable-next-line class-methods-use-this
  renderLoading() {
    return (
      <div className="confirmation-form" />
    );
  }

  renderSuccess() {
    const { t, registeredUser, handleSubmit } = this.props;

    return (
      <div className="confirmation-form">
        <div>
          {t('user.notification.email_sent')}
          {' '}
          <span>
            {registeredUser.email}
          </span>
        </div>
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group text-center">
            <Field
              name="submit"
              label={t('user.action.send')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { registeredUser } = this.props;

    if (!registeredUser) {
      return this.renderLoading();
    }

    return this.renderSuccess();
  }
}

const mapStateToProps = ({ registeredUser }) => ({ registeredUser });

const mapDispatchToProps = dispatch => bindActionCreators({ sendConfirmationEmail }, dispatch);

export default reduxForm({
  form: 'confirmationForm',
})(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ConfirmationForm)));

ConfirmationForm.propTypes = {
  t: PropTypes.func.isRequired,
  registeredUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  sendConfirmationEmail: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

ConfirmationForm.defaultProps = {
  registeredUser: null,
};
