import _ from 'lodash';

import BUSINESS_UNIT_FETCHED from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_UNIT_FETCHED: {
      return _.get(action.payload, 'data.businessUnit', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
