import axios from 'axios';

import {
  SEND_PROGRESS_REPORT,
  SEND_QUALITATIVE_REPORT,
  SEND_FULL_EXPORT_REPORT,
  SEND_SCORE_REPORT,
  SEND_SURVEY_REPORT,
  SEND_SURVEY_INITIAL_REPORT,
  SEND_TASK_REPORT,
  SEND_PARTICIPATION_RATE_REPORT,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const sendProgressReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/progress-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_PROGRESS_REPORT,
    payload: request,
  };
};

const sendQualitativeReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/qualitative-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_QUALITATIVE_REPORT,
    payload: request,
  };
};

const sendFullExportReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/full-export-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_FULL_EXPORT_REPORT,
    payload: request,
  };
};

const sendSurveyReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/survey-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_SURVEY_REPORT,
    payload: request,
  };
};

const sendSurveyInitialReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/survey-initial-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_SURVEY_INITIAL_REPORT,
    payload: request,
  };
};

const sendTaskReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/task-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_TASK_REPORT,
    payload: request,
  };
};

const sendScoreReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/score-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_SCORE_REPORT,
    payload: request,
  };
};

const sendParticipationRateReport = (surveyType, year, quarter) => {
  const url = `${REST_API_URL}v1/admin-report/participation-rate-report`;
  const request = axios.post(
    url,
    {
      surveyType,
      year,
      quarter,
    },
  );

  return {
    type: SEND_PARTICIPATION_RATE_REPORT,
    payload: request,
  };
};

export {
  sendProgressReport,
  sendQualitativeReport,
  sendFullExportReport,
  sendSurveyReport,
  sendSurveyInitialReport,
  sendTaskReport,
  sendScoreReport,
  sendParticipationRateReport,
};
