import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ReactMarkdown from 'react-markdown';
import { AutoSizer, List } from 'react-virtualized';
import { withNamespaces } from 'react-i18next';

const AutocompleteSelectField = ({
  t,
  meta: { touched, error, submitting },
  options,
  input,
  input: { name },
  label,
  isMulti,
  showLabel,
  showErrors,
  showAll,
  markdownLabel,
  onMenuOpen,
}) => {
  const MenuList = (props) => {
    const { children } = props;
    const rowRenderer = ({ key, index, style }) => (
      <div
        key={key}
        style={style}
      >
        {children[index]}
      </div>
    );

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            height={children.length > 1 ? 200 : 60}
            rowHeight={40}
            rowCount={children && children.length ? children.length : 0}
            rowRenderer={rowRenderer}
            noRowsRenderer={() => (
              <p className="m-4 text-center">
                {options && options.length > 0 ? t('user.label.no_options') : t('user.label.please_wait')}
              </p>
            )}
          />
        )}
      </AutoSizer>
    );
  };
  const customFilterOption = (candidate, value) => {
    if (!value) {
      return showAll || candidate.label.toLowerCase().startsWith('a');
    }
    const words = value.split(' ');

    return words.reduce(
      (acc, cur) => acc && candidate.label.toLowerCase().includes(cur.toLowerCase()),
      true,
    );
  };

  return (
    <div className="autocomplete-select-field-component form-group">
      <label
        className={`${showLabel ? 'visible' : ''}`}
        htmlFor={input.name}
      >
        {markdownLabel
          ? <ReactMarkdown>{label}</ReactMarkdown>
          : label
        }
      </label>
      <Select
        filterOption={customFilterOption}
        className="creatable-select-field"
        isMulti={isMulti}
        isDisabled={submitting}
        {...input}
        value={input.value}
        onChange={input.onChange}
        onBlur={event => event.preventDefault()}
        onMenuOpen={onMenuOpen}
        options={options}
        id={name}
        components={{ MenuList }}
      />
      <small className="select-field__error">
        {touched && error && showErrors ? error : '' }
      </small>
    </div>
  );
};

export default withNamespaces()(AutocompleteSelectField);

AutocompleteSelectField.propTypes = {
  t: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    submitting: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({ name: PropTypes.string }).isRequired,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
  })).isRequired,
  showLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
  showAll: PropTypes.bool,
  markdownLabel: PropTypes.bool,
  children: PropTypes.shape({}),
  key: PropTypes.string,
  index: PropTypes.number,
  style: PropTypes.shape({}),
  onMenuOpen: PropTypes.func,
};

AutocompleteSelectField.defaultProps = {
  label: '',
  isMulti: true,
  showLabel: false,
  showErrors: true,
  showAll: false,
  markdownLabel: false,
  children: null,
  key: '',
  index: 0,
  style: null,
  onMenuOpen: undefined,
};
