import React from 'react';
import PropTypes from 'prop-types';

import SignInSamlForm from '../../containers/SignInSamlForm';

const SignInCheckPage = ({ history: { push }, match: { params: { token } } }) => (
  <div className="sign-in-check-page">
    <SignInSamlForm
      samlToken={token}
      onSignIn={() => push('/')}
    />
  </div>
);

export default SignInCheckPage;

SignInCheckPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
