const FEEDBACK_FETCHED = '@@feedback/feedback-fetched';
const FEEDBACK_LIST_FETCHED = '@@feedback/feedback-list-fetched';
const FEEDBACK_CREATED = '@@feedback/feedback-created';
const FEEDBACK_UPDATED = '@@feedback/feedback-updated';

export {
  FEEDBACK_FETCHED,
  FEEDBACK_LIST_FETCHED,
  FEEDBACK_CREATED,
  FEEDBACK_UPDATED,
};
