const SURVEY_FETCHED = '@@survey/survey-fetched';
const SURVEY_LAST_FETCHED = '@@survey/survey-last-fetched';
const SURVEYS_FETCHED = '@@survey/surveys-fetched';
const SURVEYS_CLOSED_FETCHED = '@@survey/surveys-closed-fetched';
const SURVEYS_TEAM_FETCHED = '@@survey/surveys-team-fetched';
const SURVEY_CREATED = '@@survey/survey-created';
const SURVEY_REMOVED = '@@survey/survey-removed';
const SURVEY_UPDATED = '@@survey/survey-updated';
const SURVEY_SELF_ASSESSMENT = '@@survey/survey-self-assessment';
const SURVEY_REMINDER = '@@survey/email-reminder';

export {
  SURVEY_FETCHED,
  SURVEY_LAST_FETCHED,
  SURVEYS_FETCHED,
  SURVEYS_CLOSED_FETCHED,
  SURVEYS_TEAM_FETCHED,
  SURVEY_CREATED,
  SURVEY_REMOVED,
  SURVEY_UPDATED,
  SURVEY_SELF_ASSESSMENT,
  SURVEY_REMINDER,
};
