import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SurveysList from '../../components/SurveyList';
import { fetchClosedSurveys } from '../../redux-modules/survey/actions';

class SurveyClosedList extends Component {
  componentDidMount() {
    const { token, fetchClosedSurveys: fetchClosedSurveysAction } = this.props;
    fetchClosedSurveysAction(token);
  }

  render() {
    const { closedSurveys, filterUserSurvey } = this.props;

    return (
      <div className="survey-list">
        <SurveysList
          data={closedSurveys}
          filter={filterUserSurvey}
          closed
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  closedSurveys,
  filterUserSurvey,
}) => ({ token, closedSurveys, filterUserSurvey });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchClosedSurveys }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyClosedList);

SurveyClosedList.propTypes = {
  token: PropTypes.string.isRequired,
  closedSurveys: PropTypes.shape({}),
  filterUserSurvey: PropTypes.shape({}),
  fetchClosedSurveys: PropTypes.func.isRequired,
};

SurveyClosedList.defaultProps = {
  closedSurveys: null,
  filterUserSurvey: null,
};
