import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import tip6 from '../../assets/images/tip-6.svg';
import tip7 from '../../assets/images/tip-7.svg';
import tip8 from '../../assets/images/tip-8.svg';

const TipsAsk = ({ t }) => (
  <div className="tips">
    <p align="center">
      <strong>
        {t('survey.label.tips')}
        :
      </strong>
    </p>
    <div
      className="carousel slide"
      id="carousel"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item red active">
          <div className="row">
            <div className="col-3">
              <img
                src={tip6}
                alt="tip 6"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                {t('survey.label.tips_1')}
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item green green">
          <div className="row">
            <div className="col-3">
              <img
                src={tip7}
                alt="tip 7"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                {t('survey.label.tips_2')}
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item yellow">
          <div className="row">
            <div className="col-3">
              <img
                src={tip8}
                alt="tip 8"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                {t('survey.label.tips_3')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        className="carousel-control-prev"
        href="#carousel"
        role="button"
        data-slide="prev"
      >
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"
        />
        <span className="sr-only">
          {t('survey.action.previous')}
        </span>
      </a>
      <a
        className="carousel-control-next"
        href="#carousel"
        role="button"
        data-slide="next"
      >
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"
        />
        <span className="sr-only">
          {t('survey.action.next')}
        </span>
      </a>
    </div>
  </div>
);

export default withNamespaces()(TipsAsk);

TipsAsk.propTypes = {
  t: PropTypes.func.isRequired,
};
