import { SURVEY_TYPE_SELECTED, SURVEY_TIMEFRAME_SELECTED, SURVEY_USER_SELECTED } from './types';

const initialState = {
  type: '',
  timeframe: '',
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_TYPE_SELECTED: {
      return {
        ...state, ...{ type: action.payload },
      };
    }
    case SURVEY_TIMEFRAME_SELECTED: {
      return {
        ...state, ...{ timeframe: action.payload },
      };
    }
    case SURVEY_USER_SELECTED: {
      return {
        ...state, ...{ user: action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
