import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import tip6 from '../../assets/images/tip-6.svg';

const TipsPod = ({ t }) => (
  <div className="tips">
    <p align="center">
      <strong>
        {t('survey.label.tips_pod')}
        :
      </strong>
    </p>
    <div
      className="carousel slide"
      id="carousel"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item red active">
          <div className="row">
            <div className="col-3">
              <img
                src={tip6}
                alt="tip 6"
              />
            </div>
            <div className="col-8 text-left my-auto">
              <p>
                {t('survey.label.tips_4')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withNamespaces()(TipsPod);

TipsPod.propTypes = {
  t: PropTypes.func.isRequired,
};
