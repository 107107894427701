import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import TransferHistoryReportItem from '../TransferHistoryReportItem';

const TransferHistoryReportList = ({ data }) => {
  const reports = _.values(data)
    .sort((a, b) => {
      const endDateA = moment(a.created);
      const endDateB = moment(b.created);
      if (endDateA.isBefore(endDateB)) {
        return 1;
      }
      if (endDateA.isAfter(endDateB)) {
        return -1;
      }
      return 0;
    })
    .map((survey) => {
      const endDate = moment(survey.created);

      return (
        <div
          className="sub-accordion-item"
          key={survey.id}
        >
          <TransferHistoryReportItem
            id={survey.id}
            quarter={survey.quarter}
            year={survey.year}
            progress={{
              total: survey.progressTotal,
              count: survey.progressCount,
            }}
            title={survey.title}
            type={survey.type}
            name={survey.fullName}
            endDate={endDate}
            favorability={survey.score}
          />
        </div>
      );
    });

  return (
    <div className="report-list">
      {reports}
    </div>
  );
};

export default TransferHistoryReportList;

TransferHistoryReportList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

TransferHistoryReportList.defaultProps = {
  data: null,
};
