import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import AutocompleteSelectField from '../../components/AutocompleteSelectField';
import TextAreaField from '../../components/TextAreaField';
import InputField from '../../components/InputField';
import SubmitField from '../../components/SubmitField';
import SelectField from '../../components/SelectField/SelectField';
import fetchUsers from '../../redux-modules/user/actions';
import { fetchLastSurvey, updateSurvey } from '../../redux-modules/survey/actions';
import validators from '../../utils/validators';

import './update-survey-form.css';

const AVAILABLE_LANGUAGES = process.env.REACT_APP_AVAILABLE_LANGUAGES;

class UpdateSurveyForm extends Component {
  static handleEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      customQuestionAdded: false,
      customPeerAdded: false,
    };

    this.loadUsers = _.once(this.loadUsers.bind(this));
    this.renderExternalProviders = this.renderExternalProviders.bind(this);
  }

  componentDidMount() {
    const {
      token,
      type,
      fetchLastSurvey: fetchLastSurveyAction,
    } = this.props;

    if (type === 'external_customer_feedback' || type === 'internal_customer_feedback') {
      fetchLastSurveyAction(token, type).then(
        () => { this.setState({ loading: false }); },
        () => { this.setState({ loading: false }); },
      );
    } else {
      this.setState({ loading: false });
    }
  }

  loadUsers() {
    const { token, users, fetchUsers: fetchUsersAction } = this.props;

    if (!users || users.length === 0) {
      fetchUsersAction(token);
    }
  }

  handleSubmit(values) {
    const {
      token,
      id,
      updateSurvey: updateSurveyAction,
      onSubmit,
      type,
      t,
    } = this.props;

    let providers = (values.providers || []).map(value => ({ email: value.value }));
    if (type === 'external_customer_feedback') {
      providers = values.externalProviders;
    }
    const customProviders = (values.customPeerProviders || [])
      .map(value => ({ email: value.value }));
    providers = [...providers, ...customProviders];
    providers = _.uniqBy(providers, provider => provider.email);

    const { customQuestion } = values;

    return updateSurveyAction(
      token,
      id,
      providers,
      type,
      customQuestion,
    ).then(
      onSubmit || (() => {}),
      () => {
        throw new SubmissionError({ providers: t('survey.error.provider_min') });
      },
    );
  }

  renderExternalProviders({ fields }) {
    const { t } = this.props;

    const languageOptions = AVAILABLE_LANGUAGES
      .split(',')
      .map(language => (
        {
          value: language,
          label: t(`language.${language}`),
        }
      ));

    return (
      <div className="external-providers">
        <div className="title">
          {t('survey.label.add_customers')}
          {':'}
        </div>
        {fields.map((provider, index) => (
          <div
            className="question-box"
            key={provider}
          >
            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="close"
                  onClick={() => fields.remove(index)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col col-5">
                <div className="form-group">
                  <Field
                    name={`${provider}.email`}
                    type="email"
                    label={t('user.label.email')}
                    showLabel
                    component={InputField}
                  />
                </div>
              </div>
              <div className="col col-4">
                <div className="form-group">
                  <Field
                    name={`${provider}.name`}
                    type="text"
                    label={t('user.label.username')}
                    showLabel
                    component={InputField}
                  />
                </div>
              </div>
              <div className="col col-3">
                <div className="form-group">
                  <Field
                    name={`${provider}.language`}
                    label={t('user.label.language')}
                    showLabel
                    options={languageOptions}
                    component={SelectField}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
        }
        <div className="form-group text-center">
          <button
            className="btn standard add-provider"
            type="button"
            onClick={() => fields.push({})}
          >
            {t('survey.label.add_customer')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      t,
      handleSubmit,
      type,
      status,
      users,
      initialValues,
      currentCustomQuestion,
      currentCustomPeerProviders,
      step,
      onBack,
    } = this.props;
    const { loading, customQuestionAdded, customPeerAdded } = this.state;

    const providers = initialValues ? initialValues.providers : [];
    const customPeerProviders = initialValues ? initialValues.customPeerProviders : [];
    const prePopulatedProviders = initialValues ? initialValues.prePopulatedProviders : [];
    const allOptions = (users || []);
    const options = type === 'peer_feedback' ? prePopulatedProviders : allOptions;

    const showCustomQuestion = customQuestionAdded || currentCustomQuestion;
    const showProviders = providers.length > 0;
    const showCustomProviders = providers.length === 0
      || customPeerAdded
      || currentCustomPeerProviders.length > 0;

    if (loading) {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
      <div className="update-survey-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
          onKeyPress={UpdateSurveyForm.handleEnterKey}
        >
          {type === 'peer_feedback' && (
            <div className="form-group mt-0">
              <div className={showCustomQuestion ? '' : 'd-none'}>
                <Field
                  name="customQuestion"
                  label={t('feedback.label.ask_question_peer')}
                  component={TextAreaField}
                />
              </div>
              <button
                className={`update-survey-form__button update-survey-form__button--add-question${showCustomQuestion ? ' d-none' : ''}`}
                type="button"
                onClick={() => { this.setState({ customQuestionAdded: true }); }}
              >
                {t('survey.action.add_question_peer')}
              </button>
              <ReactMarkdown>
                {t(status === 'approve_level_1'
                  ? 'survey.label.selected_peers_1'
                  : 'survey.label.select_peers_1')}
              </ReactMarkdown>
            </div>
          )}
          {type === 'external_customer_feedback' && (<FieldArray
            name="externalProviders"
            component={this.renderExternalProviders}
          />
          )}
          {type === 'internal_customer_feedback' && (<Field
            name="providers"
            options={options}
            label={`${t('survey.label.select_customers')}:`}
            onMenuOpen={this.loadUsers}
            component={AutocompleteSelectField}
          />
          )}
          {type === 'peer_feedback' && (
            <div className="update-survey-form__peers">
              <div className={`update-survey-form__peer__custom-providers ${showCustomProviders ? 'update-survey-form__peer__custom-providers--visible' : ''}`}>
                <Field
                  name="customPeerProviders"
                  options={allOptions}
                  label={`${t(status === 'approve_level_1'
                    ? `survey.label.selected_peers_2_${_.isEmpty(customPeerProviders) ? 'not_selected' : 'selected'}`
                    : 'survey.label.select_peers_2')}`}
                  markdownLabel
                  onMenuOpen={this.loadUsers}
                  component={AutocompleteSelectField}
                />
              </div>
              {!showCustomProviders && (
                <button
                  className="update-survey-form__button update-survey-form__button--add-peer"
                  type="button"
                  onClick={() => { this.setState({ customPeerAdded: true }); }}
                >
                  {t('survey.action.add_peer')}
                </button>
              )}
              <div className={`update-survey-form__peer__providers ${showProviders ? 'update-survey-form__peer__providers--visible' : ''}`}>
                <Field
                  name="providers"
                  options={options}
                  label={`${t(status === 'approve_level_1'
                    ? 'survey.label.selected_peers'
                    : 'survey.label.select_peers')}`}
                  showAll
                  markdownLabel
                  component={AutocompleteSelectField}
                />
              </div>
              {status === 'approve_level_1' && (
                <p>{t('survey.label.selected_peers_3')}</p>
              )}
            </div>
          )}
          <div className="buttons-group">
            <div className="buttons-group-wrapper">
              {step === 1 && (
                <button
                  className="btn standard gray mr-4"
                  type="button"
                  onClick={onBack}
                >
                  {t('survey.action.back')}
                </button>
              )}
              {step !== 1 && (
                <Link
                  style={{ float: 'none' }}
                  className="btn standard gray"
                  to="/"
                >
                  {t('survey.action.cancel')}
                </Link>
              )}
              <Field
                name="submit"
                inline
                label={t(status === 'approve_level_1'
                  ? 'survey.action.launch'
                  : 'survey.action.submit')}
                component={SubmitField}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t, type }) => {
  const errors = {};

  const isCustomer = validators.equals(type, 'customer_feedback')
    || validators.equals(type, 'external_customer_feedback')
    || validators.equals(type, 'internal_customer_feedback');
  if (!validators.maxLength(values.providers, 10) && isCustomer) {
    errors.providers = t('survey.error.provider_max');
  }

  let peerProviders = [
    ...(values.providers || []),
    ...(values.customPeerProviders || []),
  ];
  peerProviders = _.uniqBy(peerProviders, provider => provider.value);

  if (!isCustomer && !validators.minLength(peerProviders, 3)) {
    errors.providers = t('survey.error.provider_min');
    if ((values.providers || []).length === 0) {
      errors.customPeerProviders = t('survey.error.provider_min');
    }
  }
  if (isCustomer && !validators.required(values.providers)) {
    errors.providers = t('survey.error.provider_required');
  }
  if (isCustomer && !validators.minLength(values.providers, 5)) {
    errors.providers = t('survey.error.provider_min_5');
  }
  if (validators.equals(type, 'external_customer_feedback') && !validators.minLength(values.externalProviders, 5)) {
    errors.externalProviders = { _error: t('survey.error.provider_min_5') };
  }

  return errors;
};

function mapStateToProps({
  token,
  users,
  surveys,
  lastSurvey,
  form,
},
{ id }) {
  const survey = _.get(surveys, id, null);
  let initialValues;

  if (lastSurvey && Object.prototype.hasOwnProperty.call(lastSurvey, 'providers')) {
    const providers = (lastSurvey.providers || []).map((provider) => {
      const user = _.find(users, { email: provider });
      return ({
        value: provider,
        label: user ? user.name : provider,
      });
    });

    initialValues = {
      providers: lastSurvey.type === 'internal_customer_feedback' ? providers : [],
      externalProviders: lastSurvey.externalProviders,
    };
  }

  if (survey
    && Object.prototype.hasOwnProperty.call(survey, 'providers')
    && Object.prototype.hasOwnProperty.call(survey, 'previousProviders')
    && Object.prototype.hasOwnProperty.call(survey, 'externalProviders')
  ) {
    const providers = survey.providers || [];
    const previousProviders = survey.previousProviders || [];
    const prePopulatedProviders = survey.areProvidersSelected
      ? previousProviders
      : providers;

    const selectedPrePopulatedProviders = providers
      .filter(provider => prePopulatedProviders.includes(provider));
    const selectedNewProviders = providers
      .filter(provider => !prePopulatedProviders.includes(provider));

    const providersList = (selectedPrePopulatedProviders).map((provider) => {
      const user = _.find(users, { email: provider });
      return ({
        value: provider,
        label: user ? user.name : provider,
      });
    });
    const customProvidersList = (selectedNewProviders).map((provider) => {
      const user = _.find(users, { email: provider });
      return ({
        value: provider,
        label: user ? user.name : provider,
      });
    });
    const prePopulatedProvidersList = (prePopulatedProviders).map((provider) => {
      const user = _.find(users, { email: provider });
      return ({
        value: provider,
        label: user ? user.name : provider,
      });
    });

    const hasCustomQuestion = survey
      && survey.type === 'peer_feedback'
      && !_.isEmpty(survey.questions)
      && _.size(survey.questions) > 9;
    const customQuestion = hasCustomQuestion ? _.last(survey.questions).text : '';

    initialValues = {
      providers: providersList,
      customPeerProviders: customProvidersList,
      prePopulatedProviders: prePopulatedProvidersList,
      customQuestion,
      externalProviders: survey.externalProviders,
    };
  }

  const currentCustomQuestion = _.get(form, 'updateSurveyForm.values.customQuestion', '');
  const currentCustomPeerProviders = _.get(form, 'updateSurveyForm.values.customPeerProviders', []);

  return {
    token,
    users,
    initialValues,
    surveys,
    currentCustomQuestion,
    currentCustomPeerProviders,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchUsers,
  fetchLastSurvey,
  updateSurvey,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    validate,
    form: 'updateSurveyForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: true,
  })(UpdateSurveyForm),
));
UpdateSurveyForm.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
  surveys: PropTypes.shape({}),
  lastSurvey: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string,
  initialValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchLastSurvey: PropTypes.func.isRequired,
  updateSurvey: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  currentCustomQuestion: PropTypes.string.isRequired,
  currentCustomPeerProviders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

UpdateSurveyForm.defaultProps = {
  users: null,
  surveys: null,
  lastSurvey: null,
  initialValues: null,
  status: '',
};
