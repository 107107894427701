const USER_LOGGED_IN = '@@auth/user-logged-in';
const ADMIN_LOGGED_IN = '@@auth/admin-logged-in';
const USER_LOGGED_OUT = '@@auth/user-logged-out';
const TOKEN_REFRESHED = '@@auth/token-refreshed';

export {
  USER_LOGGED_IN,
  ADMIN_LOGGED_IN,
  USER_LOGGED_OUT,
  TOKEN_REFRESHED,
};
