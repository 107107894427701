import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Tooltip from '../Tooltip';
import ratingUtils from '../../utils/ratings';
import selfIcon from '../../assets/images/self-icon.svg';

const ReportOverallResults = ({
  t,
  rating1,
  rating2,
  rating3,
  rating4,
  rating5,
  progress,
  favorability,
  selfAssessment,
}) => {
  const ratings = ratingUtils.calculateRatings(rating1, rating2, rating3, rating4, rating5);
  const providers = Math.floor(100 * progress.count / progress.total);
  const score = Math.floor(favorability * 100);
  const selfScore = selfAssessment && selfAssessment.length > 0
    ? selfAssessment.map(s => s.rating).reduce((a, b) => a + b) / selfAssessment.length
    : 0;

  return (
    <div className="report-overall-results report-box">
      <div className="report-box-title">
        {t('survey.label.overall_results')}
        {' '}
        <Tooltip title={t('tooltip.overall')} />
      </div>
      <div className="overall-results">
        {ratings && (
          <div className="stacked-bar">
            <span
              className="bar-1"
              style={{ width: `${ratings[1].value}%` }}
              data-toggle="tooltip"
              data-html="true"
              title={t('tooltip.bar').replace(
                '{number}',
                `<strong>${rating1} '${t('answer.label.rating_1')}'</strong>`,
              )}
            >
              {rating1}
            </span>
            <span
              className="bar-2"
              style={{ width: `${ratings[2].value}%` }}
              data-toggle="tooltip"
              data-html="true"
              title={t('tooltip.bar').replace(
                '{number}',
                `<strong>${rating2} '${t('answer.label.rating_2')}'</strong>`,
              )}
            >
              {rating2}
            </span>
            <span
              className="bar-3"
              style={{ width: `${ratings[3].value}%` }}
              data-toggle="tooltip"
              data-html="true"
              title={t('tooltip.bar').replace(
                '{number}',
                `<strong>${rating3} '${t('answer.label.rating_3')}'</strong>`,
              )}
            >
              {rating3}
            </span>
            <span
              className="bar-4"
              style={{ width: `${ratings[4].value}%` }}
              data-toggle="tooltip"
              data-html="true"
              title={t('tooltip.bar').replace(
                '{number}',
                `<strong>${rating4} '${t('answer.label.rating_4')}'</strong>`,
              )}
            >
              {rating4}
            </span>
            <span
              className="bar-5"
              style={{ width: `${ratings[5].value}%` }}
              data-toggle="tooltip"
              data-html="true"
              title={t('tooltip.bar').replace(
                '{number}',
                `<strong>${rating5} '${t('answer.label.rating_5')}'</strong>`,
              )}
            >
              {rating5}
            </span>
          </div>
        )}
        {selfScore > 0 && (
          <div
            className="self-icon"
            style={{ left: `${Math.min((selfScore - 1) * 25, 100)}%` }}
            data-toggle="tooltip"
            data-html="true"
            title={`<strong>
              ${t('tooltip.self_overall')}:
            </strong>
            ${Math.floor(Math.min((selfScore - 1) * 25, 100))}%`
            }
          >
            <img
              src={selfIcon}
              alt="self icon"
            />
            <div className="self-text">
              {t('survey.label.self')}
            </div>
          </div>
        )}
        <div className="row progress-bar-box">
          <div className="col">
            <div className="progres-bar-label">
              {t('survey.label.providers')}
            </div>
            <div className="progress">
              <div
                className="progress-bar providers"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${providers}%` }}
                aria-valuenow={providers}
                data-toggle="tooltip"
                data-html="true"
                title={t('tooltip.providers')}
              >
                {progress.count}
                {'/'}
                {progress.total}
              </div>
            </div>
          </div>
          <div className="col" align="right">
            <div className="progres-bar-label">
              {t('survey.label.favorability')}
            </div>
            <div className="progress">
              <div
                className="progress-bar favorability"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${score}%` }}
                aria-valuenow={score}
                data-toggle="tooltip"
                data-html="true"
                title={t('tooltip.favorable')}
              >
                {score}
                %
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(ReportOverallResults);

ReportOverallResults.propTypes = {
  t: PropTypes.func.isRequired,
  rating1: PropTypes.number,
  rating2: PropTypes.number,
  rating3: PropTypes.number,
  rating4: PropTypes.number,
  rating5: PropTypes.number,
  progress: PropTypes.shape({
    total: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  favorability: PropTypes.number.isRequired,
  selfAssessment: PropTypes.arrayOf(PropTypes.shape({})),
};

ReportOverallResults.defaultProps = {
  rating1: 0,
  rating2: 0,
  rating3: 0,
  rating4: 0,
  rating5: 0,
  selfAssessment: [],
};
