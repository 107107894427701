import {
  FILTER_ADMIN_SURVEY_TYPE_SELECTED,
  FILTER_ADMIN_BUSINESS_UNIT_SELECTED,
  FILTER_ADMIN_WORKFORCE_SEGMENT_SELECTED,
  FILTER_ADMIN_STATUS_SELECTED,
  FILTER_ADMIN_YEAR_SELECTED,
} from './types';

const selectType = type => ({
  type: FILTER_ADMIN_SURVEY_TYPE_SELECTED,
  payload: type,
});

const selectUnit = unit => ({
  type: FILTER_ADMIN_BUSINESS_UNIT_SELECTED,
  payload: unit,
});

const selectSegment = segment => ({
  type: FILTER_ADMIN_WORKFORCE_SEGMENT_SELECTED,
  payload: segment,
});

const selectStatus = status => ({
  type: FILTER_ADMIN_STATUS_SELECTED,
  payload: status,
});

const selectYear = year => ({
  type: FILTER_ADMIN_YEAR_SELECTED,
  payload: year,
});

export {
  selectType,
  selectUnit,
  selectSegment,
  selectStatus,
  selectYear,
};
