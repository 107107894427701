import axios from 'axios';

import USERS_FETCHED from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchUsers = () => {
  const url = `${REST_API_URL}v1/users`;
  const request = axios.get(
    url,
  );

  return {
    type: USERS_FETCHED,
    payload: request,
  };
};

export default fetchUsers;
