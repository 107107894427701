import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import TipsAsk from '../../components/TipsAsk';
import TipsPod from '../../components/TipsPod';
import AddSurveyForm from '../AddSurveyForm';
import CheckboxField from '../../components/CheckboxField';
import SubmitField from '../../components/SubmitField';
import { createSurvey } from '../../redux-modules/survey/actions';

import './add-survey.css';

import visibilityIcon from '../../assets/images/visibility.svg';
import anonimity from '../../assets/images/anonimity.svg';
import cheerUp from '../../assets/images/cheer-up.png';

class AddSurvey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      title: '',
      providers: [],
      questions: [],
      visibility: true,
    };

    this.handleSurveyFormSubmit = this.handleSurveyFormSubmit.bind(this);
    this.renderStepOne = this.renderStepOne.bind(this);
    this.renderStepTwo = this.renderStepTwo.bind(this);
    this.renderStepThree = this.renderStepThree.bind(this);
  }

  handleSurveyFormSubmit(providers, questions) {
    this.setState({ providers, questions });
  }

  handleSubmit() {
    const {
      token,
      createSurvey: createSurveyAction,
      type,
    } = this.props;
    const {
      title,
      providers,
      questions,
      businessUnit,
      visibility,
    } = this.state;

    return createSurveyAction(
      token,
      null,
      null,
      title,
      type,
      providers,
      questions,
      '',
      businessUnit || '',
      '',
      '',
      visibility,
    ).then(
      () => this.setState({ step: 4 }),
      () => {},
    );
  }

  renderStepOne() {
    const {
      t,
      onCancel,
      userProfile,
      type,
    } = this.props;

    return (
      <div className="step-one">
        <div className="content-box accordion">
          {type === 'pod_feedback' && (
            <p>
              <strong>
                {t(`survey.label.tips_5_${type}`)}
              </strong>
            </p>
          )}
          <p>
            {t('survey.label.hi')}
            {' '}
            {userProfile.name}
            ,
          </p>
          <p>
            {t(`survey.label.tips_1_${type}`)}
          </p>
          <p>
            {t(`survey.label.tips_2_${type}`)}
          </p>
          <div className="row visibility-anonimity">
            <div className="col my-auto">
              <div className="row">
                <div className="col-sm-4 my-auto">
                  <img
                    src={visibilityIcon}
                    alt="visibility"
                  />
                </div>
                <div className="col-sm my-auto">
                  <p>
                    {t(`survey.label.tips_3_${type}`)}
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-auto">
              <div className="row">
                <div className="col-sm-4 my-auto">
                  <img
                    src={anonimity}
                    alt="anonimity"
                  />
                </div>
                <div className="col-sm my-auto">
                  <p>
                    {t(`survey.label.tips_4_${type}`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {type !== 'pod_feedback'
            ? (<TipsAsk />)
            : (<TipsPod />)
          }
        </div>
        <div className="buttons-group">
          <div className="buttons-group-wrapper">
            <button
              className="btn standard gray"
              type="button"
              onClick={onCancel}
            >
              {t('survey.action.cancel')}
            </button>
            <button
              className="btn standard"
              type="button"
              onClick={() => this.setState({ step: 2 })}
            >
              {t('survey.action.next')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderStepTwo() {
    const { type, handleSubmit } = this.props;

    return (
      <div className="step-two">
        <AddSurveyForm
          type={type}
          onSubmit={(title, providers, questions, businessUnit) => {
            if (type === 'freestyle_feedback') {
              this.setState({
                step: 3,
                title,
                providers,
                questions,
              });
            } else {
              this.setState(
                {
                  title,
                  providers,
                  questions: [{ text: 'default' }],
                  businessUnit,
                },
                handleSubmit(this.handleSubmit.bind(this)),
              );
            }
          }}
          onCancel={() => this.setState({ step: 1 })}
        />
      </div>
    );
  }

  renderStepThree() {
    const { t, handleSubmit } = this.props;
    const { providers, questions } = this.state;

    const users = (providers || []).map((email, index) => (
      <span key={email}>
        { email }
        {index < providers.length - 1 ? ', ' : ''}
      </span>
    ));
    const askedQuestions = (questions || []).map((question, index) => (
      <div
        className={`question-box ${question.rating ? 'has-rating' : ''}`}
        key={question.text}
      >
        {index + 1}
        {'. '}
        {question.text}
      </div>
    ));

    return (
      <div className="step-three">
        <div className="content-box accordion">
          <div className="send-feedback-to">
            {t('survey.label.freestyle_providers')}
            {' '}
            { users }
            {' '}
            {t('survey.label.freestyle_questions')}
            :
          </div>
          <div className="question-list">
            {askedQuestions}
          </div>
          <p align="center">
            <strong>
              {t('feedback.label.launch_note')}
            </strong>
          </p>
        </div>
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="content-box text-center">
            <Field
              name="visibility"
              type="checkbox"
              label={t('survey.label.result_visible')}
              showLabel
              component={CheckboxField}
              onChange={(event, result) => {
                this.setState({ visibility: result });
              }}
            />
          </div>
          <div className="buttons-group">
            <div className="buttons-group-wrapper">
              <button
                className="btn standard gray"
                type="button"
                onClick={() => this.setState({ step: 2 })}
              >
                {t('survey.action.back')}
              </button>
              <Field
                inline
                name="submit"
                className="btn standard"
                label={t('survey.action.submit')}
                component={SubmitField}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderStepFour() {
    const { t, onLaunch } = this.props;

    return (
      <div>
        <div className="content-box accordion">
          <p align="center">
            <strong>
              {t('answer.label.thank_you')}
              !
            </strong>
          </p>
          <p align="center">
            {t('answer.label.thank_you_desc_2')}
          </p>
          <div className="cheer-up">
            <img
              align="center"
              alt="cheer up"
              src={cheerUp}
            />
          </div>
        </div>
        <div className="buttons-group">
          <button
            type="button"
            className="btn standard"
            onClick={onLaunch || (() => {})}
          >
            {t('menu.label.close')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { t, userProfile, type } = this.props;
    const { step } = this.state;

    return (
      <div className="add-survey">
        <div className="content-title">
          <div className="row">
            <div className="col my-auto">
              <span className="box-title">
                {moment().year()}
                {' '}
                Q
                {moment().quarter()}
                {' '}
                {t(`survey.type.${type}`)}
                {' '}
                {t('survey.label.for')}
                {' '}
                {userProfile.name}
              </span>
            </div>
          </div>
        </div>
        {step === 1 && this.renderStepOne()}
        <div className={step === 2 ? '' : 'd-none'}>
          {this.renderStepTwo()}
        </div>
        {step === 3 && this.renderStepThree()}
        {step === 4 && this.renderStepFour()}
      </div>
    );
  }
}

const mapStateToProps = ({ token, userProfile }) => ({ token, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators({ createSurvey }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'surveyVisibilityForm',
  initialValues: { visibility: true },
})(AddSurvey)));

AddSurvey.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  createSurvey: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onLaunch: PropTypes.func,
  userProfile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

AddSurvey.defaultProps = {
  onCancel: null,
  onLaunch: null,
};
