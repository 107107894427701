import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
  }

  handleClose() {
    const { onClose } = this.props;
    onClose();
  }

  handleYes() {
    const { onYes } = this.props;
    onYes();
  }

  handleNo() {
    const { onNo } = this.props;
    onNo();
  }

  render() {
    const {
      t,
      open,
      title,
      body,
    } = this.props;

    return (
      <div className={`confirm-dialog pop-up-filter-wrapper ${open ? 'open' : ''}`}>
        <div className="pop-up-filter">
          <div className="pop-up-filter-content">
            <span
              className="close-filter"
              role="button"
              tabIndex={0}
              onClick={this.handleClose}
              onKeyPress={this.handleClose}
            >
              {t('menu.label.close')}
              <i className="fas fa-times" />
            </span>
            <h3>
              {title}
            </h3>
            <p>
              {body}
            </p>
            <div className="text-center">
              <button
                className="btn standard gray mr-4"
                type="button"
                onClick={this.handleNo}
              >
                {t('dialog.cancel')}
              </button>
              <button
                className="btn standard"
                type="button"
                onClick={this.handleYes}
              >
                {t('dialog.confirm')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(ConfirmDialog);

ConfirmDialog.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  open: false,
  title: '',
  body: '',
};
