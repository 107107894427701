import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import logo from '../../../assets/images/logo.svg';
import cchLogo from '../../../assets/images/cch-logo.svg';

import './admin-top-bar.css';

class AdminTopBar extends Component {
  constructor(props) {
    super(props);

    this.state = { menuOpen: false };

    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  handleMenuOpen() {
    this.setState({ menuOpen: true });
  }

  handleMenuClose() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { t } = this.props;
    const { menuOpen } = this.state;

    return (
      <nav className="admin-top-bar navbar navbar-expand-lg navbar-gray static-top">
        <div className="mobile-header">
          <div className="mobile-header-logo">
            <img
              src={cchLogo}
              alt="logo"
            />
          </div>
          <span
            className="mobile-menu-button"
            role="button"
            tabIndex={0}
            onClick={this.handleMenuOpen}
            onKeyPress={this.handleMenuOpen}
          >
            {t('menu.label.menu')}
            <i className="fas fa-bars" />
          </span>
        </div>
        <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
          <ul className="main-menu-mobile-menu">
            <span
              className="close-menu"
              role="button"
              tabIndex={0}
              onClick={this.handleMenuClose}
              onKeyPress={this.handleMenuClose}
            >
              {t('menu.label.close')}
              <i className="fas fa-times" />
            </span>
            <li className="nav-item">
              <NavLink
                to="/"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.surveys')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/users"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.users')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/survey-exclude-rules"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.survey_exclude_rules')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/transfer-history"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.transfer_history')}
              </NavLink>
            </li>
            <li className="nav-item last">
              <NavLink
                to="/profile"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.settings')}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="logo">
            <NavLink to="/">
              <img
                src={logo}
                alt="logo"
              />
            </NavLink>
          </div>
          <ul className="main-menu ml-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.surveys')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/users"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.users')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/survey-exclude-rules"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.survey_exclude_rules')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/transfer-history"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.transfer_history')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/profile"
                onClick={this.handleMenuClose}
                activeClassName="active"
              >
                {t('menu.label.settings')}
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default withNamespaces()(AdminTopBar);

AdminTopBar.propTypes = {
  t: PropTypes.func.isRequired,
};
