import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SurveyList from '../../components/SurveyList';
import { fetchTeamSurveys } from '../../redux-modules/survey/actions';

class SurveyTeamList extends Component {
  componentDidMount() {
    const { token, fetchTeamSurveys: fetchTeamSurveysAction } = this.props;
    fetchTeamSurveysAction(token);
  }

  render() {
    const { teamSurveys, filterUserSurvey } = this.props;

    return (
      <div className="survey-team-list">
        <SurveyList
          data={teamSurveys}
          filter={filterUserSurvey}
          team
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  teamSurveys,
  filterUserSurvey,
}) => ({ token, teamSurveys, filterUserSurvey });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchTeamSurveys }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTeamList);

SurveyTeamList.propTypes = {
  token: PropTypes.string.isRequired,
  teamSurveys: PropTypes.shape({}),
  filterUserSurvey: PropTypes.shape({}),
  fetchTeamSurveys: PropTypes.func.isRequired,
};

SurveyTeamList.defaultProps = {
  teamSurveys: null,
  filterUserSurvey: null,
};
