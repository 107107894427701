import axios from 'axios';

import { USER_FETCHED, USER_UPDATED } from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const me = () => {
  const url = `${REST_API_URL}v1/users/me`;
  const request = axios.get(
    url,
  );

  return {
    type: USER_FETCHED,
    payload: request,
  };
};

const updateUser = (token, language) => {
  const url = `${REST_API_URL}v1/users`;
  const request = axios.put(
    url,
    {
      language,
    },
  );

  return {
    type: USER_UPDATED,
    payload: request,
  };
};
export { me, updateUser };
