import _ from 'lodash';

import WORKFORCE_SEGMENT_FETCHED from './types';
import { USER_LOGGED_OUT } from '../auth/types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKFORCE_SEGMENT_FETCHED: {
      return _.get(action.payload, 'data.workforceSegment', null);
    }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
