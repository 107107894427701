import { USER_ACTIVATED } from './types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIVATED: {
      return action.payload.status === 204;
    }
    default: {
      return state;
    }
  }
};
