import axios from 'axios';

import USER_REGISTERED from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const register = (email, password, name) => {
  const url = `${REST_API_URL}v1/users`;
  const request = axios.post(url, {
    email,
    password,
    name,
  });

  return {
    type: USER_REGISTERED,
    payload: request,
  };
};

export default register;
