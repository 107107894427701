import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import AdminTransferHistoryCheckForm from '../../containers/AdminTransferHistoryCheckForm';
import AdminTransferHistoryExecuteForm from '../../containers/AdminTransferHistoryExecuteForm';

const AdminTransferHistoryPage = ({ t }) => (
  <main className="admin admin-survey-exclude-rules-page">
    <div className="container">
      <div className="table-survey-exclude-rules-wrapper">
        <div className="content-title my-auto">
          <span>
            {t('menu.label.transfer_history')}
          </span>
        </div>
        <div className="container">
          <div className="table-survey-wrapper">
            <AdminTransferHistoryCheckForm />
            <AdminTransferHistoryExecuteForm />
          </div>
        </div>
      </div>
    </div>
  </main>
);

export default withNamespaces()(AdminTransferHistoryPage);

AdminTransferHistoryPage.propTypes = {
  t: PropTypes.func.isRequired,
};
