import React from 'react';
import PropTypes from 'prop-types';

import './select-field.css';

const SelectField = ({
  meta: { touched, error, submitting },
  options,
  input,
  label,
  hasEmpty,
  placeholder,
  showLabel,
  showErrors,
  onValueChange,
}) => {
  let optionsView = options.map(option => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="dropdown-item"
      key={option.value}
      role="button"
      tabIndex="0"
      onClick={() => {
        input.onChange(option.value);
        if (onValueChange) {
          onValueChange(option.value);
        }
      }}
      onKeyPress={() => {
        input.onChange(option.value);
        if (onValueChange) {
          onValueChange(option.value);
        }
      }}
    >
      {option.label}
    </a>
  ));
  if (hasEmpty) {
    optionsView = [(
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className="dropdown-item"
        key="null"
        role="button"
        tabIndex="0"
        onClick={() => {
          input.onChange(null);
          if (onValueChange) {
            onValueChange(null);
          }
        }}
        onKeyPress={() => {
          input.onChange(null);
          if (onValueChange) {
            onValueChange(null);
          }
        }}
      >
        {placeholder}
      </a>
    ), ...optionsView];
  }

  const selectedValue = options.filter(o => o.value === input.value);

  return (
    <div className="select-field form-group">
      <label
        className={`${showLabel ? 'visible' : ''}`}
        htmlFor={input.name}
      >
        {label}
      </label>
      <div className="form-group">
        <div className="dropdown">
          <button
            className="btn dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            disabled={submitting}
          >
            {selectedValue.length > 0 ? selectedValue[0].label : placeholder}
          </button>
          <div
            className={`dropdown-menu ${options && options.length > 5 ? 'pre-scrollable' : ''}`}
            aria-labelledby="dropdownMenuButton"
          >
            {optionsView}
          </div>
        </div>
      </div>
      <small className="select-field__error">
        {touched && error && showErrors ? error : '' }
      </small>
    </div>
  );
};

export default SelectField;

SelectField.propTypes = {
  meta: PropTypes.shape({
    submitting: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  hasEmpty: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
  })).isRequired,
  showLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
  onValueChange: PropTypes.func,
};

SelectField.defaultProps = {
  label: '',
  hasEmpty: false,
  placeholder: null,
  showLabel: false,
  showErrors: true,
  onValueChange: null,
};
