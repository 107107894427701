const SEND_PROGRESS_REPORT = '@@admin-report/send-progress-report';
const SEND_QUALITATIVE_REPORT = '@@admin-report/send-qualitative-report';
const SEND_FULL_EXPORT_REPORT = '@@admin-report/send-full-export-report';
const SEND_SURVEY_REPORT = '@@admin-report/send-survey-report';
const SEND_SURVEY_INITIAL_REPORT = '@@admin-report/send-survey-initial-report';
const SEND_TASK_REPORT = '@@admin-report/send-task-report';
const SEND_SCORE_REPORT = '@@admin-report/send-score-report';
const SEND_PARTICIPATION_RATE_REPORT = '@@admin-report/send-participation-rate-report';

export {
  SEND_PROGRESS_REPORT,
  SEND_QUALITATIVE_REPORT,
  SEND_FULL_EXPORT_REPORT,
  SEND_SURVEY_REPORT,
  SEND_SURVEY_INITIAL_REPORT,
  SEND_TASK_REPORT,
  SEND_SCORE_REPORT,
  SEND_PARTICIPATION_RATE_REPORT,
};
