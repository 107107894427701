import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AddSurvey from '../../containers/AddSurvey';

class AddSurveyPage extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleLaunch = this.handleLaunch.bind(this);
  }

  handleCancel() {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
      return;
    }
    history.push('/');
  }

  handleLaunch() {
    const { history } = this.props;

    history.push('/');
  }

  render() {
    const { match: { params: { type } } } = this.props;

    return (
      <main className="add-survey-page survey">
        <div className="container">
          <AddSurvey
            onCancel={this.handleCancel}
            onLaunch={this.handleLaunch}
            type={type}
          />
        </div>
      </main>
    );
  }
}

export default AddSurveyPage;

AddSurveyPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
