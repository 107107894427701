import axios from 'axios';

import {
  FEEDBACK_FETCHED,
  FEEDBACK_LIST_FETCHED,
  FEEDBACK_CREATED,
  FEEDBACK_UPDATED,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchFeedback = (token, id) => {
  const url = `${REST_API_URL}v1/feedback/${id}`;
  const request = axios.get(
    url,
  );

  return {
    type: FEEDBACK_FETCHED,
    payload: request,
  };
};

const fetchFeedbackList = () => {
  const url = `${REST_API_URL}v1/feedback`;
  const request = axios.get(
    url,
  );

  return {
    type: FEEDBACK_LIST_FETCHED,
    payload: request,
  };
};

const createFeedback = (token, answer, userEmail, visibility) => {
  const url = `${REST_API_URL}v1/feedback`;
  const request = axios.post(
    url,
    {
      answer,
      userEmail,
      visibility,
    },
  );

  return {
    type: FEEDBACK_CREATED,
    payload: request,
  };
};

const updateFeedback = (token, id, answers) => {
  const url = `${REST_API_URL}v1/feedback/${id}`;
  const request = axios.put(
    url,
    {
      answers,
    },
  );

  return {
    type: FEEDBACK_UPDATED,
    payload: request,
    meta: { id },
  };
};

export {
  fetchFeedback,
  fetchFeedbackList,
  createFeedback,
  updateFeedback,
};
