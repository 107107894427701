import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import ReportItem from '../ReportItem';

const ReportList = ({ data, filter: { type, timeframe, user }, team }) => {
  const currentYear = moment().year();
  const currentQuarter = moment().quarter();
  const currentMonth = moment().month();

  const reports = _.values(data)
    .filter((survey) => {
      const endDate = moment(survey.created);

      if (timeframe && timeframe === 'this_month' && (endDate.month() !== currentMonth || endDate.year() !== currentYear)) {
        return false;
      }
      if (timeframe && timeframe === 'this_quarter' && (endDate.quarter() !== currentQuarter || endDate.year() !== currentYear)) {
        return false;
      }
      if (timeframe && timeframe === 'this_year' && endDate.year() !== currentYear) {
        return false;
      }
      if (type && survey.type !== type) {
        return false;
      }
      if (team && user && survey.user !== user) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      const endDateA = moment(a.created);
      const endDateB = moment(b.created);
      if (endDateA.isBefore(endDateB)) {
        return 1;
      }
      if (endDateA.isAfter(endDateB)) {
        return -1;
      }
      return 0;
    })
    .map((survey) => {
      const endDate = moment(survey.created);

      return (
        <div
          className="sub-accordion-item"
          key={survey.id}
        >
          <ReportItem
            id={survey.id}
            quarter={survey.quarter}
            year={survey.year}
            progress={{
              total: survey.progressTotal,
              count: survey.progressCount,
            }}
            title={survey.title}
            type={survey.type}
            name={survey.fullName}
            endDate={endDate}
            favorability={survey.score}
          />
        </div>
      );
    });

  return (
    <div className="report-list">
      {reports}
    </div>
  );
};

export default ReportList;

ReportList.propTypes = {
  data: PropTypes.shape({}),
  filter: PropTypes.shape({
    type: PropTypes.string,
    timeframe: PropTypes.string,
    user: PropTypes.number,
  }),
  team: PropTypes.bool,
};

ReportList.defaultProps = {
  data: null,
  filter: {
    type: '',
    timeframe: '',
    user: null,
  },
  team: false,
};
