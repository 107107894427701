import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loginWithSaml } from '../../redux-modules/auth/actions';
import { me } from '../../redux-modules/me/actions';


class SignInSamlForm extends Component {
  componentDidMount() {
    const {
      samlToken,
      loginWithSaml: loginWithSamlAction,
      me: meAction,
      onSignIn,
    } = this.props;

    loginWithSamlAction(samlToken).then(
      (data) => {
        const token = _.get(data, 'payload.data.token', null);
        meAction(token).then(
          () => {
            if (onSignIn) {
              onSignIn();
            }
          },
          () => {
            if (onSignIn) {
              onSignIn();
            }
          },
        );
      },
      () => {
        if (onSignIn) {
          onSignIn();
        }
      },
    );
  }

  render() {
    return (
      <div className="sign-in-saml-form" />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ loginWithSaml, me }, dispatch);

export default connect(null, mapDispatchToProps)(SignInSamlForm);

SignInSamlForm.propTypes = {
  samlToken: PropTypes.string.isRequired,
  loginWithSaml: PropTypes.func.isRequired,
  me: PropTypes.func.isRequired,
  onSignIn: PropTypes.func,
};

SignInSamlForm.defaultProps = {
  onSignIn: null,
};
