import React from 'react';
import PropTypes from 'prop-types';

import SurveyReportDetails from '../../containers/SurveyReportDetails';

const ReportSurveyDetailsPage = ({ match: { params: { id } } }) => (
  <div className="report-survey-details-page">
    <SurveyReportDetails id={id} />
  </div>
);

export default ReportSurveyDetailsPage;

ReportSurveyDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
