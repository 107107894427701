import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReportList from '../../components/ReportList';
import { fetchSurveyReports } from '../../redux-modules/survey-report/actions';

class SurveyReportList extends Component {
  componentDidMount() {
    const {
      token,
      fetchSurveyReports: fetchSurveyReportsAction,
    } = this.props;

    fetchSurveyReportsAction(token);
  }

  render() {
    const { surveyReports, filterUserSurvey } = this.props;

    if (!surveyReports) {
      return <div />;
    }

    return (
      <div className="survey-report-list">
        <ReportList
          data={surveyReports}
          filter={filterUserSurvey}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  surveyReports,
  filterUserSurvey,
}) => ({ token, surveyReports, filterUserSurvey });

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveyReports,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyReportList);

SurveyReportList.propTypes = {
  token: PropTypes.string.isRequired,
  surveyReports: PropTypes.shape({}),
  filterUserSurvey: PropTypes.shape({}),
  fetchSurveyReports: PropTypes.func.isRequired,
};

SurveyReportList.defaultProps = {
  surveyReports: null,
  filterUserSurvey: null,
};
