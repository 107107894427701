import moment from 'moment';

import {
  FILTER_ADMIN_SURVEY_TYPE_SELECTED,
  FILTER_ADMIN_BUSINESS_UNIT_SELECTED,
  FILTER_ADMIN_WORKFORCE_SEGMENT_SELECTED,
  FILTER_ADMIN_STATUS_SELECTED,
  FILTER_ADMIN_YEAR_SELECTED,
} from './types';

const initialState = {
  type: 'upwards_feedback',
  unit: null,
  segment: null,
  status: null,
  year: moment().year(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_ADMIN_SURVEY_TYPE_SELECTED: {
      return {
        ...state, ...{ type: action.payload },
      };
    }
    case FILTER_ADMIN_BUSINESS_UNIT_SELECTED: {
      return {
        ...state, ...{ unit: action.payload },
      };
    }
    case FILTER_ADMIN_WORKFORCE_SEGMENT_SELECTED: {
      return {
        ...state, ...{ segment: action.payload },
      };
    }
    case FILTER_ADMIN_STATUS_SELECTED: {
      return {
        ...state, ...{ status: action.payload },
      };
    }
    case FILTER_ADMIN_YEAR_SELECTED: {
      return {
        ...state, ...{ year: action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
