import axios from 'axios';

import { TRANSFER_HISTORY_CHECKED, TRANSFER_HISTORY_EXECUTED } from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const transferHistoryCheck = (deletedUser, newUser) => {
  const url = `${REST_API_URL}v1/transfer-history/check`;
  const request = axios.post(
    url,
    {
      deletedUser,
      newUser,
    },
  );

  return {
    type: TRANSFER_HISTORY_CHECKED,
    payload: request,
  };
};

const transferHistoryExecute = (deletedUsers, newUser) => {
  const url = `${REST_API_URL}v1/transfer-history/execute`;
  const request = axios.post(
    url,
    {
      deletedUsers,
      newUser,
    },
  );

  return {
    type: TRANSFER_HISTORY_EXECUTED,
    payload: request,
  };
};

export {
  transferHistoryCheck,
  transferHistoryExecute,
};
