import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import FeedbackItem from '../../components/FeedbackItem';
import { fetchFeedbackList } from '../../redux-modules/feedback/actions';

class HistoricFeedbackList extends Component {
  componentDidMount() {
    const { token, fetchFeedbackList: fetchFeedbackListAction } = this.props;
    fetchFeedbackListAction(token);
  }

  render() {
    const { feedbackList, filterUserFeedback } = this.props;
    const { timeframe, user } = filterUserFeedback;

    const currentYear = moment().year();
    const currentQuarter = moment().quarter();

    const data = (_.values(feedbackList) || [])
      .filter((feedback) => {
        if (timeframe && timeframe === 'this_quarter' && (feedback.quarter !== currentQuarter || feedback.year !== currentYear)) {
          return false;
        }
        if (timeframe && timeframe === 'this_year' && feedback.year !== currentYear) {
          return false;
        }
        if (user && user !== feedback.fullName) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        const endDateA = moment(a.endDate);
        const endDateB = moment(b.endDate);

        if (endDateA.isBefore(endDateB)) {
          return 1;
        }
        if (endDateA.isAfter(endDateB)) {
          return -1;
        }
        return 0;
      })
      .map(feedback => (
        <div
          className="sub-accordion-item"
          key={feedback.id}
        >
          <FeedbackItem
            id={feedback.id}
            quarter={feedback.quarter}
            year={feedback.year}
            title={feedback.title}
            type={feedback.surveyType}
            name={feedback.fullName}
            endDate={moment(feedback.endDate)}
          />
        </div>
      ));

    return (
      <div className="historic-feedback-list">
        {data}
      </div>
    );
  }
}

const mapStateToProps = ({ token, filterUserFeedback, feedbackList }) => ({
  token,
  filterUserFeedback,
  feedbackList,
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchFeedbackList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HistoricFeedbackList);

HistoricFeedbackList.propTypes = {
  filterUserFeedback: PropTypes.shape({
    timeframe: PropTypes.string,
    user: PropTypes.string,
  }),
  token: PropTypes.string.isRequired,
  feedbackList: PropTypes.shape({}),
  fetchFeedbackList: PropTypes.func.isRequired,
};

HistoricFeedbackList.defaultProps = {
  feedbackList: null,
  filterUserFeedback: null,
};
