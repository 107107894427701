const files = {
  '0301': 'P4G_Disclaimer_Russia',
  '0307': 'P4G_Disclaimer_Russia',
  '0308': 'P4G_Disclaimer_Russia',
  '0403': 'P4G_Disclaimer_Russia',
  '0404': 'P4G_Disclaimer_Russia',
  '0410': 'P4G_Disclaimer_Armenia',
  '0415': 'P4G_Disclaimer_Latvia',
  '0416': 'P4G_Disclaimer_Estonia',
  '0417': 'P4G_Disclaimer_Lithuania',
  '0424': 'P4G_Disclaimer_CSC',
  '0425': 'P4G_Disclaimer_BSO',
  '0428': 'P4G_Disclaimer_Bulgaria',
  '0430': 'P4G_Disclaimer_Northern_Macedonia',
  '0440': 'P4G_Disclaimer_Serbia',
  '0441': 'P4G_Disclaimer_Serbia',
  '0443': 'P4G_Disclaimer_Serbia',
  '0444': 'P4G_Disclaimer_Serbia',
  '0445': 'P4G_Disclaimer_Serbia',
  '0446': 'P4G_Disclaimer_Serbia',
  '0450': 'P4G_Disclaimer_Greece',
  '0452': 'P4G_Disclaimer_Greece',
  '0456': 'P4G_Disclaimer_Greece',
  '0457': 'P4G_Disclaimer_Cyprus',
  '0460': 'P4G_Disclaimer_Moldova',
  '0470': 'P4G_Disclaimer_Nigeria',
  '0480': 'P4G_Disclaimer_Ireland',
  '0491': 'P4G_Disclaimer_Ireland',
  '0518': 'P4G_Disclaimer_CSC',
  '0520': 'P4G_Disclaimer_CSC',
  '0522': 'P4G_Disclaimer_Hungary',
  '0526': 'P4G_Disclaimer_Austria',
  '0527': 'P4G_Disclaimer_Austria',
  '0530': 'P4G_Disclaimer_Czech',
  '0531': 'P4G_Disclaimer_Czech',
  '0532': 'P4G_Disclaimer_Czech',
  '0533': 'P4G_Disclaimer_Slovakia',
  '0540': 'P4G_Disclaimer_Slovenia',
  '0541': 'P4G_Disclaimer_Slovenia',
  '0550': 'P4G_Disclaimer_Belarus',
  '0560': 'P4G_Disclaimer_Ukraine',
  '0562': 'P4G_Disclaimer_Poland',
  '0563': 'P4G_Disclaimer_Poland',
  '0570': 'P4G_Disclaimer_Croatia',
  '0573': 'P4G_Disclaimer_Bosnia_And_Herzegovina',
  '0580': 'P4G_Disclaimer_Romania',
  '0590': 'P4G_Disclaimer_Switzerland',
  '0594': 'P4G_Disclaimer_Italy',
  '0595': 'P4G_Disclaimer_Italy',
  '0600': 'P4G_Disclaimer_Switzerland',
  '0720': 'P4G_Disclaimer_CSC',
  '0724': 'P4G_Disclaimer_Russia',
  '0726': 'P4G_Disclaimer_Russia',
  '0739': 'P4G_Disclaimer_CSC',
  '0747': 'P4G_Disclaimer_CSC',
  '0750': 'P4G_Disclaimer_Russia',
  '0753': 'P4G_Disclaimer_CSC',
  '0754': 'P4G_Disclaimer_CSC',
  '0769': 'P4G_Disclaimer_Russia',
  '0783': 'P4G_Disclaimer_Russia',
  '0786': 'P4G_Disclaimer_Russia',
  '0790': 'P4G_Disclaimer_Russia',
  '0796': 'P4G_Disclaimer_Russia',
  '0797': 'P4G_Disclaimer_Russia',
  1450: 'P4G_Disclaimer_CSC',
  1451: 'P4G_Disclaimer_CSC',
  1452: 'P4G_Disclaimer_CSC',
};
const defaultFile = 'P4G_Disclaimer_CSC';

export default {
  getValue: id => (id in files ? files[id] : defaultFile),
};
