import React from 'react';
import PropTypes from 'prop-types';

const TextAreaField = ({
  meta: { touched, error, submitting },
  input,
  input: { name },
  label,
  placeholder,
  showLabel,
  showErrors,
}) => (
  <div className="text-area-field-component form-group">
    <label
      className={`${showLabel ? 'visible' : ''}`}
      htmlFor={name}
    >
      {label}
    </label>
    <div className="text-area-field">
      <textarea
        className="form-control"
        {...input}
        id={name}
        disabled={submitting}
        placeholder={placeholder}
      />
    </div>
    <small className="text-area-field__error">
      {touched && error && showErrors ? error : '' }
    </small>
  </div>
);

export default TextAreaField;

TextAreaField.propTypes = {
  meta: PropTypes.shape({
    submitting: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({ name: PropTypes.string }).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
};

TextAreaField.defaultProps = {
  label: '',
  placeholder: null,
  showLabel: false,
  showErrors: true,
};
