import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import CantProvideForm from '../../containers/CantProvideForm';

import './notification-item.css';

class NotificationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  handleDialogOpen() {
    this.setState({ open: true });
  }

  handleDialogClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      t,
      quarter,
      year,
      title,
      type,
      url,
      notificationType,
      name,
      endDate,
    } = this.props;
    const { open } = this.state;

    return (
      <div className="notification-item row">
        <div className="col">
          <div className="sub-acordion-title">
            {year}
            {' '}
            Q
            {quarter}
            {' '}
            {title}
            {' '}
            {t(`survey.type.${type}`)}
            {' '}
            {t('survey.label.for')}
            {' '}
            {name}
          </div>
          <div className="sub-acordion-date">
            {t('survey.date.due')}
            {': '}
            {endDate.format(t('date_format'))}
          </div>
        </div>
        <div className="col text-right my-auto">
          <Link
            className="btn standard"
            to={url}
          >
            {t(`notification.label.${notificationType}`)}
          </Link>
          {notificationType === 'provide_feedback' && type !== 'pod_feedback' && (
            <button
              type="button"
              className="btn standard cant-provide"
              onClick={this.handleDialogOpen}
            >
              {t('notification.label.cant_provide_feedback')}
            </button>
          )}
        </div>
        <div className={`pop-up-filter-wrapper ${open ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                style={{ position: 'absolute' }}
                role="button"
                tabIndex={0}
                onClick={this.handleDialogClose}
                onKeyPress={this.handleDialogClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('feedback.label.cant_provide_title')}
              </h3>
              <CantProvideForm
                onCancel={this.handleDialogClose}
                onSend={this.handleDialogClose}
                url={url}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(NotificationItem);

NotificationItem.propTypes = {
  t: PropTypes.func.isRequired,
  quarter: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  endDate: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
};

NotificationItem.defaultProps = {
  title: '',
};
