import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Footer from '../../components/Footer';
import ConfirmationForm from '../../containers/ConfirmationForm';

import cchLogo from '../../assets/images/cch-logo.svg';
import logo from '../../assets/images/logo.svg';

const ConfirmationPage = ({ t }) => (
  <div className="confirmation-page">
    <nav className="navbar navbar-expand-lg navbar-gray static-top">
      <div className="mobile-header">
        <div className="mobile-header-logo">
          <img
            src={cchLogo}
            alt="logo"
          />
        </div>
      </div>
      <div className="container">
        <div className="logo">
          <img
            src={logo}
            alt="logo"
          />
        </div>
      </div>
    </nav>
    <main className="login">
      <div className="container">
        <div className="content-box">
          <h2 align="center">
            {t('user.email_verification')}
          </h2>
          <ConfirmationForm />
        </div>
      </div>
    </main>
    <Footer />
  </div>
);

export default withNamespaces()(ConfirmationPage);

ConfirmationPage.propTypes = {
  t: PropTypes.func.isRequired,
};
