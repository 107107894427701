/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import UpdateSurveyForm from '../UpdateSurveyForm';
import { fetchSurvey } from '../../redux-modules/survey/actions';

import './update-survey.css';

import cheerUp from '../../assets/images/cheer-up.png';

const questionsConfig = {
  internal_customer_feedback: ['question_1', 'question_2', 'question_3', 'question_4', 'question_5', 'question_6', 'question_7'],
  external_customer_feedback: ['question_1', 'question_2', 'question_3', 'question_4', 'question_5'],
};

class UpdateSurvey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'peer_feedback',
      step: 0,
    };

    this.renderStepZero = this.renderStepZero.bind(this);
    this.renderStepZeroPeer = this.renderStepZeroPeer.bind(this);
    this.renderStepOne = this.renderStepOne.bind(this);
    this.renderStepTwo = this.renderStepTwo.bind(this);
  }

  componentDidMount() {
    const {
      token,
      id,
      onRedirect,
      fetchSurvey: fetchSurveyAction,
    } = this.props;

    fetchSurveyAction(token, id).then(
      (data) => {
        const status = _.get(data, 'payload.status', 500);
        if (status === 200) {
          const survey = _.get(data, 'payload.data', null);
          const type = _.get(survey, 'type', '');
          const surveyStatus = _.get(survey, 'status', '');
          if (surveyStatus !== 'approve_level_1' && surveyStatus !== 'select_providers') {
            onRedirect();
          }
          this.setState({ type });
        }
      },
      () => {},
    );
  }

  renderStepZero() {
    const { t, userProfile } = this.props;

    return (
      <div className="update-survey">
        <div className="content-box accordion">
          {userProfile !== null && (
            <p>
              {t('survey.label.hi')}
              {' '}
              {userProfile.name}
              ,
            </p>
          )}
          <p>
            {t('survey.label.tips_create_customer_list_1')}
          </p>
          <p>
            {t('survey.label.tips_create_customer_list_2')}
          </p>
        </div>
        <div className="buttons-group">
          <div className="buttons-group-wrapper external-internal">
            <Link
              className="btn standard gray"
              to="/"
            >
              {t('survey.action.cancel')}
            </Link>
            <button
              className="btn standard"
              type="button"
              onClick={() => this.setState({ step: 1, type: 'internal_customer_feedback' })}
            >
              {t('survey.type.internal_customer_feedback')}
            </button>
            <button
              className="btn standard"
              type="button"
              onClick={() => this.setState({ step: 1, type: 'external_customer_feedback' })}
            >
              {t('survey.type.external_customer_feedback')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderStepZeroPeer() {
    const {
      t,
      userProfile,
      surveys,
      id,
    } = this.props;

    const survey = _.get(surveys, id, null);

    const showLMContent = survey
      && userProfile
      && survey.status === 'approve_level_1'
      && survey.userId !== userProfile.id;

    let taskDueDate = '';
    let quarter = '';
    let year = '';
    if (survey && survey.launchDate) {
      taskDueDate = moment(survey.launchDate)
        .add((showLMContent ? 7 : 2), 'days')
        .format(t('date_format'));
    }
    if (survey && survey.quarter) {
      ({ quarter } = survey);
    }
    if (survey && survey.year) {
      ({ year } = survey);
    }

    const disabled = survey
      && userProfile
      && survey.type === 'peer_feedback'
      && survey.status === 'approve_level_1'
      && survey.userId === userProfile.id;

    return (
      <div className="update-survey update-survey--peers">
        <div className="content-box accordion">
          {userProfile !== null && (
            <p>
              {t('survey.label.hi')}
              {' '}
              {userProfile.name}
              ,
            </p>
          )}
          {showLMContent
            ? (
              <div>
                <ReactMarkdown>
                  {t('survey.label.tips_created_peer_list_1').replace('<year>', year).replace('<quarter>', quarter)}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_created_peer_list_2')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_created_peer_list_3')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_created_peer_list_4')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_created_peer_list_5')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_created_peer_list_6')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_created_peer_list_7').replace('<date>', taskDueDate)}
                </ReactMarkdown>
              </div>
            )
            : (
              <div>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_1')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_2')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_3')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_4')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_5')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_6')}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_7').replace('<date>', taskDueDate)}
                </ReactMarkdown>
                <ReactMarkdown>
                  {t('survey.label.tips_create_peer_list_8')}
                </ReactMarkdown>
              </div>
            )
          }
        </div>
        <div className="buttons-group">
          <div className="buttons-group-wrapper external-internal">
            <Link
              className="btn standard gray"
              to="/"
            >
              {t('survey.action.cancel')}
            </Link>
            <button
              className="btn standard"
              type="button"
              disabled={disabled}
              onClick={() => {
                this.setState({ step: 1 });
                window.scrollTo(0, 0);
              }}
            >
              {t('survey.action.next')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderStepOne() {
    const {
      t,
      id,
      surveys,
    } = this.props;
    const { type, step } = this.state;

    const survey = _.get(surveys, id, null);
    const surveyStatus = survey ? survey.status : '';
    let questions;

    const questionsLimit = type === 'peer_feedback' ? 9 : 20;

    if (survey && survey.questions && survey.questions.length) {
      questions = (survey.questions || []).slice(0, questionsLimit).map((question, index) => (
        <div
          className="question-box"
          key={question.text}
        >
          {index + 1}
          {'. '}
          {t(question.text)}
        </div>
      ));
    } else {
      const questionsByType = _.get(questionsConfig, type, []);
      questions = questionsByType.map((question, index) => (
        <div
          className="question-box"
          key={question}
        >
          {index + 1}
          {'. '}
          {t(`question.${type}.${question}`)}
        </div>
      ));
    }

    return (
      <div>
        {step === 0 && type === 'customer_feedback' ? this.renderStepZero() : null}
        {step === 0 && type === 'peer_feedback' ? this.renderStepZeroPeer() : null}
        <div className={`update-survey${step !== 1 ? ' d-none' : ''}`}>
          <div className="content-box accordion">
            {questions}
            <UpdateSurveyForm
              id={id}
              type={type}
              status={surveyStatus}
              step={step}
              onSubmit={() => { this.setState({ step: 2 }); }}
              onBack={() => {
                this.setState({ step: 0 });
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderStepTwo() {
    const {
      userProfile,
      surveys,
      id,
      t,
      onSubmit,
    } = this.props;

    const survey = _.get(surveys, id, null);

    const showLMContent = survey
      && userProfile
      && survey.status === 'approve_level_1'
      && survey.userId !== userProfile.id;

    let taskDueDate = '';
    if (survey && survey.launchDate) {
      taskDueDate = moment(survey.launchDate)
        .add((showLMContent ? 7 : 2), 'days')
        .format(t('date_format'));
    }

    return (
      <div>
        <div className="content-box accordion">
          <p align="center">
            <strong>
              {t('answer.label.thank_you')}
              !
            </strong>
          </p>
          {showLMContent
            ? (
              <div>
                <p align="center">
                  {t('answer.label.thank_you_desc_5')}
                </p>
              </div>
            )
            : (
              <div>
                <p align="center">
                  {t('answer.label.thank_you_desc_3')}
                </p>
                <p align="center">
                  {t('answer.label.thank_you_desc_4').replace('<date>', taskDueDate)}
                </p>
              </div>
            )
          }
          <div className="cheer-up">
            <img
              align="center"
              alt="cheer up"
              src={cheerUp}
            />
          </div>
        </div>
        <div className="buttons-group">
          <button
            type="button"
            className="btn standard"
            onClick={onSubmit || (() => {})}
          >
            {t('menu.label.close')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { surveys, id, t } = this.props;
    const { step } = this.state;

    const survey = _.get(surveys, id, null);

    if (!survey) {
      return (<div />);
    }

    let dueDate = moment(survey.endDate);
    if (survey.type === 'peer_feedback' && survey.status === 'select_providers') {
      dueDate = moment(survey.launchDate).add(2, 'days');
    }
    if (survey.type === 'peer_feedback' && survey.status === 'approve_level_1') {
      dueDate = moment(survey.launchDate).add(7, 'days');
    }

    const content = step === 0 || step === 1
      ? this.renderStepOne()
      : this.renderStepTwo();

    return (
      <div className="update-survey">
        <div className="content-title">
          <div className="col my-auto">
            <span className="box-title">
              {survey.year}
              {' '}
              Q
              {survey.quarter}
              {' '}
              {t(`survey.type.${survey.type}`)}
              {' '}
              {t('survey.label.for')}
              {' '}
              {survey.fullName}
            </span>
            <div className="information">
              <span className="box-information">
                <strong>
                  {t('survey.date.due')}
                  :
                  {' '}
                </strong>
                <div className="d-inline-block">
                  {dueDate.format(t('date_format'))}
                </div>
              </span>
            </div>
          </div>
        </div>
        {content}
      </div>
    );
  }
}

const mapStateToProps = ({ token, surveys, userProfile }) => ({ token, surveys, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchSurvey }, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(UpdateSurvey));

UpdateSurvey.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  surveys: PropTypes.shape({}),
  fetchSurvey: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({}),
};

UpdateSurvey.defaultProps = {
  surveys: null,
  userProfile: null,
};
