export default {
  required: value => !!value,

  email: value => value && (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(value)),

  minLength: (value, length) => value && value.length >= length,

  maxLength: (value, length) => value && value.length <= length,

  isNumber: value => (value || value === 0) && !Number.isNaN(Number(value)),

  min: (value, length) => (value || value === 0) && value >= length,

  max: (value, length) => (value || value === 0) && value <= length,

  before: (from, to) => from.diff(to) < 0,

  equals: (value, other) => value === other,

  date: (month, day, year) => {
    switch (month) {
      case '2':
        return year % 4 === 0 ? day <= 29 : day <= 28;
      case '4': case '6': case '9': case '11':
        return day <= 30;
      default:
        return day <= 31;
    }
  },
};
