import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ManualReminderForm from '../../containers/ManualReminderForm';

class ManualReminderPage extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleCancel() {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
      return;
    }
    history.push('/');
  }

  handleSend() {
    const { history } = this.props;

    history.push('/');
  }

  render() {
    const { match: { params: { id } } } = this.props;

    return (
      <main className="add-survey-page survey">
        <div className="container">
          <ManualReminderForm
            id={id}
            onCancel={this.handleCancel}
            onSend={this.handleSend}
          />
        </div>
      </main>
    );
  }
}

export default ManualReminderPage;

ManualReminderPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};
