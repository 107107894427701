import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const FeedbackItem = ({
  t,
  id,
  quarter,
  year,
  title,
  type,
  name,
  endDate,
}) => (
  <div className="feedback-item row">
    <div className="col">
      <div className="sub-acordion-title">
        {year}
        {' '}
        Q
        {quarter}
        {' '}
        {title}
        {' '}
        {t(`survey.type.${type}`)}
        {' '}
        {t('survey.label.for')}
        {' '}
        {name}
      </div>
      <div className="sub-acordion-date">
        {t('survey.date.closed')}
        {': '}
        {endDate.format(t('date_format'))}
      </div>
    </div>
    <div className="col text-right my-auto">
      <Link
        className="btn standard gray"
        to={`/feedback/preview/${id}`}
      >
        {t('survey.action.view_feedback')}
      </Link>
    </div>
  </div>
);

export default withNamespaces()(FeedbackItem);

FeedbackItem.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  quarter: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  endDate: PropTypes.shape({}).isRequired,
};
