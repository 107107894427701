import axios from 'axios';

import {
  SURVEY_REPORT_FETCHED,
  SURVEY_REPORTS_FETCHED,
  SURVEY_REPORTS_TEAM_FETCHED,
  SURVEY_REPORT_PREVIEW_FETCHED,
} from './types';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

const fetchSurveyReport = (token, id) => {
  const url = `${REST_API_URL}v1/survey-report/${id}`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEY_REPORT_FETCHED,
    payload: request,
  };
};

const fetchSurveyReportPreview = (token, id) => {
  const url = `${REST_API_URL}v1/survey/${id}/preview-report`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEY_REPORT_PREVIEW_FETCHED,
    payload: request,
  };
};

const fetchSurveyReports = () => {
  const url = `${REST_API_URL}v1/survey-report`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEY_REPORTS_FETCHED,
    payload: request,
  };
};

const fetchTeamSurveyReports = () => {
  const url = `${REST_API_URL}v1/survey-report/team`;
  const request = axios.get(
    url,
  );

  return {
    type: SURVEY_REPORTS_TEAM_FETCHED,
    payload: request,
  };
};

export {
  fetchSurveyReport,
  fetchSurveyReports,
  fetchTeamSurveyReports,
  fetchSurveyReportPreview,
};
